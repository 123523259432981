import React, { useState, useEffect, useRef } from 'react';

const CDNImage = ({ 
  src, 
  alt, 
  className = '', 
  fallbackSrc = null,
  loading = 'lazy',
  sizes, 
  preload, 
  lowQuality = true
}) => {
  const [imgSrc, setImgSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const imageRef = useRef(null);
  
  const CDN_DOMAIN = 'https://cdn.datatraininglab.com';
  const MAIN_DOMAIN = 'https://datatraininglab.com';
  
  // Generate srcSet for responsive images
  const generateSrcSet = (path) => {
    const widths = [320, 640, 768, 1024, 1280, 1536];
    const cleanPath = path.replace(/^\//, '');
    return widths
      .map(w => `${CDN_DOMAIN}/${cleanPath}?w=${w} ${w}w`)
      .join(', ');
  };

  // Format image URL and add quality/format parameters
  const formatImageUrl = (path, useCdn = true, params = {}) => {
    const cleanPath = path.replace(/^\//, '');
    const baseUrl = `${useCdn ? CDN_DOMAIN : MAIN_DOMAIN}/${cleanPath}`;
    
    // Add quality and format parameters
    const searchParams = new URLSearchParams({
      format: 'webp', // Use WebP format where supported
      ...params
    });
    
    return `${baseUrl}?${searchParams.toString()}`;
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    setError(false);

    // Preload high-quality image
    const preloadImage = (url) => {
      const img = new Image();
      
      img.onload = () => {
        if (isMounted) {
          setImgSrc(url);
          setIsLoading(false);
        }
      };
      
      img.onerror = () => {
        if (isMounted) {
          if (fallbackSrc && url !== formatImageUrl(fallbackSrc, false)) {
            // Try fallback URL if primary fails
            preloadImage(formatImageUrl(fallbackSrc, false));
          } else {
            setError(true);
            setIsLoading(false);
          }
        }
      };

      img.src = url;
    };

    // Start with low quality placeholder if enabled
    if (lowQuality) {
      setImgSrc(formatImageUrl(src, true, { quality: '10' }));
    }

    // Load high quality image
    preloadImage(formatImageUrl(src, true));

    // Preload next-gen format
    if (preload === true) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = formatImageUrl(src, true);
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }

    return () => {
      isMounted = false;
    };
  }, [src, fallbackSrc, lowQuality, preload]);

  // Use Intersection Observer for lazy loading
  useEffect(() => {
    if (!imageRef.current || loading !== 'lazy') return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            img.srcset = img.dataset.srcset;
            observer.unobserve(img);
          }
        });
      },
      {
        rootMargin: '50px 0px',
        threshold: 0.01
      }
    );

    observer.observe(imageRef.current);

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [loading]);

  if (error) {
    return (
      <div 
        className={`${className} bg-gray-200 flex items-center justify-center`}
        role="img"
        aria-label={alt}
      >
        <span className="text-gray-400 text-sm">Image unavailable</span>
      </div>
    );
  }

  return (
    <img
      ref={imageRef}
      src={loading === 'lazy' ? undefined : imgSrc}
      data-src={loading === 'lazy' ? imgSrc : undefined}
      srcSet={loading === 'lazy' ? undefined : generateSrcSet(src)}
      data-srcset={loading === 'lazy' ? generateSrcSet(src) : undefined}
      sizes={sizes || undefined}
      alt={alt}
      className={`${className} ${isLoading ? 'blur-sm' : 'blur-0'} transition-all duration-300`}
      loading={loading}
      onLoad={() => setIsLoading(false)}
    />
  );
};

export default CDNImage;