import React from 'react';
import { Link } from 'react-router-dom';
import CDNImage from './CDNImage';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from './Navigation';

// Theme styles configuration - using Tailwind classes for static classes, 
// and referencing the actual color values for dynamic use cases
export const themeStyles = {
  light: {
    primary: {
      className: 'bg-primary',
      color: '#311d3f'
    },
    secondary: {
      className: 'bg-secondary',
      color: '#4d3160'
    },
    dark: {
      className: 'bg-primary-dark',
      color: '#160d1c'
    },
    accent: {
      className: 'bg-accent',
      color: '#59a52c'
    },
    lightAccent: {
      className: 'bg-accent-light',
      color: '#64ba32'
    }
  },
  dark: {
    primary: {
      className: 'bg-dark-primary',
      color: '#160d1c'
    },
    secondary: {
      className: 'bg-dark-secondary',
      color: '#372344'
    },
    dark: {
      className: 'bg-dark-primary-dark',
      color: '#050307'
    },
    accent: {
      className: 'bg-dark-accent',
      color: '#457f23'
    },
    lightAccent: {
      className: 'bg-dark-accent-light',
      color: '#57a32c'
    }
  }
};

// Hero Section Component
export const HeroSection = ({ 
  imageSrc, 
  fallbackSrc, 
  title, 
  subtitle, 
  ctaText = 'Start Your Journey', 
  ctaLink = '/tracks',
  styles 
}) => (
  <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-8">
    <div className="w-[95%] mx-auto relative rounded-2xl overflow-hidden shadow-2xl dark:shadow-dark-primary/30">
      <CDNImage
        src={imageSrc}
        alt={title}
        className="w-full h-full object-cover absolute inset-0"
        fallbackSrc={fallbackSrc}
        preload={true}
      />
      <div className="absolute inset-0 bg-black/50"></div>
      <div className="relative py-24 px-8 max-w-4xl mx-auto text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 tracking-tight drop-shadow-lg">
          {title}
        </h1>
        <p className="text-xl md:text-2xl text-white/90 mb-8 drop-shadow">
          {subtitle}
        </p>
        <Link 
          to={ctaLink}
          className="inline-block px-8 py-4 text-white text-lg rounded-lg transition-all 
            transform hover:scale-105 hover:shadow-xl dark:hover:shadow-dark-primary/30"
          style={{ 
            backgroundColor: styles.accent.color,
            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
          }}
        >
          {ctaText}
        </Link>
      </div>
    </div>
  </div>
);

// Gradient Break Section Component
export const GradientBreakSection = ({ text, styles }) => (
  <div 
    className="text-center py-16 px-4 transition-colors duration-200 my-8"
    style={{ 
      backgroundImage: `linear-gradient(to right, ${styles.secondary.color}, ${styles.primary.color})` 
    }}
  >
    <h2 className="text-3xl md:text-4xl font-bold text-white max-w-4xl mx-auto">
      {text}
    </h2>
  </div>
);

// Card Grid Component
export const CardGrid = ({ items, styles, variant = 'default' }) => {
  const renderCard = (item, index) => {
    if (variant === 'testimonial') {
      return (
        <div 
          key={index} 
          className="bg-white/10 dark:bg-dark-primary-light/50 backdrop-blur-lg rounded-2xl p-6 
            border border-white/10 dark:border-dark-primary/50 
            transform transition-all hover:scale-105 hover:shadow-2xl 
            dark:hover:shadow-dark-primary/30"
        >
          <div className="mb-4">
            <h4 className="font-bold text-white">{item.name}</h4>
            <p className="text-sm text-white/70">{item.role}</p>
          </div>
          <p className="text-white/90">{item.content}</p>
        </div>
      );
    }

    return (
      <div 
        key={index}
        className="bg-white/10 dark:bg-dark-primary-light/50 backdrop-blur-lg rounded-2xl p-6 
          border border-white/10 dark:border-dark-primary/50 
          transform transition-all hover:scale-105 hover:shadow-2xl 
          dark:hover:shadow-dark-primary/30"
      >
        <h3 className="text-xl font-bold text-white mb-4">
          {item.title}
        </h3>
        <p className="text-white/90">
          {item.description}
        </p>
      </div>
    );
  };

  return (
    <div className="grid md:grid-cols-3 gap-8">
      {items.map(renderCard)}
    </div>
  );
};

// Call to Action Section Component
export const CTASection = ({ title, subtitle, ctaText, styles, to }) => (
  <div 
    className="py-24 transition-colors duration-200"
    style={{ 
      backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
    }}
  >
    <div className="max-w-7xl mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold text-white mb-6">
        {title}
      </h2>
      <p className="text-white text-xl mb-8 max-w-2xl mx-auto">
        {subtitle}
      </p>
      <Link 
        to={to}
        className="inline-block px-8 py-4 bg-white dark:bg-dark-primary text-gray-900 dark:text-white 
          rounded-lg transition-all transform hover:scale-105 hover:shadow-xl 
          dark:hover:shadow-dark-primary/30"
      >
        {ctaText}
      </Link>
    </div>
  </div>
);

// Feature Section Component
export const FeatureSection = ({ 
  imageSrc, 
  fallbackSrc, 
  title, 
  description, 
  ctaText, 
  styles,
  reverse = false ,
  ctaLink = '/tracks'
}) => (
  <div className="py-16 bg-gray-50 dark:bg-dark-primary/90 transition-colors duration-200">
    <div className="max-w-7xl mx-auto px-4">
      <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl 
        dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
        <div className={`flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} items-center`}>
          <div className="md:w-1/2 p-2">
              <div className="aspect-[4/3] overflow-hidden rounded-xl">
              <CDNImage
                src={imageSrc}
                alt={title}
                className="rounded-xl w-full h-auto transform transition-transform hover:scale-105"
                fallbackSrc={fallbackSrc}
              />
            </div>
          </div>
          <div className="md:w-1/2 p-8">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-900 dark:text-white">
              {title}
            </h2>
            <p className="text-gray-700 dark:text-white/80 text-lg mb-6">
              {description}
            </p>
            <Link 
              to={ctaLink}
              className="inline-block px-6 py-3 text-white rounded-lg transition-all 
                transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
              style={{ 
                backgroundColor: styles.accent.color,
                backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
              }}
            >
              {ctaText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const ContentSection = ({ title, content, children }) => (
  <div className="py-8 bg-gray-50 dark:bg-dark-primary-light/30 transition-colors duration-200">
    <div className="max-w-7xl mx-auto px-4">
      <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 p-8">
        <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
          {title}
        </h2>
        <div className="space-y-6 text-gray-700 dark:text-white/80">
          {content && content.map((paragraph, index) => (
            <p key={index} className="text-lg leading-relaxed">
              {paragraph}
            </p>
          ))}
        </div>
        {children && (
          <div className="mt-6">
            {children}
          </div>
        )}
      </div>
    </div>
  </div>
);

export const PolicyLayout = ({ children, title, lastUpdated, styles: propStyles }) => {
  const { theme } = useTheme();
  const styles = propStyles || themeStyles[theme === 'dark' ? 'dark' : 'light'];

  return (
    <div className="max-w-7xl mx-auto px-4 py-16">
      <div className="bg-white dark:bg-dark-primary-light rounded-2xl p-8 shadow-xl">
        <h1 className="text-4xl font-bold mb-4 text-gray-900 dark:text-white" style={{ 
          background: theme === 'dark' ? 'none' : `linear-gradient(to right, ${styles.primary.color}, ${styles.secondary.color})`,
          WebkitBackgroundClip: theme === 'dark' ? 'none' : 'text',
          WebkitTextFillColor: theme === 'dark' ? 'inherit' : 'transparent'
        }}>
          {title}
        </h1>
        {lastUpdated && (
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
            Last Updated: {lastUpdated}
          </p>
        )}
        <div className="space-y-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export const ProtectedPageLayout = ({ 
  children, 
  title, 
  description, 
  styles = getDefaultStyles() 
}) => (
  <div style={{ backgroundColor: styles.primary }} className="min-h-screen">
    <Navigation />
    <div className="max-w-7xl mx-auto px-4 py-16">
      <div className="bg-white rounded-lg p-8">
        {title && (
          <h1 
            className="text-3xl font-bold mb-8"
            style={{ color: styles.primary }}
          >
            {title}
          </h1>
        )}
        {description && (
          <p className="text-gray-600 mb-8">{description}</p>
        )}
        {children}
      </div>
    </div>
  </div>
);

export const LoadingState = ({ styles = getDefaultStyles() }) => (
  <div style={{ backgroundColor: styles.primary }} className="min-h-screen">
    <Navigation />
    <div className="max-w-7xl mx-auto px-4 py-16">
      <div className="bg-white rounded-lg p-8 flex justify-center items-center">
        <div className="text-gray-600">Loading...</div>
      </div>
    </div>
  </div>
);

export const ErrorState = ({ 
  error = 'An unexpected error occurred', 
  styles = getDefaultStyles() 
}) => (
  <div style={{ backgroundColor: styles.primary }} className="min-h-screen">
    <Navigation />
    <div className="max-w-7xl mx-auto px-4 py-16">
      <div className="bg-white rounded-lg p-8">
        <div className="text-red-600">Error: {error}</div>
      </div>
    </div>
  </div>
);

export const ProgressIndicator = ({ 
  currentStep, 
  totalSteps, 
  progress, 
  styles = getDefaultStyles() 
}) => (
  <div className="mb-8">
    <div className="flex items-center justify-between mb-2">
      <h2 className="text-sm font-medium text-gray-600">
        Section {currentStep} of {totalSteps}
      </h2>
      <span className="text-sm text-gray-600">
        {Math.round(progress)}% Complete
      </span>
    </div>
    <div className="h-2 bg-gray-200 rounded-full">
      <div 
        className="h-full rounded-full transition-all duration-500"
        style={{ 
          width: `${progress}%`,
          backgroundColor: styles.accent
        }}
      />
    </div>
  </div>
);

export const NavigationButtons = ({
  onPrevious,
  onNext,
  previousDisabled = false,
  nextDisabled = false,
  previousLabel = 'Previous',
  nextLabel = 'Next',
  styles = getDefaultStyles()
}) => (
  <div className="flex justify-between items-center mt-12">
    <button
      onClick={onPrevious}
      disabled={previousDisabled}
      className="flex items-center gap-2 px-4 py-2 text-gray-600 disabled:opacity-50"
    >
      <ChevronLeft size={20} />
      {previousLabel}
    </button>
    <button
      onClick={onNext}
      disabled={nextDisabled}
      className="flex items-center gap-2 px-6 py-3 text-white rounded-lg disabled:opacity-50"
      style={{ backgroundColor: styles.accent }}
    >
      {nextLabel}
      <ChevronRight size={20} />
    </button>
  </div>
);



export default {
  HeroSection,
  ContentSection,
  GradientBreakSection,
  CardGrid,
  CTASection,
  FeatureSection,
  themeStyles,
  PolicyLayout,
  ProtectedPageLayout,
  LoadingState,
  ErrorState,
  ProgressIndicator,
  NavigationButtons
};