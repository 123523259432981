import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from '../config/api';

const AnalyticsTracker = () => {
  const location = useLocation();

  const trackPageView = async (path) => {
    let clientId = localStorage.getItem('clientId');
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem('clientId', clientId);
    }

    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      sessionStorage.setItem('sessionId', sessionId);
    }

    const data = {
      path: path,
      referrer: document.referrer || null,
      userAgent: navigator.userAgent,
      clientId,
      sessionId,
      timeOnPage: null
    };

    try {
      const response = await fetch(`${API_URL}/analytics/pageview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-API-Key': process.env.REACT_APP_ANALYTICS_API_KEY,
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.success;
    } catch (error) {
      console.error('Analytics error:', error);
      return false;
    }
  };

  useEffect(() => {
    const apiKey = process.env.REACT_APP_ANALYTICS_API_KEY;
    if (!apiKey) {
      console.warn('Analytics API key not found');
      return;
    }

    trackPageView(location.pathname).catch(error => {
      console.error('Analytics tracking error:', error);
    });
  }, [location.pathname]);

  return null;
};

export default AnalyticsTracker;