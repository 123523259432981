import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { themeStyles } from '../../SharedComponents';
import { useEffectiveTheme } from '../../../hooks/useEffectiveTheme';
import ContentTypeFields from './ContentTypeFields';
import { contentTypes } from './contentUtils';

const ContentFormModal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  editingContent,
  content,
  sectionId
}) => {
  const [selectedContentType, setSelectedContentType] = useState(editingContent?.content_type || 'text');

    // Add debug logging
    console.log('Modal State:', {
      selectedContentType,
      isDropdownDisabled: !!editingContent,
      contentTypes
    });

    useEffect(() => {
      if (isOpen) {
        setSelectedContentType(editingContent?.content_type || 'text');
      }
    }, [isOpen, editingContent]);


  const handleContentTypeChange = (e) => {
    console.log('Content type changed to:', e.target.value);
    setSelectedContentType(e.target.value);
  };
  
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  if (!isOpen) return null;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    // Convert form data to object
    const data = {
      section_id: sectionId,
      content_type: selectedContentType,
      sequence: parseInt(formData.get('sequence')),
      content: {}
    };

    // Add content based on type
    switch (selectedContentType) {
      case 'text':
        data.content = {
          html: formData.get('html')
        };
        break;
      case 'video':
        data.content = {
          videoId: formData.get('videoId'),
          hash: formData.get('hash'),
          title: formData.get('title'),
          caption: formData.get('caption'),
          description: formData.get('description'),
          previewImage: formData.get('previewImage'),
          transcript: formData.get('transcript')
        };
        break;
      case 'code':
        data.content = {
          language: formData.get('language'),
          codeContent: formData.get('codeContent'),
          caption: formData.get('caption')
        };
        break;
      case 'image':
        data.content = {
          url: formData.get('url'),
          alt: formData.get('alt'),
          caption: formData.get('caption'),
          description: formData.get('description')
        };
        break;
      case 'code':
        data.content = {
          language: formData.get('language'),
          codeContent: formData.get('codeContent'),
          caption: formData.get('caption')
        };
        break;
      case 'quiz':
        try {
          data.content = JSON.parse(formData.get('quiz'));
        } catch (error) {
          console.error('Error parsing quiz data:', error);
          data.content = { questions: [] };
        }
        break;
      case 'protected-pdf':
        data.content = {
          filename: formData.get('filename'),
          text: formData.get('text')
        };
        break;
      case 'link':
        data.content = {
          url: formData.get('url'),
          text: formData.get('text')
        };
        break;
      default:
        break;
    }

    onSubmit(data);
  };

  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50 overflow-y-auto transition-colors duration-200">
      <div className="bg-white dark:bg-dark-primary-light rounded-lg p-6 max-w-2xl w-full my-8 max-h-[90vh] flex flex-col shadow-xl dark:shadow-dark-primary/30 transition-colors duration-200">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
            {editingContent ? 'Edit Content' : 'Add Content'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 transition-colors duration-200"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleFormSubmit} className="space-y-4 overflow-y-auto flex-1">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
              Content Type
            </label>
            <select
              value={selectedContentType}
              onChange={handleContentTypeChange}
              disabled={editingContent?.id !== undefined}
              className="mt-1 block w-full rounded-lg border-gray-300 dark:border-white/20 
                dark:bg-dark-primary shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                text-gray-900 dark:text-white transition-colors duration-200"
              required
            >
              {contentTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
              Sequence
            </label>
            <input
              type="number"
              name="sequence"
              defaultValue={editingContent?.sequence ?? content.length + 1}
              className="mt-1 block w-full rounded-lg border-gray-300 dark:border-white/20 
                dark:bg-dark-primary shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                text-gray-900 dark:text-white transition-colors duration-200"
              required
            />
          </div>

          <ContentTypeFields
            type={selectedContentType}
            editingContent={editingContent || { 
              content_type: selectedContentType,
              content: editingContent?.content || {},
              section_id: sectionId 
            }}
          />

          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-white/90 bg-gray-100 
                dark:bg-dark-primary rounded-lg hover:bg-gray-200 
                dark:hover:bg-dark-primary-light transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white rounded-lg transition-all 
                transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
              style={{ 
                backgroundColor: styles.accent.color,
                backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
              }}
            >
              {editingContent ? 'Save Changes' : 'Create Content'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentFormModal;