import React from 'react';

const EnrollmentModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const styles = {
    accent: '#59a52c'
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Course Enrollment Status</h2>
        <p className="text-gray-600 mb-6">
          We apologize, but we are currently not accepting new trainees at this time. 
          Please check back later or follow our blog for announcements about future enrollment periods.
        </p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            style={{ backgroundColor: styles.accent }}
            className="px-4 py-2 text-white rounded-lg hover:opacity-90 transition-opacity"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentModal;