import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash2, X, AlertCircle } from 'lucide-react';

const QuizContentManager = ({ initialQuiz = { questions: [] }, onQuizUpdate }) => {
  const [quiz, setQuiz] = useState(initialQuiz);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [validationError, setValidationError] = useState('');

  // Update parent whenever quiz changes
  // Lock body scroll when modal is open
  useEffect(() => {
    if (editingQuestion !== null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [editingQuestion]);

  const validateQuiz = () => {
    if (!quiz.questions || quiz.questions.length === 0) {
      return 'Quiz must have at least one question';
    }

    for (let i = 0; i < quiz.questions.length; i++) {
      const question = quiz.questions[i];
      if (!question.text || question.text.trim() === '') {
        return `Question ${i + 1} must have text`;
      }
      if (!question.options || question.options.length < 2) {
        return `Question ${i + 1} must have at least 2 options`;
      }
      if (question.options.some(opt => !opt || opt.trim() === '')) {
        return `Question ${i + 1} has empty options`;
      }
      if (question.correctAnswer === undefined || question.correctAnswer < 0 || 
          question.correctAnswer >= question.options.length) {
        return `Question ${i + 1} must have a valid correct answer`;
      }
    }
    return '';
  };

  const handleQuestionSubmit = (questionData) => {
    setValidationError('');
  
    setQuiz(prevQuiz => {
      const updatedQuestions = editingQuestion >= 0 
        ? prevQuiz.questions.map((q, index) => 
            index === editingQuestion ? questionData : q
          )
        : [...prevQuiz.questions, questionData];
      
      const updatedQuiz = {
        ...prevQuiz,
        questions: updatedQuestions
      };
      
      // Call onQuizUpdate with the updated quiz
      onQuizUpdate(updatedQuiz);
      
      return updatedQuiz;
    });
  
    setEditingQuestion(null);
  };

  const handleDeleteQuestion = (indexToRemove) => {
    setValidationError('');
    setQuiz(prevQuiz => ({
      ...prevQuiz,
      questions: prevQuiz.questions.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = () => {
    const error = validateQuiz();
    if (error) {
      setValidationError(error);
      return;
    }
    onQuizUpdate(quiz);
  };

  return (
    <div className="space-y-6">
      {validationError && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2">
          <AlertCircle className="text-red-500 flex-shrink-0" />
          <span className="text-red-700">{validationError}</span>
        </div>
      )}

      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Questions ({quiz.questions.length})</h3>
        {quiz.questions.map((question, index) => (
          <div 
            key={index} 
            className="border rounded-lg p-4 bg-gray-50"
          >
            <div className="flex justify-between items-start">
              <div className="flex-grow">
                <h4 className="font-medium mb-2">Question {index + 1}</h4>
                <p className="text-gray-700">{question.text}</p>
                <div className="mt-2 space-y-1">
                  {question.options.map((option, optIndex) => (
                    <div 
                      key={optIndex}
                      className={`text-sm ${
                        optIndex === question.correctAnswer 
                          ? 'text-green-600 font-medium' 
                          : 'text-gray-600'
                      }`}
                    >
                      {optIndex === question.correctAnswer ? '✓ ' : ''}
                      {option}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-2 ml-4">
                <button
                  type="button"
                  onClick={() => setEditingQuestion(index)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  <Edit size={16} />
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteQuestion(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {editingQuestion !== null && (
        <QuestionModal
          key={editingQuestion}
          initialQuestion={
            editingQuestion >= 0
              ? quiz.questions[editingQuestion] 
              : { text: '', options: ['', '', '', ''], correctAnswer: 0 }
          }
          onSubmit={handleQuestionSubmit}
          onClose={() => setEditingQuestion(null)}
        />
      )}

      <div className="flex items-center gap-4">
        <button
          type="button"
          onClick={() => setEditingQuestion(-1)}
          className="flex items-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
        >
          <Plus size={16} /> Add Question
        </button>
      </div>
    </div>
  );
};

const QuestionModal = ({ initialQuestion, onSubmit, onClose }) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [validationError, setValidationError] = useState('');

  const validateQuestion = () => {
    if (!question.text.trim()) {
      return 'Question text is required';
    }
    if (question.options.some(opt => !opt.trim())) {
      return 'All options must be filled';
    }
    if (question.correctAnswer < 0 || question.correctAnswer >= question.options.length) {
      return 'Please select a correct answer';
    }
    return '';
  };

  const handleSubmit = () => {
    const error = validateQuestion();
    if (error) {
      setValidationError(error);
      return;
    }
    onSubmit(question);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-hidden">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center mb-4 flex-shrink-0">
          <h2 className="text-xl font-bold">
            {initialQuestion.text ? 'Edit Question' : 'Add Question'}
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={24} />
          </button>
        </div>

        {validationError && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm flex items-center gap-2 flex-shrink-0">
            <AlertCircle size={16} />
            {validationError}
          </div>
        )}

        <div className="space-y-4 overflow-y-auto flex-grow pr-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Question Text
            </label>
            <input
              type="text"
              value={question.text}
              onChange={(e) => setQuestion(prev => ({ ...prev, text: e.target.value }))}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="Enter your question"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Options
            </label>
            {question.options.map((option, index) => (
              <div key={index} className="flex items-center gap-2">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...question.options];
                    newOptions[index] = e.target.value;
                    setQuestion(prev => ({ ...prev, options: newOptions }));
                  }}
                  className="flex-1 px-3 py-2 border rounded-md"
                  placeholder={`Option ${index + 1}`}
                />
                <input
                  type="radio"
                  name="correctAnswer"
                  checked={question.correctAnswer === index}
                  onChange={() => setQuestion(prev => ({ ...prev, correctAnswer: index }))}
                  className="h-4 w-4"
                />
              </div>
            ))}
          </div>

          <div className="flex justify-end gap-2 pt-4 mt-4 border-t flex-shrink-0">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
            >
              Save Question
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizContentManager;