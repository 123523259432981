// src/components/EnterpriseMatrixApp.js
import React, { useState, useEffect, useRef } from 'react';
import EnterpriseMatrix from './EnterpriseMatrix';
import EstimatesBreakdown from './EstimatesBreakdown';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { themeStyles } from './SharedComponents';
import html2canvas from 'html2canvas';
import Editor from '@monaco-editor/react';

// Storage key for local storage
const STORAGE_KEY = 'enterprise-bus-matrix-code';

const EnterpriseMatrixApp = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const [code, setCode] = useState(initialCode);
  const [matrixData, setMatrixData] = useState(null);
  const [error, setError] = useState(null);
  const [editorCollapsed, setEditorCollapsed] = useState(false);
  const [panelSizes, setPanelSizes] = useState({ editor: 33, visualization: 67 });
  const [isDragging, setIsDragging] = useState(false);
  const contentRef = useRef(null);
  const isDraggingRef = useRef(false);
  const editorRef = useRef(null);
  const rafRef = useRef(null);
  
  // Save matrix code to local storage
  const saveToLocalStorage = (codeToSave) => {
    try {
      localStorage.setItem(STORAGE_KEY, codeToSave);
    } catch (err) {
      console.error('Error saving to local storage:', err);
      // We don't need to show an error to the user for storage failures
    }
  };
  
  // Load matrix code from local storage
  const loadFromLocalStorage = () => {
    try {
      const savedCode = localStorage.getItem(STORAGE_KEY);
      if (savedCode) {
        return savedCode;
      }
    } catch (err) {
      console.error('Error loading from local storage:', err);
    }
    return initialCode; // Fall back to the default code if nothing in storage
  };

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'col-resize';
      document.body.style.userSelect = 'none';
    } else {
      document.body.style.cursor = '';
      document.body.style.userSelect = '';
    }
  }, [isDragging]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (editorRef.current && !editorCollapsed) {
        // Debounce the resize to prevent multiple simultaneous updates
        const timeoutId = setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.layout();
          }
        }, 100);
  
        return () => clearTimeout(timeoutId);
      }
    };
    
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [editorCollapsed]);

  useEffect(() => {
    // Only run this when the theme changes
    if (!window.monaco) return; // Safety check
    
    // Define the custom dark theme by extending vs-dark
    window.monaco.editor.defineTheme('custom-dark-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': styles.secondary.color,
      }
    });
    
  }, [effectiveTheme, styles.secondary.color]);

  // Load saved code on component mount
  useEffect(() => {
    const savedCode = loadFromLocalStorage();
    setCode(savedCode);
  }, []);

  useEffect(() => {
    try {
      // Parse the code into matrix data
      const result = evaluateCode(code);
      setMatrixData(result);
      setError(null);
    } catch (err) {
      console.error('Code evaluation error:', err);
      setError(err.message);
    }
  }, [code]);

  const handleEditorChange = (value) => {
    setCode(value);
    // Auto-save to local storage whenever code changes
    saveToLocalStorage(value);
  };

  const toggleEditorCollapse = () => {
    setEditorCollapsed(!editorCollapsed);
    
    // When toggling, make sure to update editor layout after state change
    setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    }, 300); // Match this with the transition duration
  };

  const startDragging = (e) => {
    e.preventDefault();
    isDraggingRef.current = true;
    setIsDragging(true);
    document.addEventListener('mousemove', handleDragging);
    document.addEventListener('mouseup', stopDragging);
    document.body.classList.add('resizing');
  };
  
  const handleDragging = (e) => {
    // Cancel any pending animation frame
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    
    rafRef.current = requestAnimationFrame(() => {
      if (!isDraggingRef.current || !contentRef.current) return;
      
      const containerRect = contentRef.current.getBoundingClientRect();
      const editorWidthPercent = Math.max(20, Math.min(80, 
        ((e.clientX - containerRect.left) / containerRect.width) * 100
      ));
      
      // Update state instead of direct DOM manipulation
      setPanelSizes({
        editor: editorWidthPercent,
        visualization: 100 - editorWidthPercent
      });
    });
  };
  
  const stopDragging = () => {
    if (!isDraggingRef.current) return;
    
    // Cancel any pending animation frame
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = null;
    }
    
    // Clean up
    isDraggingRef.current = false;
    setIsDragging(false);
    document.removeEventListener('mousemove', handleDragging);
    document.removeEventListener('mouseup', stopDragging);
    document.body.classList.remove('resizing');
  };
  
  // Clean up event listeners when component unmounts
  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      document.removeEventListener('mousemove', handleDragging);
      document.removeEventListener('mouseup', stopDragging);
      document.body.classList.remove('resizing');
    };
  }, []);

  const exportToPng = () => {
    if (!matrixData) return;
    
    // Find the matrix element to capture
    const matrixElement = document.querySelector('[data-export-container]');
    
    if (!matrixElement) {
      setError("Couldn't find the matrix element to export");
      return;
    }
    
    // Configure html2canvas with better quality settings
    html2canvas(matrixElement, {
      scale: 2, // Higher scale for better quality
      backgroundColor: effectiveTheme === 'dark' ? '#160d1c' : '#ffffff',
      logging: false,
      useCORS: true
    }).then(canvas => {
      // Convert canvas to PNG data URL
      const imgData = canvas.toDataURL('image/png');
      
      // Create a temporary link and trigger download
      const a = document.createElement('a');
      a.href = imgData;
      a.download = 'enterprise-bus-matrix.png';
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      document.body.removeChild(a);
    }).catch(err => {
      console.error('Error exporting to PNG:', err);
      setError('Failed to export the matrix as PNG.');
    });
  };

  const exportCode = () => {
    // Create a blob with the current code
    const blob = new Blob([code], { type: 'text/ebm' });
    const url = URL.createObjectURL(blob);
    
    // Create a temporary link and trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'enterprise-bus-matrix.ebm';
    document.body.appendChild(a);
    a.click();
    
    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const importCode = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedCode = e.target.result;
        setCode(importedCode);
        // Also save the imported code to local storage
        saveToLocalStorage(importedCode);
      } catch (error) {
        setError("Failed to import the file. Please make sure it's a valid Enterprise Bus Matrix file.");
      }
    };
    reader.readAsText(file);
  };

  const resetToDefault = () => {
    if (window.confirm('Are you sure you want to reset to the default matrix? This will discard your current work.')) {
      setCode(initialCode);
      saveToLocalStorage(initialCode);
    }
  };

  return (
    <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
      {/* Toolbar */}
      <div className="p-4 border-b border-gray-200 dark:border-gray-700/50 flex justify-between items-center">
        <div className="flex items-center">
          <button
            onClick={toggleEditorCollapse}
            className="flex items-center justify-center w-8 h-8 rounded-md hover:bg-gray-100 dark:hover:bg-dark-primary text-gray-700 dark:text-gray-300 mr-3"
          >
            {editorCollapsed ? '→' : '←'}
          </button>
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white transition-colors duration-200">
            Matrix Definition
          </h2>
        </div>
        <div className="flex gap-3">
          <label className="flex items-center gap-2 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg cursor-pointer text-gray-700 dark:text-white/90 hover:bg-gray-50 dark:hover:bg-dark-primary transition-colors duration-200">
            <span>Import</span>
            <input 
              type="file" 
              accept=".ebm,.txt" 
              onChange={importCode} 
              className="hidden" 
            />
          </label>
          <button
            onClick={exportCode}
            className="px-4 py-2 text-white rounded-lg transition-all hover:scale-105 shadow-sm"
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          >
            Export Code
          </button>
          <button
            onClick={exportToPng}
            className="px-4 py-2 text-white rounded-lg transition-all hover:scale-105 shadow-sm"
            style={{ 
              backgroundColor: styles.secondary.color,
              backgroundImage: `linear-gradient(to right, ${styles.secondary.color}, ${styles.primary.color})` 
            }}
          >
            Export as PNG
          </button>
          <button
            onClick={resetToDefault}
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white/90 rounded-lg transition-all hover:bg-gray-300 dark:hover:bg-gray-600"
          >
            Reset
          </button>
        </div>
      </div>
      
      {/* Main Content Area */}
      <div 
        ref={contentRef} 
        className="flex relative" 
        style={{ height: 'calc(100vh - 250px)', minHeight: '500px' }}
      >
        {/* Editor Panel */}
        <div 
          className={`transition-all duration-300 flex flex-col border-r border-gray-200 dark:border-gray-700/50 
                      ${editorCollapsed ? 'w-[40px]' : ''}`}
          style={{ 
            width: editorCollapsed ? '40px' : `${panelSizes.editor}%`,
            flexShrink: 0  // Prevent shrinking
          }}
        >
          {!editorCollapsed ? (
            <div className="h-full overflow-auto bg-white dark:bg-dark-primary transition-colors duration-200">
              <Editor
                height="100%"
                width="100%"
                defaultLanguage="javascript"
                value={code}
                onChange={handleEditorChange}
                theme={effectiveTheme === 'dark' ? "custom-dark-theme" : "vs-light"}
                options={{
                  minimap: { enabled: false },
                  fontSize: 14,
                  wordWrap: 'on',
                  automaticLayout: true, // Add automatic layout option
                }}
                onMount={(editor) => {
                  editorRef.current = editor;
                }}
                beforeMount={(monaco) => {
                  // This ensures monaco is available before we try to define themes
                  monaco.editor.defineTheme('custom-dark-theme', {
                    base: 'vs-dark',
                    inherit: true,
                    rules: [],
                    colors: {
                      'editor.background': styles.secondary.color,
                    }
                  });
                }}
              />
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <span className="transform -rotate-90 text-gray-500 whitespace-nowrap">Code Editor</span>
            </div>
          )}
        </div>
        
        {/* Resize Handle - increased width for easier grabbing */}
        <div 
          className={`w-[5px] bg-gray-200 dark:bg-gray-700 cursor-col-resize absolute top-0 bottom-0 z-10 
                    hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200
                    ${editorCollapsed ? 'hidden' : ''}`}
          onMouseDown={startDragging}
          style={{ left: `${panelSizes.editor}%` }}
        ></div>
        
        {/* Visualization Panel */}
        <div 
          className="transition-all duration-300 overflow-y-auto p-4"
          style={{ 
            width: editorCollapsed ? 'calc(100% - 40px)' : `${panelSizes.visualization}%`,
            flexShrink: 0  // Prevent shrinking
          }}
        >
          {error && (
            <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 rounded-lg text-red-700 dark:text-red-300 transition-colors duration-200">
              {error}
            </div>
          )}
          
          {matrixData && (
            <div data-export-container>
              <EnterpriseMatrix data={matrixData} />
              <EstimatesBreakdown data={matrixData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Function to safely evaluate the code and extract matrix data
function evaluateCode(code) {
  try {
    // Create objects to capture Facts, Dimensions, and Bridges
    const Facts = {};
    const Dimensions = {};
    const Bridges = {};  // New object for Bridge Tables
    
    // Define Status constants
    const Status = {
      COMPLETE: 'complete',
      IN_PROGRESS: 'in-progress',
      NOT_STARTED: 'not-started',
      ISSUES: 'issues'
    };
    
    // Define Fibonacci points
    const FibonacciPoints = [1, 2, 3, 5, 8, 13, 21];
    
    // Execute the code in this context
    new Function('Facts', 'Dimensions', 'Bridges', 'Status', 'FibonacciPoints', code)(
      Facts, Dimensions, Bridges, Status, FibonacciPoints
    );
    
    // Validate the data structure
    if (!Facts || typeof Facts !== 'object' || Object.keys(Facts).length === 0) {
      throw new Error('Facts must be defined as a non-empty object');
    }
    
    if (!Dimensions || typeof Dimensions !== 'object' || Object.keys(Dimensions).length === 0) {
      throw new Error('Dimensions must be defined as a non-empty object');
    }
    
    // Process the data into the format needed for visualization
    return {
      facts: Object.entries(Facts).map(([name, config]) => ({
        name,
        ...config,
        tableType: 'fact'
      })),
      dimensions: Object.entries(Dimensions).map(([name, config]) => ({
        name,
        ...config
      })),
      bridges: Object.entries(Bridges || {}).map(([name, config]) => ({
        name,
        ...config,
        tableType: 'bridge'
      }))
    };
  } catch (error) {
    throw new Error(`Error evaluating code: ${error.message}`);
  }
}

// Initial demo code that appears in the editor
const initialCode = `// Define your dimensions
Dimensions.Customer = {
  // Define the status of the dimension: COMPLETE, IN_PROGRESS, NOT_STARTED, ISSUES
  status: Status.NOT_STARTED,
  description: "Contains all customer information including demographic data, contact details, and customer segmentation attributes.",
  // Add estimates for each phase of development still to be complete
  estimates: {
    analysis: 1,
    modeling: 2,
    mapping: 1,
    buildTest: 3
  }
};

Dimensions.Product = {
  status: Status.COMPLETE,
  description: "Products sold by the organization, including categorization, pricing tiers, and product attributes.",
  // Estimates are not needed for complete tables
};

Dimensions.Employee = {
  status: Status.IN_PROGRESS,
  description: "Employee data including department, role, location, and hierarchical reporting structure.",
  // Estimates are only needed for remaining work
  estimates: {
    mapping: 2,
    buildTest: 8
  }
};

Dimensions.Calendar = {
  status: Status.COMPLETE,
  description: "Time dimension with date attributes including fiscal periods, holidays, and seasonal indicators."
};

Dimensions.Location = {
  status: Status.NOT_STARTED,
  description: "Geographic locations including stores, warehouses, and customer locations with regional hierarchies.",
  estimates: {
    analysis: 3,
    modeling: 3,
    mapping: 2,
    buildTest: 5
  }
};

Dimensions.PaymentTerm = {
  status: Status.ISSUES,
  description: "Payment terms and conditions associated with customer transactions.",
  estimates: {
    analysis: 2,
    modeling: 2,
    mapping: 1,
    buildTest: 3
  }
};

// Define your fact tables
Facts.Activity = {
  status: Status.COMPLETE,
  description: "Customer activity tracking for sales and support interactions.",
  // Using dimensionRoles link dimensions to facts, defining role-playing dimensions
  dimensionRoles: [
    { dimension: "Calendar", role: "Activity Date" },
    { dimension: "Product", role: "Product" },
    { dimension: "Customer", role: "Customer" },
    { dimension: "Employee", role: "Assigned To" }
  ]
};

Facts.Assessments = {
  status: Status.IN_PROGRESS,
  description: "Customer assessment data collected during evaluation processes.",
  dimensionRoles: [
    { dimension: "Calendar", role: "Assessment Date" },
    { dimension: "Customer", role: "Customer" },
    { dimension: "Location", role: "Assessment Location" }
  ],
  estimates: {
    modeling: 3,
    mapping: 3,
    buildTest: 8
  }
};

Facts.SalesInvoices = {
  status: Status.IN_PROGRESS,
  description: "Invoice header information for customer purchases.",
  dimensionRoles: [
    { dimension: "Calendar", role: "Invoice Date" },
    { dimension: "Calendar", role: "Due Date" },
    { dimension: "Product", role: "Product" },
    { dimension: "Customer", role: "Customer" },
    { dimension: "Location", role: "Customer Location" }
  ],
  // Identify linked facts and the relationship type
  linkedFacts: [
    { name: "SalesInvoiceLineItems", relationship: "Parent-Child" }
  ],
  estimates: {
    mapping: 3,
    buildTest: 8
  }
};

Facts.SalesInvoiceLineItems = {
  status: Status.IN_PROGRESS,
  description: "Detailed line item information for each sales invoice.",
  // Dimensions can be used instead of dimensionRoles for simpler relationships
  dimensions: ["Product", "PaymentTerm", "Location"],
  linkedFacts: [
    { name: "SalesInvoices", relationship: "Child-Parent" }
  ],
  estimates: {
    buildTest: 5
  }
};

// Define your bridge tables
Bridges.CustomerProductInterest = {
  status: Status.IN_PROGRESS,
  description: "Maps customers to products they've expressed interest in, with interest level scoring.",
  dimensionRoles: [
    { dimension: "Customer", role: "Customer" },
    { dimension: "Product", role: "Product" }
  ],
  estimates: {
    mapping: 2,
    buildTest: 3
  }
};

Bridges.ProductLocationInventory = {
  status: Status.NOT_STARTED,
  description: "Maps products to locations with inventory levels and restock thresholds.",
  dimensionRoles: [
    { dimension: "Product", role: "Product" },
    { dimension: "Location", role: "Location" }
  ],
  estimates: {
    analysis: 1,
    modeling: 2,
    mapping: 3,
    buildTest: 5
  }
};`;

export default EnterpriseMatrixApp;