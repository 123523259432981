import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthProvider } from './components/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import AnalyticsTracker from './components/AnalyticsTracker';
import ScrollToTop from './ScrollToTop';
import Home from './Home';
import OurPlatform from './OurPlatform';
import Tracks from './Tracks';
import { ThemeProvider } from './contexts/ThemeContext';
import ProgressDashboard from './components/ProgressDashboard';
import TrainingModule from './components/TrainingModule';
import CertificatePage from './components/CertificatePage';
import SignupInitiate from './components/SignupInitiate';
import SignupCompletion from './components/SignupCompletion';
import PasswordReset from './components/PasswordReset';
import AccountManagement from './components/AccountManagement';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancelled from './components/PaymentCancelled';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';
import OurStory from './OurStory';
import HelpCenter from './HelpCenter';
import ContactUs from './ContactUs';
import Hiring from './Hiring';
import EBMatrix from './EBMatrix';
import SQLViz from './SQLViz';
import AdminRoute from './components/admin/AdminRoute';
import StatisticsDashboard from './components/admin/StatisticsDashboard';
import CourseManager from './components/admin/CourseManager';
import ModuleManager from './components/admin/ModuleManager';
import SectionManager from './components/admin/SectionManager';
import ContentManager from './components/admin/ContentManager';
import AuditLogs from './components/admin/AuditLogs';

const BASE_URL = 'https://datatraininglab.com';
const OG_IMAGE_URL = `${BASE_URL}/og-image.jpg`;

// SEO metadata for each route
const routes = {
  home: {
    path: '/',
    element: <Home />,
    title: 'Data Training Lab | Expert Data Training',
    description: 'Advance your career with expert-led training in data analytics, data engineering, and data governance. Join Data Training Lab for hands-on, project-based learning.',
    keywords: 'data leadership training, data analytics course, data architecture education, data engineering bootcamp',
  },
  ourPlatform: {
    path: '/our-platform',
    element: <OurPlatform />,
    title: 'Our Platform | Data Training Lab',
    description: 'Learn about Data Training Lab\'s innovative approach to data education. We combine real-world scenarios with expert mentorship for an immersive learning experience.',
    keywords: 'data leadership training, data analytics course, data architecture education, data engineering bootcamp',
  },
  helpCenter: {
    path: '/help-center',
    element: <HelpCenter />,
    title: 'Help Center | Data Training Lab',
    description: 'Find answers to frequently asked questions and guides to help you make the most of your learning experience.',
    keywords: 'help center, faqs, guides, support, data training',
  },
  tracks: {
    path: '/tracks',
    element: <Tracks />,
    title: 'Learning Pathways | Data Training Lab',
    description: 'Explore our immersive, project-based learning pathways in data engineering, analytics, and governance. Get hands-on experience with real-world scenarios at Summit Stay Hotel.',
    keywords: 'data engineering course, data analytics training, data governance education, project-based learning, hotel data analysis',
  },
  hiring: {
    path: '/hiring',
    element: <Hiring />,
    title: 'Careers | Data Training Lab',
    description: 'Join the Data Training Lab team and help shape the future of data education. Explore career opportunities in data education, technology, and more.',
    keywords: 'data training careers, data education jobs, tech careers, remote jobs, data training lab employment, data education careers',
  },
  dashboard: {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <ProgressDashboard />
      </ProtectedRoute>
    ),
    title: 'Learning Progress | Data Training Lab',
    description: 'Track your learning progress across Data Training Lab courses and modules.',
    keywords: 'learning progress, course tracking, module completion, data training dashboard',
  },
    contact: {
    path: '/contact',
    element: <ContactUs />,
    title: 'Contact Us | Data Training Lab',
    description: 'Get in touch with the Data Training Lab team. Whether you have questions about our courses, need technical support, or want to learn more about our platform, we are here to help.',
    keywords: 'data training contact, data education support, learning platform help, technical support, data training questions, course information',
  },
  esbMatrix: {
    path: '/apps/ebm-as-code',
    element: <EBMatrix />,
    title: 'Enterprise Bus Matrix | Data Training Lab',
    description: 'Create and visualize data warehouse bus matrices using our interactive code-based tool.',
    keywords: 'data warehouse, bus matrix, dimension modeling, data modeling, kimball methodology, data engineering',
  },
  sqlViz: {
    path: '/apps/sql-viz',
    element: <SQLViz />,
    title: 'SQL Vizualizer | Data Training Lab',
    description: 'Show the lineage of your SQL queries in a visual way.',
    keywords: 'data warehouse, sql, data modeling, data engineering',
  },
  module: {
    path: '/module/:moduleId',
    element: (
      <ProtectedRoute>
        <TrainingModule />
      </ProtectedRoute>
    ),
    title: 'Training Module | Data Training Lab',
    description: 'Interactive learning module for your selected course.',
    keywords: 'training module, interactive learning, data education, course content',
  },
  certificate: {
    path: '/certificate',
    element: (
      <ProtectedRoute>
        <CertificatePage />
      </ProtectedRoute>
    ),
    title: 'Certificate | Data Training Lab',
    description: 'Congratulations on your success.',
    keywords: 'training, interactive learning, data education, certification',
  },  
  signup: {
    path: '/signup_not_public',
    element: <SignupInitiate />,
    title: 'Sign Up | Data Training Lab',
    description: 'Join Data Training Lab to start your learning journey in data engineering, analytics, and governance.',
    keywords: 'signup, registration, data training, learning platform',
  },
  verifyEmail: {
    path: '/verify-email',
    element: <SignupCompletion />,
    title: 'Complete Registration | Data Training Lab',
    description: 'Complete your registration for Data Training Lab.',
    keywords: 'email verification, complete registration, signup completion',
  },
  account: {
    path: '/account',
    element: (
      <ProtectedRoute>
        <AccountManagement />
      </ProtectedRoute>
    ),
    title: 'Account Settings | Data Training Lab',
    description: 'Manage your Data Training Lab account settings and preferences.',
    keywords: 'account settings, profile management, password reset',
  },
  ourStory: {
    path: '/our-story',
    element: <OurStory />,
    title: 'Our Story | Data Training Lab',
    description: 'Learn about the founder\'s journey and the mission behind Data Training Lab, a platform dedicated to transforming data education through immersive, real-world learning experiences.',
    keywords: 'data training origin, data professional journey, data education mission, learning platform founder'
  },
  resetPassword: {
    path: '/reset-password',
    element: <PasswordReset />,
    title: 'Reset Password | Data Training Lab',
    description: 'Reset your Data Training Lab account password.',
    keywords: 'password reset, account security',
  },
  paymentSuccess: {
    path: '/payment/success',
    element: <PaymentSuccess />,
    title: 'Payment Successful | Data Training Lab',
    description: 'Confirming your enrollment in Data Training Lab.',
    keywords: 'payment confirmation, enrollment, data training',
  },
  paymentCancelled: {
    path: '/payment/cancelled',
    element: <PaymentCancelled />,
    title: 'Payment Cancelled | Data Training Lab',
    description: 'Cancelled payment to Data Training Lab.',
    keywords: 'payment cancelled, enrollment, data training',
  },
  terms: {
    path: '/terms',
    element: <TermsOfUse />,
    title: 'Terms of Use | Data Training Lab',
    description: 'Review Data Training Lab\'s Terms of Use, covering platform access, course enrollment, intellectual property rights, and user obligations for our data education services.',
    keywords: 'terms of use, legal terms, data training terms, course enrollment terms, platform usage terms, data education agreement',
  },
  privacy: {
    path: '/privacy',
    element: <PrivacyPolicy />,
    title: 'Privacy Policy | Data Training Lab',
    description: 'Learn how Data Training Lab protects and manages your personal information, data rights, and privacy in our educational platform.',
    keywords: 'privacy policy, data protection, user privacy, data security, personal information, data rights',
  },
  cookies: {
    path: '/cookies',
    element: <CookiePolicy />,
    title: 'Cookie Policy | Data Training Lab',
    description: 'Understanding how Data Training Lab uses essential cookies to provide basic site functionality and improve your learning experience.',
    keywords: 'cookie policy, website cookies, essential cookies, site functionality, cookie usage',
  },
  adminDashboard: {
    path: '/my_admin_console',
    element: (
      <AdminRoute>
        <StatisticsDashboard />
      </AdminRoute>
    ),
    title: 'Admin Dashboard | Data Training Lab',
    description: 'Administrative dashboard for Data Training Lab platform management.',
    keywords: 'admin, dashboard, management, data training',
  },
  adminCourses: {
    path: '/my_admin_console/courses',
    element: (
      <AdminRoute>
        <CourseManager />
      </AdminRoute>
    ),
    title: 'Course Management | Data Training Lab',
    description: 'Manage courses and curriculum content for Data Training Lab.',
    keywords: 'course management, content management, admin',
  },
  adminModules: {
    path: '/my_admin_console/modules/:courseId?',
    element: (
      <AdminRoute>
        <ModuleManager />
      </AdminRoute>
    ),
    title: 'Module Management | Data Training Lab',
    description: 'Manage training modules and their content for Data Training Lab.',
    keywords: 'module management, course content, admin',
  },
  adminSections: {
    path: '/my_admin_console/sections/:moduleId?',
    element: (
      <AdminRoute>
        <SectionManager />
      </AdminRoute>
    ),
    title: 'Section Management | Data Training Lab',
    description: 'Manage module sections and content organization for Data Training Lab.',
    keywords: 'section management, content organization, admin',
  },
  adminContent: {
    path: '/my_admin_console/content/:sectionId?',
    element: (
      <AdminRoute>
        <ContentManager />
      </AdminRoute>
    ),
    title: 'Content Management | Data Training Lab',
    description: 'Manage section content and learning materials for Data Training Lab.',
    keywords: 'content management, learning materials, admin',
  },
  adminAudit: {
    path: '/my_admin_console/audit',
    element: (
      <AdminRoute>
        <AuditLogs />
      </AdminRoute>
    ),
    title: 'Audit Logs | Data Training Lab',
    description: 'View and analyze system audit logs for Data Training Lab.',
    keywords: 'audit logs, system logs, admin, security',
  },
  adminStats: {
    path: '/my_admin_console/statistics',
    element: (
      <AdminRoute>
        <StatisticsDashboard />
      </AdminRoute>
    ),
    title: 'Platform Statistics | Data Training Lab',
    description: 'View platform statistics and analytics for Data Training Lab.',
    keywords: 'statistics, analytics, metrics, admin',
  }
};

function App() {
  // Structured data for the organization and tracks
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Data Training Lab",
    "url": BASE_URL,
    "description": "Expert-led training in data analytics, data engineering, and data governance",
    "offers": [
      {
        "@type": "Course",
        "name": "Data Engineering",
        "description": "Step into the role of a Data Engineer at Summit Stay Hotel in our comprehensive 24-week program. Build a complete data infrastructure from the ground up, working with our Property Management System (PMS) API.",
        "provider": {
          "@type": "Organization",
          "name": "Data Training Lab"
        },
        "timeRequired": "P24W",
        "educationalLevel": "Professional",
        "teaches": [
          "Python and SQL development",
          "Cloud platforms (AWS/Snowflake)",
          "Apache Airflow orchestration",
          "Terraform for infrastructure",
          "CI/CD implementation",
          "Data quality frameworks"
        ]
      },
      {
        "@type": "Course",
        "name": "Data Analytics",
        "description": "Master the art of transforming hotel data into actionable insights. Work with real-life datasets from Summit Stay Hotel's data warehouse, learn advanced analytics techniques, and develop data-informed decision making skills.",
        "provider": {
          "@type": "Organization",
          "name": "Data Training Lab"
        },
        "timeRequired": "P24W",
        "educationalLevel": "Professional",
        "teaches": [
          "Advanced SQL and Python analytics",
          "Statistical analysis and modeling",
          "Power BI and Tableau",
          "Machine learning fundamentals",
          "Data visualization techniques",
          "Business intelligence tools"
        ]
      },
      {
        "@type": "Course",
        "name": "Data Governance",
        "description": "Master data governance, develop robust frameworks, ensure compliance with regulations, and lead data initiatives that balance innovation with risk management at Summit Stay Hotel.",
        "provider": {
          "@type": "Organization",
          "name": "Data Training Lab"
        },
        "timeRequired": "P24W",
        "educationalLevel": "Professional",
        "teaches": [
          "Data governance frameworks",
          "Privacy and compliance regulations",
          "Metadata management",
          "Data quality assessment",
          "Risk management",
          "Policy automation approaches"
        ]
      }
    ]
  };

  return (
    <ThemeProvider>
      <AuthProvider>
        <ScrollToTop />
        <AnalyticsTracker />
        {/* Default SEO tags */}
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#311d3f" />
          <link rel="canonical" href={BASE_URL} />
          
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={BASE_URL} />
          <meta property="og:title" content="Data Training Lab" />
          <meta property="og:description" content="Expert-led training in data analytics, data engineering, and data governance" />
          <meta property="og:image" content={OG_IMAGE_URL} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:site_name" content="Data Training Lab" />

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content={BASE_URL} />
          <meta name="twitter:title" content="Data Training Lab" />
          <meta name="twitter:description" content="Expert-led training in data analytics, data engineering, and data governance" />
          <meta name="twitter:image" content={OG_IMAGE_URL} />

          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>

        <Routes>
          {Object.values(routes).map(({ path, element, title, description, keywords }) => (
            <Route
              key={path}
              path={path}
              element={
                <>
                  <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                    <link rel="canonical" href={`${BASE_URL}${path}`} />
                    <meta property="og:url" content={`${BASE_URL}${path}`} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta name="twitter:url" content={`${BASE_URL}${path}`} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                  </Helmet>
                  {element}
                </>
              }
            />
          ))}

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;