import React, { useState } from 'react';
import { Play } from 'lucide-react';

const VimeoPlayer = ({ 
  videoId,
  hash,  // Added hash parameter
  title = '',
  previewImageUrl = '/api/placeholder/800/450',
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  
  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="w-full rounded-lg overflow-hidden shadow-xl bg-gray-900">
      {!isPlaying ? (
        <div className="relative w-full" style={{ paddingTop: '56.25%' }}> 
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src={previewImageUrl}
              alt={title || 'Video preview'}
              className="absolute inset-0 w-full h-full object-cover" 
              style={{top: -32}}
            />
            <div className="absolute inset-0 bg-black bg-opacity-30" />
            <button
              onClick={handlePlayClick}
              className="relative z-10 p-4 rounded-full bg-white bg-opacity-90 hover:bg-opacity-100 transition-all transform hover:scale-105 group"
              aria-label="Play video"
            >
              <Play 
                size={32} 
                className="text-gray-900 group-hover:text-black transition-colors" 
              />
            </button>
            <div className="absolute bottom-4 left-4 right-4 text-white text-lg font-medium">
              {title}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src={`https://player.vimeo.com/video/${videoId}?h=${hash}&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title={title || 'Video player'}
          />
        </div>
      )}
    </div>
  );
};

export default VimeoPlayer;