import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import { 
  HeroSection,
  CardGrid,
  themeStyles
} from './components/SharedComponents';


function OurPlatform() {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const approachFeatures = [
    {
      title: 'Immersive Learning Environment',
      description: "Step into realistic workplace scenarios where you'll tackle real challenges. Our innovative platform puts you in the role of a data professional at Summit Stay Hotel."
    },
    {
      title: 'Interactive Mentorship',
      description: 'Connect with experienced industry professionals who guide you through your learning journey. Our mentors provide personalized feedback and help you develop both technical skills and professional judgment.'
    },
    {
      title: 'Project-Based Learning',
      description: "Learn by building production-ready solutions. From developing data warehouses to creating analytics dashboards, you'll work with real-time data and industry-standard tools."
    }
  ];

  const ExperienceCard = ({ title, items }) => (
    <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 p-8 transform transition-all hover:scale-105">
      <h4 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
        {title}
      </h4>
      <div className="space-y-3 text-gray-700 dark:text-white/80">
        <p>{items.intro}</p>
        <ul className="space-y-2">
          {items.list.map((item, i) => (
            <li key={i} className="flex items-center">
              <span 
                className="w-2 h-2 rounded-full mr-2"
                style={{ backgroundColor: styles.accent.color }}
              ></span>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const PhilosophyCard = ({ title, items }) => (
    <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 p-8">
      <h4 className="text-xl font-bold mb-6 text-gray-900 dark:text-white">
        {title}
      </h4>
      <div className="grid grid-cols-2 gap-6">
        {items.map((item, index) => (
          <div key={index} className="space-y-2">
            <span className="font-bold block" style={{ color: styles.accent.color }}>
              {item.title}
            </span>
            <p className="text-gray-700 dark:text-white/80 text-sm">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        imageSrc="/images/collaboration.png"
        fallbackSrc="/collaboration.png"
        title="Revolutionizing Data Education"
        subtitle="At Data Training Lab, we believe in learning by doing. Our innovative approach combines real-world scenarios with expert guidance to create an immersive learning experience."
        styles={styles}
      />

      <div style={{ 
        backgroundImage: `linear-gradient(135deg, ${styles.secondary.color} 0%, ${styles.primary.color} 100%)`
      }} className="py-24">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-white text-center mb-16">
            Our Unique Approach
          </h2>
          <CardGrid items={approachFeatures} styles={styles} />
        </div>
      </div>

      <div className="py-24 bg-gray-50 dark:bg-dark-primary-light/30 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 text-gray-900 dark:text-white">
            The Summit Stay Experience
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <ExperienceCard 
              title="Immersive Learning Environment"
              items={{
                intro: "Step into a fully realized hotel data ecosystem, complete with:",
                list: [
                  "Live booking systems",
                  "Customer feedback streams",
                  "Operational metrics",
                  "Market competition data"
                ]
              }}
            />
            <ExperienceCard 
              title="Industry-Driven Scenarios"
              items={{
                intro: "Face challenges that mirror real hospitality industry demands:",
                list: [
                  "Peak season optimization",
                  "Dynamic pricing strategies",
                  "Guest experience enhancement",
                  "Operational efficiency"
                ]
              }}
            />
            <ExperienceCard 
              title="Practical Skill Building"
              items={{
                intro: "Develop capabilities that translate directly to your career:",
                list: [
                  "Problem-solving mindset",
                  "Technical proficiency",
                  "Business acumen",
                  "Project management"
                ]
              }}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <PhilosophyCard 
              title="Our Teaching Philosophy"
              items={[
                {
                  title: 'Learn by Doing',
                  description: 'Hands-on experience with real-world tools and datasets, guided by industry experts.'
                },
                {
                  title: 'Progressive Complexity',
                  description: 'Build confidence through gradually increasing challenges and complexity.'
                },
                {
                  title: 'Immediate Application',
                  description: 'Apply new skills to practical scenarios immediately, reinforcing learning.'
                },
                {
                  title: 'Continuous Feedback',
                  description: 'Regular guidance ensures you\'re always moving in the right direction.'
                }
              ]}
            />

            <PhilosophyCard 
              title="Learning Outcomes"
              items={[
                {
                  title: 'Technical Excellence',
                  description: 'Master industry-standard tools and technologies through practical application.'
                },
                {
                  title: 'Business Understanding',
                  description: 'Develop deep insights into how data drives business success.'
                },
                {
                  title: 'Portfolio Development',
                  description: 'Build a comprehensive portfolio of real-world projects.'
                },
                {
                  title: 'Career Readiness',
                  description: 'Graduate with the confidence to make an immediate impact.'
                }
              ]}
            />
          </div>

          <div className="mt-16 text-center">
            <Link
              to="/tracks"
              className="inline-block px-8 py-4 text-white text-lg rounded-lg transition-all 
                       transform hover:scale-105 hover:shadow-xl dark:hover:shadow-dark-primary/30"
              style={{ 
                backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
              }}
            >
              Explore Our Learning Pathways
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default OurPlatform;