// src/EBMatrix.js
import React from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import EnterpriseMatrixApp from './components/EnterpriseMatrixApp';

function EBMatrix() {

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      
      <div className="container-fluid mx-auto px-4 py-8" style={{ maxWidth: '95%' }}>
        <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white transition-colors duration-200">
          Enterprise Bus Matrix as Code
        </h1>
        <p className="text-lg text-gray-700 dark:text-white/80 mb-8 transition-colors duration-200">
          Create a blueprint for your data warehouse that maps out how key business measurements (facts) and supporting information (dimensions) interconnect across your company. This visual guide ensures that all teams work with a consistent set of definitions, making it easier to integrate and analyze data from multiple sources.
        </p>
        
        <EnterpriseMatrixApp />
      </div>
      
      <Footer />
    </div>
  );
}

export default EBMatrix;