import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import { 
  HeroSection,
  GradientBreakSection, 
  ContentSection, 
  CTASection,
  themeStyles 
} from './components/SharedComponents';

const PrivacyPolicy = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#policy-details') {
      const element = document.getElementById('policy-details');
      if (element) {
        // Smooth scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const contentSections = [
    {
      title: "Introduction",
      content: [
        "At Data Training Lab, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our educational platform and services.",
        "We encourage you to read this Privacy Policy carefully to understand our practices regarding your personal information and how we treat it."
      ]
    },
    {
      title: "Information We Collect",
      content: [
        "We collect and process the following types of information:",
        "• Full name (during account registration)",
        "• Email address (used as your primary identifier)",
        "• Password (encrypted using bcrypt)",
        "• Course progress and quiz results",
        "• Basic usage data (page views and session information)"
      ]
    },
    {
      title: "How We Use Your Information",
      content: [
        "We use the collected information for the following purposes:",
        "• To provide and maintain our educational services",
        "• To track your progress through courses and modules",
        "• To generate course completion certificates",
        "• To authenticate your access to the platform",
        "• To communicate important updates about your courses or our services",
        "• To improve our platform and user experience"
      ]
    },
    {
      title: "Data Retention",
      content: [
        "We retain your account details, quiz results, and track progress indefinitely to maintain your learning history and course certifications. This allows you to access your completed courses and certifications at any time.",
        "If you request account deletion, your personal information will be removed from our active databases. However, some information may remain in our backup systems for a period of time."
      ]
    },
    {
      title: "Third-Party Services",
      content: [
        "We use a limited number of third-party services to provide our platform:",
        "• Stripe: Handles payment processing. When you make a payment, you are subject to Stripe's privacy policy.",
        "• Vimeo: Hosts our video content. Video viewing data is subject to Vimeo's privacy policy.",
        "Beyond these essential service providers, we do not share your personal information with any third parties."
      ]
    },
    {
      title: "Data Security",
      content: [
        "We implement appropriate security measures to protect your personal information:",
        "• Passwords are encrypted using bcrypt",
        "• We use UUIDs rather than sequential IDs in our database",
        "• Secure HTTPS connections for all data transfers",
        "• Regular security updates and monitoring"
      ]
    },
    {
      title: "International Data Transfers",
      content: [
        "While Data Training Lab operates from Australia, our servers are hosted in Europe. By using our services, you consent to your information being transferred to and processed in Europe, which may have different data protection laws than your location of residence."
      ]
    },
    {
      title: "Your Rights",
      content: [
        "You have the right to:",
        "• Access your personal information",
        "• Request corrections to your personal information",
        "• Request deletion of your account and associated data",
        "• Receive a copy of your personal data",
        "To exercise any of these rights, please contact our Support team using the Contact Us link below."
      ]
    },
    {
      title: "Updates to Privacy Policy",
      content: [
        "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last Updated' date.",
        "Your continued use of our platform after such modifications constitutes your acknowledgment of the modified Privacy Policy and your agreement to abide by it."
      ]
    },
    {
      title: "Contact Information",
      content: [
        "If you have any questions about this Privacy Policy or our data practices, please contact us using the Contact Us link below"
      ]
    }
  ];

  const breakSections = [
    "Your data privacy and security are fundamental to our mission",
    "Understanding how we use and protect your information",
    "We work with trusted partners to deliver our services",
    "Keeping you informed about changes to our privacy practices"
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        title="Privacy Policy"
        subtitle="How we protect and manage your personal information"
        ctaText="Learn More"
        ctaLink="#policy-details"
        styles={styles}
        imageSrc="/images/legal.png"
        fallbackSrc="/legal.png"
      />
      <div className='text-gray-700 dark:text-white/90 max-w-4xl mx-10'>Last Updated: 16 Mar 2024</div>

      {contentSections.slice(0, 1).map((section, index) => (
        <React.Fragment key={index}>
          <GradientBreakSection 
            text={breakSections[0]}
            styles={styles}
          />
          <div id="policy-details">
            <ContentSection {...section} />
          </div>
        </React.Fragment>
      ))}

      {contentSections.slice(1, 3).map((section, index) => (
        <React.Fragment key={index}>
          <GradientBreakSection 
            text={breakSections[1]}
            styles={styles}
          />
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      {contentSections.slice(3, 5).map((section, index) => (
        <React.Fragment key={index}>
          <GradientBreakSection 
            text={breakSections[2]}
            styles={styles}
          />
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      {contentSections.slice(5).map((section, index) => (
        <React.Fragment key={index}>
          <GradientBreakSection 
            text={breakSections[3]}
            styles={styles}
          />
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      <CTASection 
        title="Ready to Start Your Journey?"
        subtitle="Join our community of data professionals and start building real-world experience today."
        ctaText="View Learning Tracks"
        styles={styles}
        to="/tracks"
      />

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;