import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentCancelled = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Give the webhook a moment to process, then redirect
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-[#311d3f] flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-xl text-center">
        <div className="mb-4">
          <svg 
            className="mx-auto h-12 w-12 text-red-500" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </div>
        <h1 className="text-2xl font-bold mb-4">Payment Cancelled!</h1>
        <p className="text-gray-600 mb-2">Sorry to hear you're not ready to proceed.</p>
        <p className="text-gray-500 text-sm">Redirecting to your dashboard...</p>
      </div>
    </div>
  );
};

export default PaymentCancelled;