import React, { useState } from 'react';
import { ChevronDown, ChevronUp, FileText } from 'lucide-react';

const VideoTranscript = ({ transcript, className = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!transcript) return null;

  return (
    <div className={`mt-4 ${className}`}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center gap-2 w-full p-4 text-left text-gray-700 dark:text-gray-300 
                   bg-gray-50 dark:bg-dark-primary-light/50 hover:bg-gray-100 
                   dark:hover:bg-dark-primary rounded-lg transition-all duration-200"
      >
        <FileText size={20} className="flex-shrink-0" />
        <span className="font-medium">Video Transcript</span>
        {isExpanded ? (
          <ChevronUp size={20} className="ml-auto" />
        ) : (
          <ChevronDown size={20} className="ml-auto" />
        )}
      </button>
      
      {isExpanded && (
        <div className="mt-2 p-4 bg-white dark:bg-dark-secondary-light rounded-lg 
                      text-gray-700 dark:text-white/90 whitespace-pre-line
                      border border-gray-200 dark:border-gray-700/50 transition-all duration-200">
          {transcript}
        </div>
      )}
    </div>
  );
};

export default VideoTranscript;