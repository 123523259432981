// src/EBMatrix.js
import React from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import SQLVizApp from './components/SQLVizApp';

function SQLViz() {

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      
      <div className="container-fluid mx-auto px-4 py-8" style={{ maxWidth: '95%' }}>
        <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white transition-colors duration-200">
          Visualize SQL Queries
        </h1>
        <p className="text-lg text-gray-700 dark:text-white/80 mb-8 transition-colors duration-200">
          Show the lineage of your SQL queries in a visual way. This tool - powered by <a 
            href="https://github.com/tobymao/sqlglot" 
            target="_blank"
            rel="noopener noreferrer"
            className="relative text-blue-600 hover:text-blue-700 transition-colors after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-green-500 after:transition-all after:duration-300"
          >
            SQLGlot
          </a>{' '} - will help you understand the flow of your queries and the dependencies of ctes and joins within them.
        </p>
        
        <SQLVizApp />
      </div>
      
      <Footer />
    </div>
  );
}

export default SQLViz;