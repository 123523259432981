import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Lock } from 'lucide-react';
import jwt_decode from 'jwt-decode';
import { 
  ContentSection, 
  themeStyles 
} from './SharedComponents';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { API_URL } from '../config/api';
import Footer from './Footer';
import Navigation from './Navigation';
import { FormInput, FormButton, FormError, FormSuccess } from './FormElements';


const SignupCompletion = () => {
  const [searchParams] = useSearchParams();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    token: '',
    email: '',
    name: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      try {
        const decoded = jwt_decode(token);
        setFormData(prev => ({
          ...prev,
          token,
          email: decoded.email,
          name: decoded.name
        }));
      } catch (error) {
        setError('Invalid verification link');
      }
    } else {
      setError('No verification token found');
    }
    setLoading(false);
  }, [searchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;

    if (!isLongEnough) {
      return 'Password must be at least 8 characters long';
    }
    if (!hasUpperCase) {
      return 'Password must include at least one uppercase letter';
    }
    if (!hasLowerCase) {
      return 'Password must include at least one lowercase letter';
    }
    if (!hasNumbers) {
      return 'Password must include at least one number';
    }
    if (!hasSpecialChar) {
      return 'Password must include at least one special character';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Password validation
    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/auth/signup/complete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: formData.token,
          password: formData.password
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const renderSignupContent = () => {
    if (loading) {
      return (
        <div className="text-gray-600 dark:text-white/70 text-center">
          Loading...
        </div>
      );
    }

    if (success) {
      return (
        <FormSuccess message="Registration completed successfully! You can now log in using the link in the top right corner." />
      );
    }

    return renderForm();
  };

  const renderForm = () => (
    <div className="space-y-6">
      <FormError message={error} />
      
      <div className="bg-gray-50 dark:bg-dark-primary-light/30 rounded-lg p-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80">Name</label>
            <div className="mt-1 text-gray-900 dark:text-white">{formData.name}</div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80">Email</label>
            <div className="mt-1 text-gray-900 dark:text-white">{formData.email}</div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <FormInput
          icon={Lock}
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Enter your password"
          required
        />
        <p className="mt-2 text-sm text-gray-500 dark:text-white/70">
          Password must be at least 8 characters and include uppercase, lowercase, numbers, and special characters.
        </p>

        <FormInput
          icon={Lock}
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder="Confirm your password"
          required
        />

        <FormButton
          text="Complete Registration"
          style={{ 
            backgroundColor: styles.accent.color,
            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
          }}
        />
      </form>
    </div>
  );

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      <ContentSection 
        title="Complete Your Registration" 
        content={[
          "Set your password to finalize your account creation and access our comprehensive data education programs."
        ]}
      >
        {renderSignupContent()}
      </ContentSection>
      <Footer />
    </div>
  );
};

export default SignupCompletion;