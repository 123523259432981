import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { jsPDF } from 'jspdf';
import { useAuth } from './AuthContext';

const Certificate = ({ trackName, completionDate }) => {
  const certificateRef = useRef();
  const { user } = useAuth();
  const [isGenerating, setIsGenerating] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  // Load fonts
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    if (document.fonts && document.fonts.ready) {
      document.fonts.ready.then(() => {
        setFontsLoaded(true);
      });
    } else {
      setTimeout(() => setFontsLoaded(true), 2000);
    }

    return () => {
      document.head.removeChild(link);
    };
  }, []);
  
  const styles = {
    primary: '#311d3f',
    secondary: '#4d3160',
    dark: '#160d1c',
    accent: '#59a52c'
  };

  // Handle direct printing
  const handlePrint = useReactToPrint({
    content: () => certificateRef.current,
  });

  // Function to convert image to base64 with fallback
  const getImageAsBase64 = async (primaryUrl, fallbackUrl) => {
    const tryLoadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        img.crossOrigin = 'anonymous';
        
        const handleLoad = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          try {
            const dataUrl = canvas.toDataURL('image/png');
            resolve(dataUrl);
          } catch (error) {
            console.error('Error converting to data URL:', url, error);
            reject(error);
          }
        };

        img.onload = handleLoad;
        
        img.onerror = async (error) => {
          console.error('Error loading image:', url, error);
          if (url.includes('?')) {
            const cleanUrl = url.split('?')[0];
            if (cleanUrl !== url) {
              img.onload = handleLoad;
              img.src = cleanUrl;
              return;
            }
          }
          reject(error);
        };

        const finalUrl = url.startsWith('/images/') 
          ? `${url}?v=${new Date().getTime()}`
          : url;
        
        img.src = finalUrl;
      });
    };

    try {
      return await tryLoadImage(primaryUrl);
    } catch (error) {
      if (fallbackUrl && fallbackUrl !== primaryUrl) {
        return await tryLoadImage(fallbackUrl);
      }
      return null;
    }
  };

  // Handle PDF generation
  const handleDownload = async () => {
    try {
      setIsGenerating(true);

      // Pre-load images with fallbacks
      const [sphereLogo, blackName, signature] = await Promise.all([
        getImageAsBase64('/images/sphere-logo.png', '/sphere-logo.png'),
        getImageAsBase64('/images/black-name.png', '/black-name.png'),
        getImageAsBase64('/images/signature.png', '/signature.png')
      ]);

      // Create PDF with A4 landscape dimensions
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      // A4 dimensions in mm
      const pageWidth = 297;
      const pageHeight = 210;
      const margin = 20;

      // Use built-in PDF fonts
      pdf.setFont('helvetica', 'normal');

      // Draw border
      pdf.setDrawColor(styles.accent);
      pdf.setLineWidth(1);
      pdf.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
      pdf.setLineWidth(0.5);
      pdf.rect(margin + 3, margin + 3, pageWidth - 2 * (margin + 3), pageHeight - 2 * (margin + 3));

      // Add logos
      if (sphereLogo && blackName) {
        try {
          // Define dimensions
          const sphereLogoSize = 25;
          const blackNameWidth = 130;
          const blackNameHeight = 25;
          const spacing = 10;
          
          // Calculate center positions
          const totalWidth = sphereLogoSize + spacing + blackNameWidth;
          const startX = (pageWidth - totalWidth) / 2;
          
          // Add sphere logo
          pdf.addImage(
            sphereLogo,
            'PNG',
            startX,
            35,
            sphereLogoSize,
            sphereLogoSize,
            undefined,
            'FAST'
          );
          
          // Add black name logo
          pdf.addImage(
            blackName,
            'PNG',
            startX + sphereLogoSize + spacing,
            35,
            blackNameWidth,
            blackNameHeight,
            undefined,
            'FAST'
          );
        } catch (error) {
          console.error('Error adding logos to PDF:', error);
        }
      }

      // Certificate title
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(32);
      pdf.setTextColor(styles.primary);
      pdf.text('Certificate of Completion', pageWidth / 2, 80, { align: 'center' });

      // Subtitle
      pdf.setFont('Inter');
      pdf.setFontSize(16);
      pdf.setTextColor('#666666');
      pdf.text('This certificate is proudly awarded to', pageWidth / 2, 90, { align: 'center' });

      // Student name
      pdf.setFont('Inter-Bold');
      pdf.setFontSize(24);
      pdf.setTextColor(styles.secondary);
      pdf.text(user?.name || 'Student Name', pageWidth / 2, 105, { align: 'center' });

      // Course completion text
      pdf.setFont('Inter');
      pdf.setFontSize(16);
      pdf.setTextColor('#666666');
      pdf.text('who has successfully completed the', pageWidth / 2, 115, { align: 'center' });

      // Track name
      pdf.setFont('Inter-Bold');
      pdf.setFontSize(20);
      pdf.setTextColor(styles.accent);
      pdf.text(`${trackName || 'Data Engineering Track'} 24-Week Training Program`, 
        pageWidth / 2, 125, { align: 'center' });

      // Completion date
      pdf.setFont('Inter');
      pdf.setFontSize(14);
      pdf.setTextColor('#666666');
      pdf.text(`Completed on ${completionDate || new Date().toLocaleDateString()}`,
        pageWidth / 2, 135, { align: 'center' });

      // Add signature
      if (signature) {
        try {
          const signatureWidth = 60;
          const signatureHeight = 20;
          const signatureX = (pageWidth - signatureWidth) / 2;
          
          pdf.addImage(
            signature,
            'PNG',
            signatureX,
            150,
            signatureWidth,
            signatureHeight,
            undefined,
            'FAST'
          );
        } catch (error) {
          console.error('Error adding signature to PDF:', error);
        }
      }

      // Signature line
      pdf.setDrawColor('#666666');
      pdf.line(pageWidth / 2 - 30, 170, pageWidth / 2 + 30, 170);

      // Signature details
      pdf.setFont('Inter-Bold');
      pdf.setFontSize(12);
      pdf.setTextColor('#333333');
      pdf.text('Andy Sawyer', pageWidth / 2, 175, { align: 'center' });
      
      pdf.setFont('Inter');
      pdf.setTextColor('#666666');
      pdf.text('Chief Executive Officer', pageWidth / 2, 180, { align: 'center' });

      // Save the PDF
      pdf.save(`${user?.name || 'Student'}_${trackName}_Certificate.pdf`);

    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Image component with simpler loading
  const CertificateImage = ({ src, alt, className, fallbackSrc }) => {
    const [imgSrc, setImgSrc] = useState(src);

    const handleError = () => {
      if (fallbackSrc && imgSrc !== fallbackSrc) {
        setImgSrc(fallbackSrc);
      }
    };

    return (
      <img
        src={imgSrc}
        alt={alt}
        className={className}
        onError={handleError}
        crossOrigin="anonymous"
        loading="eager"
      />
    );
  };

  return (
    <div className="max-w-5xl mx-auto p-8">      {/* Action Buttons */}
      <div className="mb-8 flex justify-center gap-4">
        <button
          onClick={() => window.history.back()}
          style={{ backgroundColor: "#ffffff" }}
          className="px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-100 transition-colors border border-gray-300"
        >
          Back
        </button>
        <button
          onClick={handlePrint}
          disabled={isGenerating}
          style={{ backgroundColor: "#ffffff" }}
          className="px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-100 transition-colors border border-gray-300 disabled:opacity-50"
        >
          Print Certificate
        </button>
        <button
          onClick={handleDownload}
          disabled={isGenerating}
          style={{ backgroundColor: styles.accent }}
          className="px-4 py-3 text-white rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
        >
          {isGenerating ? 'Generating PDF...' : 'Download PDF'}
        </button>
      </div>

      {/* Certificate Preview */}
      <div className="relative" style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
        <div 
          ref={certificateRef}
          className="certificate-content bg-white rounded-lg shadow-xl border-8 border-double"
          style={{ 
            borderColor: styles.accent,
            width: '100%',
            padding: '2.5rem',
            fontFamily: "'Inter', sans-serif",
            position: 'relative'
          }}
        >
          {/* Header with Logo */}
          <div className="flex justify-center mb-8">
            <div className="flex items-center gap-4">
              <CertificateImage
                src="/images/sphere-logo.png"
                alt="Data Training Lab Logo"
                className="w-16 h-16"
                fallbackSrc="/sphere-logo.png"
              />
              <CertificateImage
                src="/images/black-name.png"
                alt="Data Training Lab"
                className="h-16 w-auto"
                fallbackSrc="/black-name.png"
              />
            </div>
          </div>

          {/* Certificate Title */}
          <div className="text-center mb-8">
            <h1 className="text-4xl font-bold mb-3" style={{ color: styles.primary }}>
              Certificate of Completion
            </h1>
            <p className="text-xl text-gray-600">
              This certificate is proudly awarded to
            </p>
          </div>

          {/* Certificate Body */}
          <div className="text-center mb-8">
            <p className="text-3xl font-bold mb-4" style={{ color: styles.secondary }}>
              {user?.name || 'Student Name'}
            </p>
            <p className="text-xl text-gray-600 mb-3">
              who has successfully completed the
            </p>
            <p className="text-2xl font-bold mb-4" style={{ color: styles.accent }}>
              {trackName || 'Data Engineering Track'} 24-Week Training Program
            </p>
            <p className="text-lg text-gray-600">
              Completed on {completionDate || new Date().toLocaleDateString()}
            </p>
          </div>

          {/* Signature Section */}
          <div className="mt-10">
            <div className="flex flex-col items-center">
              <div className="h-12 mb-2">
                <CertificateImage
                  src="/images/signature.png"
                  alt="CEO Signature"
                  className="h-12 w-auto object-contain"
                  fallbackSrc="/signature.png"
                />
              </div>
              <div className="w-48 border-t border-gray-400 pt-2 text-center">
                <p className="font-bold text-gray-800">Andy Sawyer</p>
                <p className="text-gray-600">Chief Executive Officer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;