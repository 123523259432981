import { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config/api';

export const useContentManager = (sectionId) => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingContent, setEditingContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReorderMode, setIsReorderMode] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState('text');
  
  const { getAuthHeaders } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      if (!sectionId) {
        navigate('/my_admin_console/courses');
        return;
      }

      try {
        setLoading(true);
        const headers = await getAuthHeaders();
        const response = await fetch(`${API_URL}/admin/content/section/${sectionId}`, { headers });
        const data = await response.json();
        setContent(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [sectionId, getAuthHeaders, navigate]);

  const handleSubmit = async (formData) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(
        editingContent ? `${API_URL}/admin/content/${editingContent.id}` : `${API_URL}/admin/content`,
        {
          method: editingContent ? 'PUT' : 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) throw new Error('Failed to save content');

      const updatedResponse = await fetch(`${API_URL}/admin/content/section/${sectionId}`, { headers });
      const updatedData = await updatedResponse.json();
      setContent(updatedData);
      
      setIsModalOpen(false);
      setEditingContent(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (contentId) => {
    if (!window.confirm('Are you sure you want to delete this content?')) return;

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_URL}/admin/content/${contentId}`, {
        method: 'DELETE',
        headers,
      });

      if (!response.ok) throw new Error('Failed to delete content');

      const updatedResponse = await fetch(`${API_URL}/admin/content/section/${sectionId}`, { headers });
      const updatedData = await updatedResponse.json();
      setContent(updatedData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleReorder = async (reorderedContent) => {
    try {
      const headers = await getAuthHeaders();
      const reorderPayload = reorderedContent.map((item, index) => ({
        id: item.id,
        sequence: index + 1
      }));

      const response = await fetch(`${API_URL}/admin/content/section/${sectionId}/reorder`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: reorderPayload }),
      });

      if (!response.ok) throw new Error('Failed to reorder content');
      setContent(reorderedContent);
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    content,
    loading,
    error,
    editingContent,
    isModalOpen,
    isReorderMode,
    selectedContentType,
    setEditingContent,
    setIsModalOpen,
    setIsReorderMode,
    setSelectedContentType,
    handleSubmit,
    handleDelete,
    handleReorder
  };
};