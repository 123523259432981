// src/components/custom-edges/SelfLoopEdge.js
import React from 'react';
import { getBezierPath, EdgeText } from 'reactflow';

const SelfLoopEdge = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) => {
  // For self-loops, create a custom path
  if (source === target) {
    // Create a curved path that loops back to the node
    const radius = 40;
    const path = `M ${sourceX} ${sourceY} 
                 C ${sourceX + radius} ${sourceY - radius}, 
                   ${sourceX + radius} ${sourceY + radius}, 
                   ${sourceX} ${sourceY + 20}`;
                   
    return (
      <>
        <path
          id={id}
          className="react-flow__edge-path"
          d={path}
          markerEnd={markerEnd}
          style={{ ...style, strokeWidth: 2 }}
        />
        <EdgeText
          x={sourceX + radius/2}
          y={sourceY - radius/2}
          label="recursive"
          labelStyle={{ fill: '#8b5cf6', fontSize: 10 }}
          labelShowBg
          labelBgStyle={{ fill: 'white', fillOpacity: 0.75 }}
        />
      </>
    );
  }
  
  // Regular edge rendering
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  
  return (
    <path
      id={id}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
      style={style}
    />
  );
};

export default SelfLoopEdge;