import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LayoutDashboard, FileText, History, ChevronDown, Menu, X } from 'lucide-react';
import Navigation from '../Navigation';
import { themeStyles } from '../SharedComponents';
import Footer from '../Footer';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';


const AdminConsole = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const menuItems = [
    {
      title: 'Dashboard',
      icon: <LayoutDashboard size={20} />,
      path: '/my_admin_console'
    },
    {
      title: 'Content Management',
      icon: <FileText size={20} />,
      path: '/my_admin_console/courses'
    },
    {
      title: 'Audit Logs',
      icon: <History size={20} />,
      path: '/my_admin_console/audit'
    }
  ];

  const toggleExpanded = (title) => {
    setExpandedItems(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const MenuItem = ({ item, isNested = false }) => {
    const isActive = location.pathname === item.path;
    const hasSubItems = item.subItems && item.subItems.length > 0;
    const isExpanded = expandedItems[item.title];

    return (
      <>
        <div
          className={`
            flex items-center justify-between px-4 py-2 rounded-lg cursor-pointer
            transition-colors duration-200
            ${isActive 
              ? 'bg-white/10 dark:bg-dark-primary-light/50' 
              : 'hover:bg-white/5 dark:hover:bg-dark-primary-light/30'}
            ${isNested ? 'ml-4' : ''}
          `}
          onClick={() => {
            if (hasSubItems) {
              toggleExpanded(item.title);
            } else {
              navigate(item.path);
              setIsMobileMenuOpen(false);
            }
          }}
        >
          <div className="flex items-center gap-3 text-white/90">
            {item.icon}
            <span>{item.title}</span>
          </div>
          {hasSubItems && (
            <ChevronDown
              size={16}
              className={`transform transition-transform text-white/90
                ${isExpanded ? 'rotate-180' : ''}`}
            />
          )}
        </div>
        {hasSubItems && isExpanded && (
          <div className="mt-1 space-y-1">
            {item.subItems.map((subItem) => (
              <MenuItem key={subItem.path} item={subItem} isNested />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      
      <div className="flex">
        {/* Mobile menu button */}
        <button
          className="lg:hidden fixed bottom-8 left-8 z-50 p-3 rounded-full shadow-lg 
            text-white transition-all transform hover:scale-105 
            hover:shadow-xl dark:hover:shadow-dark-primary/30"
          style={{ 
            backgroundColor: styles.accent.color,
            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
          }}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Sidebar */}
        <div
          className={`
            fixed inset-y-0 left-0 transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
            lg:relative lg:translate-x-0 transition-all duration-300 ease-in-out
            w-64 backdrop-blur-lg z-40 shadow-xl dark:shadow-dark-primary/30
            bg-secondary dark:bg-dark-secondary transition-colors duration-200
          `}
        >
          <div className="h-full overflow-y-auto p-4">
            <div className="space-y-2">
              {menuItems.map((item) => (
                <MenuItem key={item.title} item={item} />
              ))}
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 p-8 bg-gray-50 dark:bg-dark-primary-light/30 
          min-h-screen transition-colors duration-200">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default AdminConsole;