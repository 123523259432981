import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useParams, useNavigate, json } from 'react-router-dom';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import Navigation from './Navigation';
import VimeoPlayer from './VimeoPlayer';
import VideoTranscript from './VideoTranscript';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { 
  LoadingState, 
  ErrorState, 
  themeStyles 
} from './SharedComponents';
import Editor from '@monaco-editor/react';
import Footer from './Footer';
import { API_URL } from '../config/api';

const TrainingModule = () => {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const { getAuthHeaders } = useAuth();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const [currentSection, setCurrentSection] = useState(0);
  const [moduleContent, setModuleContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quizResults, setQuizResults] = useState(null);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizScore, setQuizScore] = useState(null);
  const [error, setError] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizAttempts, setQuizAttempts] = useState([]);
  const [isModuleComplete, setIsModuleComplete] = useState(false);
  
  useEffect(() => {
    const fetchModuleContent = async () => {
      try {
        setLoading(true);
        setError(null);

        const headers = await getAuthHeaders();
        const response = await fetch(`${API_URL}/modules/${moduleId}/content`, {
          method: 'GET',
          headers: {
            ...headers,
            'Accept': 'application/json'
          },
          mode: 'cors',
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch module content: ${response.statusText}`);
        }
        
        const data = await response.json();
        
        // Validate the data structure
        if (!data || !Array.isArray(data.sections)) {
          throw new Error('Invalid module content structure');
        }
        
        setModuleContent(data);
      } catch (error) {
        console.error('Error fetching module content:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (moduleId) {
      fetchModuleContent();
    }
  }, [moduleId, getAuthHeaders]);

  useEffect(() => {
    const fetchQuizAttempts = async () => {
      if (!quizSubmitted || !moduleContent) return;

      const quizContent = moduleContent.sections[currentSection].content.find(
        c => c.type === 'quiz'
      );

      if (!quizContent) return;

    };

    fetchQuizAttempts();
  }, [quizSubmitted, moduleContent, currentSection, moduleId, getAuthHeaders]);

  useEffect(() => {
    setCurrentSection(0);
    setQuizSubmitted(false);
    setQuizResults(null);
    setQuizScore(null);
    setSelectedAnswers({});
    setIsModuleComplete(false);
  }, [moduleId]);

  const getLanguageDisplayName = (language) => {
    const displayNames = {
      javascript: 'JavaScript',
      python: 'Python',
      sql: 'SQL',
      bash: 'Bash',
      typescript: 'TypeScript',
      java: 'Java',
      csharp: 'C#',
      php: 'PHP',
      html: 'HTML',
      css: 'CSS',
      md: 'Markdown',
      json: 'JSON',
      yaml: 'YAML',
      txt: 'Text'
    };
    return displayNames[language] || language;
  };

  const updateModuleStatus = async (status) => {
    try {
      // First, check the current module status
      const headers = await getAuthHeaders();
      const moduleStatusResponse = await fetch(`${API_URL}/tracks/modules/${moduleId}/status`, {
        method: 'GET',
        headers: {
          ...headers,
          'Accept': 'application/json',
        }
      });
  
      if (!moduleStatusResponse.ok) {
        throw new Error('Failed to fetch module status');
      }
  
      const moduleStatusData = await moduleStatusResponse.json();
      
      // Only update status if the current status is not 'Complete'
      if (moduleStatusData.status !== 'Complete') {
        const response = await fetch(`${API_URL}/tracks/modules/${moduleId}/status`, {
          method: 'PATCH',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          body: JSON.stringify({ status })
        });
  
        if (!response.ok) {
          throw new Error('Failed to update module status');
        }
      }
    } catch (error) {
      console.error('Error updating module status:', error);
    }
  };

  const handleQuizAnswer = (questionIndex, selectedAnswer) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [questionIndex]: selectedAnswer
    }));
  };

  const handleQuizSubmission = async () => {
    const quizContent = moduleContent.sections[currentSection].content.find(
      c => c.type === 'quiz'
    );

    if (!quizContent || !quizContent.questions) return;

    try {
      // Create answers array with submitted and correct answers for each question
      const answers = quizContent.questions.map((question, index) => ({
        question: question.text,
        submittedAnswer: selectedAnswers[index] ?? 0,
        correctAnswer: question.correctAnswer,
        isCorrect: (selectedAnswers[index] ?? 0) === question.correctAnswer
      }));
      
      // Calculate score as percentage
      const correctCount = answers.filter(answer => answer.isCorrect).length;
      const scorePercentage = Math.round((correctCount / quizContent.questions.length) * 100);

      const headers = await getAuthHeaders();
      const response = await fetch(`${API_URL}/modules/${moduleId}/quiz-attempt`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify({
          answers,
          score: scorePercentage
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit quiz attempt');
      }

      const data = await response.json();
      
      // Calculate individual question results for UI feedback
      const results = {};
      answers.forEach((answer, index) => {
        results[index] = answer.isCorrect;
      });

      setQuizResults(results);
      setQuizScore(scorePercentage);
      setQuizSubmitted(true);

      if (scorePercentage === 100) {
        setIsModuleComplete(true);
        await updateModuleStatus('Complete');
      }
    } catch (error) {
      console.error('Error submitting quiz:', error);
      setError('Failed to submit quiz. Please try again.');
    }
  };

  const handleRetry = () => {
    setQuizSubmitted(false);
    setQuizResults(null);
    setQuizScore(null);
    setSelectedAnswers({});
  };

  const handleNext = async () => {
    if (moduleContent && currentSection < moduleContent.sections.length - 1) {
      // If this is the first section (start of module), update status to "In Progress"
      if (currentSection === 0) {
        await updateModuleStatus('In Progress');
      }
      setCurrentSection(curr => curr + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    if (currentSection > 0) {
      setCurrentSection(curr => curr - 1);
      window.scrollTo(0, 0);
    }
  };

  const calculateProgress = () => {
    if (!moduleContent) return 0;
    
    // If module is complete, return 100%
    if (isModuleComplete) return 100;
    
    // Otherwise, calculate based on current section
    // For a module with 4 sections, this will return 0%, 25%, 50%, 75%
    return Math.max(0, (currentSection / moduleContent.sections.length) * 100);
  };

  const renderQuizResults = () => {
    if (!quizSubmitted || !moduleContent) return null;
  
    const quizContent = moduleContent.sections[currentSection].content.find(
      c => c.type === 'quiz'
    );
  
    const totalQuestions = quizContent.questions.length;
    const correctAnswers = Object.values(quizResults).filter(result => result).length;
  
    return (
      <div className="bg-white dark:bg-dark-primary-light p-6 rounded-lg shadow-lg dark:shadow-dark-primary/30 mb-8 transition-colors duration-200">
        <h2 className="text-2xl font-bold mb-4" style={{ color: styles.primary }}>
          Quiz Results
        </h2>
        <div className="mb-6">
          <p className="text-lg text-gray-800 dark:text-white/90 transition-colors duration-200">
            You got <span className="font-bold">{correctAnswers}</span> out of{' '}
            <span className="font-bold">{totalQuestions}</span> questions correct.
          </p>
          <p className="text-lg text-gray-800 dark:text-white/90 transition-colors duration-200">
            Score: <span className="font-bold">{Math.round(quizScore)}%</span>
          </p>
        </div>
        
        {quizScore === 100 ? (
          <div className="p-4 bg-green-50 dark:bg-green-900/30 border border-green-200 dark:border-green-700 rounded-lg transition-colors duration-200">
            <p className="text-green-700 dark:text-green-300">
              Congratulations! You've passed the assessment with a perfect score!
            </p>
          </div>
        ) : (
          <div className="p-4 bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-700 rounded-lg transition-colors duration-200">
            <p className="text-yellow-700 dark:text-yellow-300">
              Keep trying! You need 100% to complete this module.
            </p>
          </div>
        )}
  
        {quizAttempts?.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white transition-colors duration-200">
              Previous Attempts
            </h3>
            <div className="space-y-2">
              {quizAttempts.map((attempt, index) => (
                <div 
                  key={index}
                  className={`p-3 rounded-lg border transition-colors duration-200 ${
                    attempt.score === attempt.total * 100 
                      ? 'border-green-200 dark:border-green-700 bg-green-50 dark:bg-green-900/30' 
                      : 'border-gray-200 dark:border-gray-700 dark:bg-dark-primary'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600 dark:text-gray-300 transition-colors duration-200">
                      Attempt {quizAttempts.length - index}
                    </span>
                    <span className={`font-medium transition-colors duration-200 ${
                      attempt.score === attempt.total * 100 
                        ? 'text-green-600 dark:text-green-400' 
                        : 'text-gray-600 dark:text-gray-300'
                    }`}>
                      Score: {Math.round((attempt.score / attempt.total) * 100)}%
                    </span>
                  </div>
                  <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 transition-colors duration-200">
                    {new Date(attempt.created_at).toLocaleString()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderContent = (content) => {
    if (!content || !content.type) {
      console.warn('Invalid content object:', content);
      return null;
    }

    switch (content.type) {
      // Add this to your TrainingModule.js file, in the renderContent function for 'text' type content

      case 'text':
        return (
          <div className="max-w-none">
            {content.html ? (
              <>
                <style>
                  {`
                    /* Main heading styles */
                    .prose h2 {
                      background-image: linear-gradient(to right, ${styles.primary.color}, ${styles.secondary.color});
                      color: white !important;
                      padding: 0.75rem 1rem;
                      border-radius: 0.5rem;
                      margin-bottom: 1.5rem;
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    }

                    /* Subheading styles */
                    .prose h3 {
                      border-left: 4px solid ${styles.accent.color};
                      padding-left: 1rem;
                      margin-top: 2rem;
                      color: ${effectiveTheme === 'dark' ? 'white' : styles.secondary.color} !important;
                    }

                    /* Installation instructions and steps */
                    .prose ol {
                      background-color: ${effectiveTheme === 'dark' ? styles.dark.color + '50' : '#f8f9fa'};
                      padding: 1rem 1rem 1rem 3rem;
                      border-radius: 0.5rem;
                      border-left: 4px solid ${styles.secondary.color};
                      margin: 1.5rem 0;
                    }

                    /* Make code stand out more */
                    .prose code {
                      background-color: ${effectiveTheme === 'dark' ? styles.dark.color : '#f0f0f0'} !important;
                      color: ${effectiveTheme === 'dark' ? '#e0e0e0' : styles.primary.color} !important;
                      padding: 0.2rem 0.4rem !important;
                      border-radius: 0.25rem !important;
                      font-weight: 500 !important;
                    }

                    /* Style links */
                    .prose a {
                      color: ${styles.accent.color} !important;
                      text-decoration: none !important;
                      border-bottom: 2px dotted ${styles.accent.color}40;
                      transition: border-bottom-color 0.2s ease-in-out;
                    }

                    .prose a:hover {
                      border-bottom-color: ${styles.accent.color};
                    }

                    /* Add block styles for p tags that follow h3 */
                    .prose h3 {
                      background-color: ${effectiveTheme === 'dark' ? styles.dark.color + '30' : '#f8f9fa'};
                      padding: 1rem;
                      border-radius: 0.5rem;
                      border-left: 4px solid ${styles.accent.color};
                    }

                    /* Make lists more visually distinct */
                    .prose ul {
                      background-color: ${effectiveTheme === 'dark' ? styles.dark.color + '50' : '#f8f9fa'};
                      padding: 1rem 1rem 1rem 3rem;
                      border-radius: 0.5rem;
                      border-left: 4px solid ${styles.secondary.color};
                      margin: 1.5rem 0;
                    }

                    /* First p tag after h2 - introduction text */
                    .prose h2 + p {
                      font-size: 1.1rem;
                      border-left: 4px solid ${styles.primary.color}80;
                      padding-left: 1rem;
                      margin-bottom: 2rem;
                    }
                    
                    /* Create a note-like box for paragraphs with "Note:" text */
                    .prose p:has(strong:contains("Note:")), 
                    .prose p:has(strong:contains("Tip:")),
                    .prose p:has(strong:contains("Important:")) {
                      background-color: ${effectiveTheme === 'dark' ? styles.primary.color + '40' : '#e6f2ff'};
                      border-left: 4px solid ${styles.primary.color};
                      padding: 1rem;
                      border-radius: 0.5rem;
                      margin: 1.5rem 0;
                    }
                    
                    /* Create a warning-like box */
                    .prose p:has(strong:contains("Warning:")),
                    .prose p:has(strong:contains("Caution:")) {
                      background-color: ${effectiveTheme === 'dark' ? '#e65c0040' : '#fff3e6'};
                      border-left: 4px solid #e65c00;
                      padding: 1rem;
                      border-radius: 0.5rem;
                      margin: 1.5rem 0;
                    }
                  `}
                </style>
                <div 
                  className="prose max-w-none 
                          prose-headings:font-bold prose-headings:text-gray-800 dark:prose-headings:text-white 
                          prose-p:text-gray-800 dark:prose-p:text-white/90
                          prose-strong:text-gray-800 dark:prose-strong:text-white
                          prose-a:text-blue-600 dark:prose-a:text-blue-400
                          prose-ul:text-gray-800 dark:prose-ul:text-white/90
                          prose-ol:text-gray-800 dark:prose-ol:text-white/90
                          prose-li:text-gray-800 dark:prose-li:text-white/90
                          prose-pre:bg-gray-100 dark:prose-pre:bg-dark-primary
                          prose-pre:text-gray-800 dark:prose-pre:text-white/90
                          prose-pre:border prose-pre:border-gray-200 dark:prose-pre:border-white/10
                          prose-pre:rounded-lg prose-pre:p-4
                          prose-code:text-gray-800 dark:prose-code:text-white/90
                          prose-code:bg-gray-100 dark:prose-code:bg-dark-primary
                          prose-code:px-1.5 prose-code:py-0.5 prose-code:rounded
                          prose-code:before:content-[''] prose-code:after:content-['']"
                  dangerouslySetInnerHTML={{ __html: content.html }} 
                />
              </>
            ) : (
              <p className="text-red-600">Missing text content</p>
            )}
          </div>
        );
        
        case 'video':
        return (
          <div className="mb-8 space-y-4">
            {content.videoId ? (
              <>
                <VimeoPlayer
                  videoId={content.videoId}
                  hash={content.hash}
                  title={content.title || 'Video content'}
                  previewImageUrl={content.previewImage}
                />
                {content.caption && (
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    {content.caption}
                  </p>
                )}
                {content.transcript && (
                  <VideoTranscript transcript={content.transcript} />
                )}
              </>
            ) : (
              <div className="bg-gray-100 p-4 rounded">
                <p className="text-red-600">Missing video ID</p>
              </div>
            )}
          </div>
        );

        case 'code':
          return (
            <div className="mb-8">
              {content.language && content.codeContent ? (
                <div className="space-y-4">
                  <div className="border rounded-lg overflow-hidden">
                    <div className="bg-gray-100 dark:bg-dark-primary px-4 py-2 border-b text-gray-700 dark:text-white/90 font-medium flex justify-between items-center">
                      <div>{getLanguageDisplayName(content.language)}</div>
                    </div>
                    <Editor
                      height={`${Math.max(200, (content.codeContent.split('\n').length * 22))}px`}
                      defaultLanguage={content.language}
                      value={content.codeContent}
                      theme={effectiveTheme === 'dark' ? "vs-dark" : "vs-light"}
                      options={{
                        readOnly: true,
                        minimap: { enabled: false },
                        fontSize: 14,
                        scrollBeyondLastLine: false,
                        automaticLayout: true,
                      }}
                      beforeMount={(monaco) => {
                        monaco.editor.defineTheme('custom-dark-theme', {
                          base: 'vs-dark',
                          inherit: true,
                          rules: [],
                          colors: {
                            'editor.background': styles.secondary.color,
                          }
                        });
                      }}
                    />
                  </div>
                  {content.caption && (
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                      {content.caption}
                    </p>
                  )}
                </div>
              ) : (
                <div className="bg-gray-100 p-4 rounded">
                  <p className="text-red-600">Missing code content or language</p>
                </div>
              )}
            </div>
          );
        
      case 'image':
        return (
          <div className="mb-8">
            {content.url ? (
              <>
                <img
                  src={content.url}
                  alt={content.alt || 'Module content'}
                  className="w-full rounded-lg shadow-lg"
                />
                {content.caption && (
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{content.caption}</p>
                )}
              </>
            ) : (
              <p className="text-red-600">Missing image URL</p>
            )}
          </div>
        );

      case 'link':
        return (
          <div className="mb-8">
            {content.url ? (
              <a
                href={content.url}
                target="_blank"
                rel="noopener noreferrer"
                className="relative inline-block text-blue-600 hover:text-blue-700 transition-colors after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-green-500 after:transition-all after:duration-300"
              >
                {content.text || 'Click here'}
              </a>
            ) : (
              <p className="text-red-600">Missing link URL</p>
            )}
          </div>
        );

        case 'protected-pdf':
          return (
            <div className="mb-8">
              {content.filename ? (
                <button
                  onClick={async () => {
                    try {
                      const headers = await getAuthHeaders();
                      const response = await fetch(`${API_URL}/documents/watermark`, {
                        method: 'POST',
                        headers: {
                          ...headers,
                          'Content-Type': 'application/json',
                          'Accept': 'application/pdf'
                        },
                        mode: 'cors',
                        credentials: 'include',
                        body: JSON.stringify({ filename: content.filename })
                      });
        
                      if (!response.ok) {
                        throw new Error('Failed to download document');
                      }
        
                      const blob = await response.blob();
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = content.filename;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                      document.body.removeChild(a);
        
                    } catch (error) {
                      console.error('Error downloading document:', error);
                    }
                  }}
                  className="relative inline-block text-blue-600 hover:text-blue-700 transition-colors after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-green-500 after:transition-all after:duration-300"
                >
                  {content.text || 'Download PDF'}
                </button>
              ) : (
                <p className="text-red-600">Missing PDF filename</p>
              )}
            </div>
          );
        
      case 'quiz':
        if (!content.questions || !Array.isArray(content.questions)) {
          return <p className="text-red-600">Invalid quiz structure</p>;
        }
        
        if (quizSubmitted) {
          return renderQuizResults();
        }

        return (
          <div className="space-y-6">
          {content.questions.map((question, idx) => (
            <div key={idx} className="bg-white dark:bg-dark-primary-light p-6 rounded-lg shadow dark:shadow-dark-primary/30 transition-colors duration-200">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white transition-colors duration-200">{question.text}</h3>
              <div className="space-y-3">
                {question.options.map((option, optIdx) => (
                  <label 
                    key={optIdx}
                    className={`flex items-center space-x-3 p-3 rounded-lg border cursor-pointer 
                      ${selectedAnswers[idx] === optIdx 
                        ? 'border-green-500 bg-green-50 dark:bg-green-900/30 dark:border-green-400' 
                        : 'border-gray-200 dark:border-dark-primary hover:bg-gray-50 dark:hover:bg-dark-primary'
                      }
                      transition-colors duration-200`}
                  >
                    <input
                      type="radio"
                      name={`question-${idx}`}
                      value={optIdx}
                      checked={selectedAnswers[idx] === optIdx}
                      onChange={() => handleQuizAnswer(idx, optIdx)}
                      className="w-4 h-4 text-green-600 dark:text-green-400"
                    />
                    <span className="text-gray-700 dark:text-white/90 transition-colors duration-200">{option}</span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
        );
        
      default:
        console.warn(`Unknown content type: ${content.type}`);
        return null;
    }
  };

  useEffect(() => {
    if (!window.monaco) return;
    
    window.monaco.editor.defineTheme('custom-dark-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': styles.secondary.color,
      }
    });
  }, [effectiveTheme, styles.secondary.color]);

  const renderNavigationButtons = () => {
    const isLastSection = currentSection === moduleContent.sections.length - 1;
    const isQuizSection = moduleContent.sections[currentSection].content.some(c => c.type === 'quiz');
    const allQuestionsAnswered = isQuizSection ? 
      moduleContent.sections[currentSection].content.find(c => c.type === 'quiz')
        .questions.length === Object.keys(selectedAnswers).length : 
      true;
  
    return (
      <div className="flex justify-between items-center mt-12">
        <button
          onClick={handlePrevious}
          disabled={currentSection === 0}
          className="flex items-center gap-2 px-4 py-2 text-gray-600 dark:text-gray-300 disabled:opacity-50 hover:text-gray-800 dark:hover:text-white transition-colors"
        >
          <ChevronLeft size={20} />
          Previous Section
        </button>
        
        {isLastSection && isQuizSection ? (
          quizSubmitted ? (
            quizScore === 100 ? (
              moduleContent.nextModule ? (
                <button
                  onClick={() => navigate(`/module/${moduleContent.nextModule.moduleId}`)}
                  className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                  style={{ 
                    backgroundColor: styles.accent.color,
                    backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                  }}
                >
                  Next Module
                  <ChevronRight size={20} />
                </button>
              ) : (
                <button
                  onClick={() => navigate(`/tracks/${moduleContent.trackId}`)}
                  className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                  style={{ 
                    backgroundColor: styles.accent.color,
                    backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                  }}
                >
                  Back to Track
                  <ChevronRight size={20} />
                </button>
              )) : (
              <button
                onClick={handleRetry}
                className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                style={{ 
                  backgroundColor: styles.secondary.color,
                  backgroundImage: `linear-gradient(to right, ${styles.secondary.color}, ${styles.primary.color})` 
                }}
              >
                Retry Assessment
                <ChevronRight size={20} />
              </button>
            )
          ) : (
            <button
              onClick={handleQuizSubmission}
              disabled={!allQuestionsAnswered}
              className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30 disabled:opacity-50"
              style={{ 
                backgroundColor: styles.accent.color,
                backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
              }}
            >
              Submit Assessment
              <ChevronRight size={20} />
            </button>
          )
        ) : (
          <button
            onClick={handleNext}
            disabled={isLastSection}
            className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30 disabled:opacity-50"
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          >
            {currentSection === moduleContent.sections.length - 2 ? 'Complete Assessment' : currentSection === 0 ? 'Start Module' : 'Next Section'}
            <ChevronRight size={20} />
          </button>
        )}
      </div>
    );
  };

  if (loading) {
    return <LoadingState styles={styles} />;
  }

  if (error || !moduleContent || !moduleContent.sections) {
    return <ErrorState error={error || 'Error: Module content not found'} styles={styles} />;
  }

  const currentSectionContent = moduleContent?.sections[currentSection];

  if (!currentSectionContent) {
    return <ErrorState error="Error: Section content not found" styles={styles} />;
  }

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="bg-gray-50 dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 p-8">
          {/* Progress indicator */}
          <div className="mb-8">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-sm font-medium text-gray-600 dark:text-gray-300">
                Section {currentSection + 1} of {moduleContent.sections.length}
              </h2>
              <span className="text-sm text-gray-600 dark:text-gray-300">
                {Math.round(calculateProgress())}% Complete
              </span>
            </div>
            <div className="h-2 bg-gray-200 dark:bg-dark-primary rounded-full">
              <div 
                className="h-full rounded-full transition-all duration-500"
                style={{ 
                  width: `${calculateProgress()}%`,
                  backgroundColor: styles.accent.color,
                  backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})`
                }}
              />
            </div>
          </div>

          {/* Section title */}
          <h1 
            className="text-3xl font-bold mb-8 text-gray-900 dark:text-white"
            style={{ 
              color: effectiveTheme === 'dark' ? 'white' : styles.primary.color 
            }}
          >
            {currentSectionContent.title}
          </h1>

          {/* Section content */}
          <div className="prose dark:prose-invert max-w-none">
            {Array.isArray(currentSectionContent.content) ? (
              currentSectionContent.content.map((content, idx) => (
                <div key={idx} className="dark:text-white/80">
                  {renderContent(content)}
                </div>
              ))
            ) : (
              <div className="text-red-600">Error: Invalid section content structure</div>
            )}
          </div>

          {/* Navigation buttons */}
          {renderNavigationButtons()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TrainingModule;