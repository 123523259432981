import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, LogOut, Settings, ChevronDown } from 'lucide-react';
import CDNImage from './CDNImage';
import EnrollmentModal from './EnrollmentModal';
import { useAuth } from './AuthContext';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAppsDropdown, setShowAppsDropdown] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const navigate = useNavigate();
  const { user, login, logout } = useAuth();

  const styles = {
    nav: {
      backgroundColor: '#160d1c'
    },
    link: {
      color: '#ffffff'
    },
    activeLink: {
      color: '#59a52c'
    },
    accent: '#59a52c'
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginError(false);
    setIsLoading(true);
  
    try {
      const email = e.target.email.value;
      const password = e.target.password.value;
      
      await login(email, password);
      setShowLoginModal(false);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setLoginError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const navLinkClasses = "relative py-2 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-[#59a52c] after:transition-all after:duration-300";

  return (
    <nav style={styles.nav} className="p-4 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center">
          {/* Logo section */}
          <div className="flex items-center gap-4">
            <Link to="/">
              <CDNImage
                src="/images/sphere-logo.png"
                alt="Data Training Lab Logo"
                className="h-10 w-10"
                loading="eager"
                fallbackSrc="/sphere-logo.png"
                preload={true}
              />
            </Link>
            <Link to="/">
              <CDNImage
                src="/images/transparent-name.png"
                alt="Data Training Lab"
                className="h-10 md:h-14 w-auto"
                loading="eager"
                fallbackSrc="/transparent-name.png"
                preload={true}
              />
            </Link>
          </div>

          {/* Mobile menu button */}
          <button
            onClick={toggleMenu}
            className="md:hidden text-white hover:text-[#59a52c] transition-colors duration-200"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <Link to={user ? "/dashboard" : "/"} style={styles.link} className={navLinkClasses}>
              {user ? "My Dashboard" : "Home"}
            </Link>
            <div className="relative">
              <button
                onClick={() => setShowAppsDropdown(!showAppsDropdown)}
                className={navLinkClasses}
                style={styles.link}
              >
                Apps <ChevronDown className="w-4 h-4 inline-block ml-1" />
              </button>

              {showAppsDropdown && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-dark-primary-light ring-1 ring-black ring-opacity-5 z-50">
                  <div className="py-1">
                    <Link
                      to="/apps/ebm-as-code"
                      className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-white/90 hover:bg-gray-100 dark:hover:bg-dark-primary"
                      onClick={() => setShowAppsDropdown(false)}
                    >
                      Enterprise Bus Matrix
                    </Link>
                    <Link
                      to="/apps/sql-viz"
                      className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-white/90 hover:bg-gray-100 dark:hover:bg-dark-primary"
                      onClick={() => setShowAppsDropdown(false)}
                    >
                      SQL Viz
                    </Link>
                    {/* Future apps will be added here */}
                  </div>
                </div>
              )}
            </div>
            {user ? (
              <div className="relative">
                <button
                  onClick={() => setShowAccountDropdown(!showAccountDropdown)}
                  className="flex items-center gap-2 focus:outline-none"
                >
                  <div className="w-8 h-8 rounded-full bg-[#59a52c] flex items-center justify-center text-white font-semibold">
                    {getInitials(user.name)}
                  </div>
                  <ChevronDown className="w-4 h-4 text-white" />
                </button>

                {showAccountDropdown && (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                      <Link
                        to="/account"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 gap-2"
                        onClick={() => setShowAccountDropdown(false)}
                      >
                        <Settings className="w-4 h-4" />
                        Manage Account
                      </Link>
                      <button
                        onClick={() => {
                          handleLogout();
                          setShowAccountDropdown(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 gap-2"
                      >
                        <LogOut className="w-4 h-4" />
                        Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <button 
                  onClick={() => setShowLoginModal(true)}
                  className={navLinkClasses}
                  style={styles.link}
                >
                  Log in
                </button>
                <button 
                  onClick={() => setShowSignUpModal(true)}
                  className="px-4 py-2 text-white rounded-lg hover:opacity-90 transition-opacity"
                  style={{ backgroundColor: styles.accent }}
                >
                  Sign Up
                </button>
              </>
            )}
          </div>
        </div>

        {/* Mobile menu dropdown */}
        {isMenuOpen && (
          <div className="md:hidden absolute left-0 right-0 top-full bg-[#160d1c] py-4 px-4 shadow-lg z-50">
            <div className="flex flex-col space-y-4">
              <Link to={user ? "/dashboard" : "/"} style={styles.link} className={navLinkClasses} onClick={() => setIsMenuOpen(false)}>
                {user ? "My Dashboard" : "Home"}
              </Link>
              {/* Apps}
              <Link 
                to="/apps/ebm-as-code" 
                className={`${navLinkClasses} text-left w-full`}
                style={styles.link}
                onClick={() => setIsMenuOpen(false)}
              >
                Enterprise Bus Matrix
              </Link>
              <Link 
                to="/apps/sql-viz" 
                className={`${navLinkClasses} text-left w-full`}
                style={styles.link}
                onClick={() => setIsMenuOpen(false)}
              >
                SQL Viz
              </Link>
              {Apps */}
              {user ? (
                <>
                  <Link 
                    to="/account" 
                    className="flex items-center px-4 py-2 text-white hover:text-[#59a52c] transition-colors gap-2"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Settings size={18} />
                    Manage Account
                  </Link>
                  <button 
                    onClick={() => {
                      handleLogout();
                      setIsMenuOpen(false);
                    }}
                    className="flex items-center px-4 py-2 text-white hover:text-[#59a52c] transition-colors gap-2"
                  >
                    <LogOut size={18} />
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <button 
                    onClick={() => {
                      setIsMenuOpen(false);
                      setShowLoginModal(true);
                    }}
                    className={`${navLinkClasses} text-left w-full`}
                    style={styles.link}
                  >
                    Login
                  </button>
                  <button 
                    onClick={() => {
                      setIsMenuOpen(false);
                      setShowSignUpModal(true);
                    }}
                    className="px-4 py-2 text-white rounded-lg hover:opacity-90 transition-opacity text-left w-full"
                    style={{ backgroundColor: styles.accent }}
                  >
                    Sign Up
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        {/* Sign Up Modal */}
        <EnrollmentModal 
          isOpen={showSignUpModal}
          onClose={() => setShowSignUpModal(false)}
        />

        {/* Login Modal */}
        {showLoginModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">Login to Your Account</h2>
              <form onSubmit={handleLoginSubmit} className="space-y-4">
                {loginError && (
                  <p className="text-red-500 text-sm">Invalid email or password. Please try again.</p>
                )}
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59a52c]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59a52c]"
                    required
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowLoginModal(false);
                      setLoginError(false);
                    }}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    style={{ backgroundColor: styles.accent }}
                    className="px-4 py-2 text-white rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Logging in...' : 'Login'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;