import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Lock } from 'lucide-react';
import Navigation from './Navigation';
import jwt_decode from 'jwt-decode';
import { API_URL } from '../config/api';
import { FormInput, FormButton, FormError, FormSuccess } from './FormElements';

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    token: '',
    email: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const styles = {
    primary: '#311d3f',
    secondary: '#4d3160',
    dark: '#160d1c',
    accent: '#59a52c'
  };

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      try {
        const decoded = jwt_decode(token);
        setFormData(prev => ({
          ...prev,
          token,
          email: decoded.email
        }));
      } catch (error) {
        setError('Invalid reset link');
      }
    } else {
      setError('No reset token found');
    }
    setLoading(false);
  }, [searchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;

    if (!isLongEnough) {
      return 'Password must be at least 8 characters long';
    }
    if (!hasUpperCase) {
      return 'Password must include at least one uppercase letter';
    }
    if (!hasLowerCase) {
      return 'Password must include at least one lowercase letter';
    }
    if (!hasNumbers) {
      return 'Password must include at least one number';
    }
    if (!hasSpecialChar) {
      return 'Password must include at least one special character';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Password validation
    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: formData.token,
          password: formData.password
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      setSuccess(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      setError(error.message);
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="text-gray-600 text-center">
          Loading...
        </div>
      );
    }

    if (error && !formData.token) {
      return (
        <div className="space-y-6">
          <FormError message={error} />
        </div>
      );
    }

    if (success) {
      return (
        <FormSuccess message="Password reset successful! You will be redirected to the login page." />
      );
    }

    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Lock size={20} className="text-gray-400" />
            <h2 className="text-2xl font-bold" style={{ color: styles.primary }}>
              Reset Your Password
            </h2>
          </div>
          <p className="text-gray-600">Please enter your new password below.</p>
        </div>

        <FormError message={error} />

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <FormInput
              icon={Lock}
              label="New Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your new password"
              required
            />
            <p className="mt-2 text-sm text-gray-500">
              Password must be at least 8 characters and include uppercase, lowercase, numbers, and special characters.
            </p>
          </div>

          <FormInput
            icon={Lock}
            label="Confirm New Password"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm your new password"
            required
          />

          <FormButton
            text="Reset Password"
            style={{ backgroundColor: styles.accent }}
          />
        </form>
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: styles.primary }} className="min-h-screen">
      <Navigation />
      <div className="max-w-md mx-auto px-4 py-16">
        <div className="bg-white shadow rounded-lg p-8">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;