import React, { useState } from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import { 
  HeroSection,
  CTASection,
  themeStyles
} from './components/SharedComponents';
import { ChevronDown, ChevronUp } from 'lucide-react';


const ExpandableSection = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 ml-8 mr-8">
      <button
        className="flex justify-between items-center w-full py-4 px-2 text-left font-medium text-lg text-gray-800 dark:text-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <div className="px-2 pb-4 text-gray-700 dark:text-white/80">
          {content}
        </div>
      )}
    </div>
  );
};

const HelpCenter = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const faqData = [
    {
      question: 'How do I enroll in a learning track?',
      answer: 'To enroll in a learning track, navigate to the "Learning Tracks" page and select the track you\'re interested in. Click the "Enroll Now" button and follow the prompts to complete your enrollment.'
    },
    {
      question: 'What are the technical requirements for participating in the program?',
      answer: 'To participate in our program, you\'ll need a computer with a stable internet connection. We recommend using a modern web browser such as Chrome, Firefox, or Safari for the best experience.'
    },
    {
      question: 'Do you have dark mode?',
      answer: 'Yes! By default, we match the theme of our platform to your system preferences. You can also manually switch between light and dark mode from the toggle in the footer next to our Medium and LinkedIn links.'
    },
    {
      question: 'Why can\'t I change my name or email address?',
      answer: 'To change your name or email address, please contact our support team. Your name is used in your certificate of completion, so we require verification before making any changes.'
    },
    {
      question: 'Is the platform mobile-friendly?',
      answer: 'While we recommend using a desktop or laptop for the full learning experience, our platform is responsive and can be accessed on tablets and mobile devices. However, some interactive elements and coding exercises are best experienced on larger screens.'
    },
    {
      question: 'What programming languages will I learn?',
      answer: 'Our tracks cover multiple programming languages and tools. In the Data Engineering track, you\'ll work with Python and SQL. The Data Analytics track focuses on Python, SQL, and visualization tools like Power BI and Tableau. The Data Governance track emphasizes policy and framework skills.'
    },
    {
      question: 'Do I need prior coding experience?',
      answer: 'No prior coding experience is required! Our tracks are designed to take you from beginner to professional, with step-by-step guidance and foundational modules that introduce you to programming concepts.'
    },
    {
      question: 'Will I receive a certificate after completing a track?',
      answer: 'Yes! Upon successfully completing all modules and assessments in a track, you\'ll receive a digital certificate of completion. This certificate highlights your skills and can be shared on professional networking platforms like LinkedIn.'
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We process payments securely through Stripe, which accepts major credit cards (Visa, MasterCard, American Express) and provides a smooth checkout experience.'
    },
    {
      question: 'Is there a refund policy?',
      answer: 'We offer a 14-day money-back guarantee from the date of enrollment. If you\'re not satisfied with the course within the first two weeks, you can request a full refund. After that, we provide pro-rated refunds based on your progress in the course.'
    },
    {
      question: 'Can I take multiple tracks?',
      answer: 'Absolutely! Many of our learners start with one track and then expand to others. We offer discounts for learners who enroll in multiple tracks, allowing you to build comprehensive data skills across engineering, analytics, and governance.'
    },
    {
      question: 'How does the Summit Stay Hotel simulation work?',
      answer: 'Our unique approach uses a fictional hotel ecosystem as a living laboratory. You\'ll work with real-world datasets, APIs, and business scenarios from Summit Stay Hotel, giving you practical experience that mirrors actual industry challenges.'
    }
  ];

  const guideData = [
    {
      title: 'Getting Started with Data Training Lab',
      content: 'This guide will walk you through the process of creating your account, setting up your profile, and navigating the learning platform.'
    },
    {
      title: 'Submitting Projects for Review',
      content: 'Learn how to submit your completed projects for review by our expert mentors. This guide covers the submission process, what to include in your submission, and how to interpret feedback.'
    },
    // Add more guide items here
  ];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-primary
                    transition-colors duration-200">
      <Navigation />
      
      <HeroSection
        title="Help Center"
        subtitle="Find answers to frequently asked questions and guides to help you make the most of your learning experience."
        ctaText="Contact Support"
        ctaLink="/contact"
        styles={styles}
        imageSrc="/images/help.png"
        fallbackSrc="/help.png"
      />

<div className="max-w-7xl mx-auto px-4 py-12">
  
  <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 overflow-hidden">
    <h2 className="text-3xl font-bold mb-8 p-8 pb-0 text-gray-900 dark:text-white">
      Frequently Asked Questions
    </h2>
    {faqData.map(({ question, answer }, index) => (
      <ExpandableSection 
        key={question} 
        title={question} 
        content={answer}
        isLast={index === faqData.length - 1}
      />
    ))}
  </div>
</div>
{/*
      <div className="max-w-7xl mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold mb-8 dark:text-white">Guides and Tutorials</h2>
        {guideData.map(({ title, content }) => (
          <ExpandableSection key={title} title={title} content={content} />
        ))}
      </div>
*/}
      <CTASection
        title="Need more assistance?"
        subtitle="Our support team is here to help. Reach out to us and we'll get back to you as soon as possible."
        ctaText="Contact Support"
        styles={styles}
        to="/contact"
      />

      <Footer />
    </div>
  );
};

export default HelpCenter;