import React from 'react';
import Navigation from './components/Navigation';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import { 
  HeroSection, 
  GradientBreakSection, 
  CTASection,
  themeStyles 
} from './components/SharedComponents';
import { Clock, MapPin, Building, Briefcase, AlertCircle } from 'lucide-react';
import Footer from './components/Footer';


// This would typically come from an API or CMS
const openPositions = [
  //{
    //role: "Senior Data Engineer",
    //location: "Remote",
    //type: "Full-time",
    //description: "We're looking for a Senior Data Engineer to help develop...",
    //requirements: [
    //  "8+ years of experience with data engineering",
    //  "Expert knowledge of Python and SQL",
    //  "Experience with cloud platforms (AWS/GCP)"
    //]
  //}
];

const valuePropositions = [
  {
    title: "Innovative Work Environment",
    description: "Join a team that's revolutionizing data education through immersive, real-world learning experiences.",
    icon: Building
  },
  {
    title: "Growth Opportunities",
    description: "Develop your skills while working with cutting-edge technologies and methodologies in data education.",
    icon: Briefcase
  },
  {
    title: "Flexible Work Culture",
    description: "We offer remote-first work arrangements with flexible hours to support work-life balance.",
    icon: Clock
  },
  {
    title: "Global Impact",
    description: "Help shape the future of data education and empower professionals worldwide to achieve their career goals.",
    icon: MapPin
  }
];

const JobCard = ({ role, location, type, description, requirements }) => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  return (
    <div className="bg-white dark:bg-dark-primary-light rounded-xl shadow-lg dark:shadow-dark-primary/30 p-6 mb-6 transform transition-all hover:scale-[1.02]">
      <div className="flex flex-col md:flex-row justify-between mb-4">
        <div>
          <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">{role}</h3>
          <div className="flex flex-wrap gap-3">
            <span className="inline-flex items-center text-sm text-gray-600 dark:text-gray-300">
              <MapPin size={16} className="mr-1" /> {location}
            </span>
            <span className="inline-flex items-center text-sm text-gray-600 dark:text-gray-300">
              <Clock size={16} className="mr-1" /> {type}
            </span>
          </div>
        </div>
        <button
          className="mt-4 md:mt-0 px-6 py-2 text-white rounded-lg transition-all transform hover:scale-105"
          style={{ 
            backgroundColor: styles.accent.color,
            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
          }}
        >
          Apply Now
        </button>
      </div>
      
      <div className="prose dark:prose-invert max-w-none mt-4">
        <p className="text-gray-700 dark:text-white/80">{description}</p>
        
        {requirements && requirements.length > 0 && (
          <>
            <h4 className="text-lg font-semibold mt-4 mb-2 text-gray-900 dark:text-white">Requirements</h4>
            <ul className="list-disc pl-5 space-y-1">
              {requirements.map((req, index) => (
                <li key={index} className="text-gray-700 dark:text-white/80">{req}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

const ValuePropositionCard = ({ title, description, icon: Icon }) => (
  <div className="bg-white dark:bg-dark-primary-light rounded-xl shadow-lg dark:shadow-dark-primary/30 p-6 transform transition-all hover:scale-105">
    <div className="flex items-start gap-4">
      <div className="bg-gradient-to-r from-primary to-secondary dark:from-dark-primary dark:to-dark-secondary p-3 rounded-lg">
        <Icon className="h-6 w-6 text-white" />
      </div>
      <div>
        <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">{title}</h3>
        <p className="text-gray-600 dark:text-white/80">{description}</p>
      </div>
    </div>
  </div>
);

const NoOpenPositions = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  return (
    <div className="bg-gray-50 dark:bg-dark-primary-light/30 rounded-xl p-8 text-center">
      <div className="flex justify-center mb-4">
        <div className="p-3 rounded-full" style={{ backgroundColor: styles.accent.color }}>
          <AlertCircle className="h-6 w-6 text-white" />
        </div>
      </div>
      <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
        No Open Positions
      </h3>
      <p className="text-gray-600 dark:text-white/80 max-w-lg mx-auto">
        While we don't have any open positions at the moment, we're always interested in connecting with talented individuals. Follow us on LinkedIn for future opportunities.
      </p>
      <a 
        href="https://www.linkedin.com/company/data-training-lab"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block mt-6 px-6 py-3 text-white rounded-lg transition-all transform hover:scale-105"
        style={{ 
          backgroundColor: styles.accent.color,
          backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
        }}
      >
        Follow Us on LinkedIn
      </a>
    </div>
  );
};

const Hiring = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        imageSrc="/images/collaboration.png"
        fallbackSrc="/collaboration.png"
        title="Join Our Team"
        subtitle="Help us transform data education and empower the next generation of data professionals"
        styles={styles}
      />

      <GradientBreakSection 
        text="Building the future of data education together"
        styles={styles}
      />

      <div className="py-16 bg-gray-50 dark:bg-dark-primary-light/30 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Why Join Data Training Lab?
            </h2>
            <p className="text-xl text-gray-600 dark:text-white/80 max-w-3xl mx-auto">
              Join our mission to revolutionize data education through immersive, real-world learning experiences.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-6 mb-16">
            {valuePropositions.map((prop, index) => (
              <ValuePropositionCard key={index} {...prop} />
            ))}
          </div>

          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Open Positions
          </h2>

          {openPositions.length > 0 ? (
            <div className="space-y-6">
              {openPositions.map((position, index) => (
                <JobCard key={index} {...position} />
              ))}
            </div>
          ) : (
            <NoOpenPositions />
          )}
        </div>
      </div>

      <CTASection 
        title="Want to Learn Instead?"
        subtitle="Explore our comprehensive learning tracks and begin your journey in data."
        ctaText="View Learning Tracks"
        styles={styles}
        to="/tracks"
      />

      <Footer />
    </div>
  );
};

export default Hiring;