// src/components/custom-nodes/SubqueryNode.js
import React from 'react';
import { Handle, Position } from 'reactflow';

const SubqueryNode = ({ data }) => {
  return (
    <div style={{
      padding: '10px 15px',
      borderRadius: '5px',
      background: data.isRecursive ? '#fff2e8' : '#fff7ed',
      border: data.isRecursive ? '2px solid #fdba8c' : '1px solid #fed7aa',
      minWidth: '180px',
      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
    }}>
      <Handle type="target" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
      
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
          <span style={{ fontWeight: '500', color: '#9a3412' }}>
            {data.alias ? `${data.alias} (Subquery)` : 'Subquery'}
            {data.isRecursive && 
              <span style={{ 
                marginLeft: '5px', 
                fontSize: '11px', 
                background: '#ea580c', 
                color: 'white', 
                padding: '2px 4px', 
                borderRadius: '4px'
              }}>
                RECURSIVE
              </span>
            }
          </span>
        </div>
        
        {/* Source section - combining both tables and referenced items */}
        <div style={{ fontSize: '12px', color: '#c2410c', marginTop: '4px' }}>
          <details>
            <summary style={{ cursor: 'pointer' }}>
              Sources: {(data.sourceTables?.length || 0) + 
                        (data.referencedCTEs?.length || 0) + 
                        ((data.referencedSubqueries?.filter(sq => sq !== data.id)?.length) || 0)}
              {data.isRecursive && ' + self'}
            </summary>
            <div style={{ paddingLeft: '12px', marginTop: '4px' }}>
              {/* Display table sources */}
              {data.sourceTables && data.sourceTables.length > 0 && (
                <>
                  <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2px' }}>Tables:</div>
                  {data.sourceTables.map((table, i) => (
                    <div key={`table-${i}`} style={{ fontSize: '12px' }}>
                      {table}
                    </div>
                  ))}
                </>
              )}
              
              {/* Display CTE sources */}
              {data.referencedCTEs && data.referencedCTEs.length > 0 && (
                <>
                  <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2px' }}>CTEs:</div>
                  {data.referencedCTEs.map((cte, i) => (
                    <div key={`cte-${i}`} style={{ fontSize: '12px' }}>
                      {cte}
                    </div>
                  ))}
                </>
              )}
              
              {/* Display Subquery sources */}
              {data.referencedSubqueries && data.referencedSubqueries.length > 0 && (
                <>
                  <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2px' }}>Subqueries:</div>
                  {data.referencedSubqueries.map((sq, i) => (
                    <div key={`sq-${i}`} style={{ fontSize: '12px' }}>
                      {sq} {sq === data.id && '(self)'}
                    </div>
                  ))}
                </>
              )}
            </div>
          </details>
        </div>
      </div>
    </div>
  );
};

export default SubqueryNode;