import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Plus, Edit, Trash2, X, Loader2, Grip, ArrowLeft } from 'lucide-react';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';
import { useDragSort } from '../../hooks/useDragSort';
import { themeStyles } from '../SharedComponents';
import { API_URL } from '../../config/api';

const SectionManager = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReorderMode, setIsReorderMode] = useState(false);
  
  const { getAuthHeaders } = useAuth();
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const parentState = location.state;
  
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  useEffect(() => {
    const fetchSections = async () => {
      if (!moduleId) {
        navigate('/my_admin_console/courses');
        return;
      }

      try {
        setLoading(true);
        const headers = await getAuthHeaders();
        const response = await fetch(`${API_URL}/admin/sections/module/${moduleId}`, { headers });
        const data = await response.json();
        setSections(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSections();
  }, [moduleId, getAuthHeaders, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const sectionData = {
      title: formData.get('title'),
      sequence: parseInt(formData.get('sequence')),
      moduleId: moduleId
    };

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(
        editingSection ? `${API_URL}/admin/sections/${editingSection.id}` : `${API_URL}/admin/sections`,
        {
          method: editingSection ? 'PUT' : 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(sectionData),
        }
      );

      if (!response.ok) throw new Error('Failed to save section');

      const updatedResponse = await fetch(`${API_URL}/admin/sections/module/${moduleId}`, { headers });
      const updatedData = await updatedResponse.json();
      setSections(updatedData);
      
      setIsModalOpen(false);
      setEditingSection(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (sectionId) => {
    if (!window.confirm('Are you sure you want to delete this section?')) return;

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_URL}/admin/sections/${sectionId}`, {
        method: 'DELETE',
        headers,
      });

      if (!response.ok) throw new Error('Failed to delete section');

      const updatedResponse = await fetch(`${API_URL}/admin/sections/module/${moduleId}`, { headers });
      const updatedData = await updatedResponse.json();
      setSections(updatedData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleReorder = async (reorderedSections) => {
    try {
      const headers = await getAuthHeaders();
      const reorderPayload = reorderedSections.map((section, index) => ({
        id: section.id,
        sequence: index + 1
      }));

      const response = await fetch(`${API_URL}/admin/sections/module/${moduleId}/reorder`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sections: reorderPayload }),
      });

      if (!response.ok) throw new Error('Failed to reorder sections');
      setSections(reorderedSections);
    } catch (err) {
      setError(err.message);
    }
  };

  const { handleDragStart, handleDragEnter, handleDragEnd, handleDragLeave } = useDragSort(sections, handleReorder);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="w-8 h-8 animate-spin text-white" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 
        rounded-lg p-4 text-red-700 dark:text-red-300 transition-colors duration-200">
        Error loading sections: {error}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <button
        onClick={() => navigate(`/my_admin_console/modules/${parentState?.courseId}`, {
          state: parentState
        })}
        className="flex items-center gap-2 text-gray-700 dark:text-white/90 
          hover:text-gray-900 dark:hover:text-white transition-colors duration-200"
      >
        <ArrowLeft size={20} />
        Back to Modules
      </button>

      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
          Section Management
        </h1>
        <div className="flex gap-4">
          <button
            onClick={() => setIsReorderMode(!isReorderMode)}
            className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all 
              transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
            style={{ 
              backgroundColor: styles.secondary.color,
              backgroundImage: `linear-gradient(to right, ${styles.secondary.color}, ${styles.primary.color})` 
            }}
          >
            {isReorderMode ? 'Exit Reorder' : 'Reorder Sections'}
          </button>
          <button
            onClick={() => {
              setEditingSection(null);
              setIsModalOpen(true);
            }}
            className="flex items-center gap-2 px-6 py-3 text-white rounded-lg transition-all 
              transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          >
            <Plus size={20} />
            Add Section
          </button>
        </div>
      </div>

      {/* Sections Table */}
      <div className="bg-white dark:bg-dark-primary-light rounded-lg shadow-xl 
        dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-white/10">
          <thead className="bg-gray-50 dark:bg-dark-primary transition-colors duration-200">
            <tr>
              {isReorderMode && <th className="w-12"></th>}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Sequence
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Content Items
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-dark-primary-light divide-y divide-gray-200 dark:divide-white/10">
            {sections.map((section, index) => (
              <tr 
                key={section.id}
                draggable={isReorderMode}
                onDragStart={(e) => handleDragStart(e, index)}
                onDragEnter={(e) => handleDragEnter(e, index)}
                onDragEnd={handleDragEnd}
                onDragLeave={handleDragLeave}
                className={`transition-colors duration-200 ${isReorderMode ? 'cursor-move' : ''}`}
              >
                {isReorderMode && (
                  <td className="pl-2 pr-0 w-12">
                    <Grip className="text-gray-400 dark:text-gray-500" size={16} />
                  </td>
                )}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                  {section.sequence}
                </td>
                <td 
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white 
                    cursor-pointer hover:text-gray-700 dark:hover:text-white/90 transition-colors duration-200"
                  onClick={() => navigate(`/my_admin_console/content/${section.id}`, {
                    state: {
                      ...parentState,
                      sectionId: section.id,
                      sectionName: section.title
                    }
                  })}
                >
                  {section.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                  {section.content?.length || 0} items
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setEditingSection(section);
                        setIsModalOpen(true);
                      }}
                      className="text-indigo-600 dark:text-indigo-400 
                        hover:text-indigo-900 dark:hover:text-indigo-300 
                        transition-colors duration-200"
                    >
                      <Edit size={16} />
                    </button>
                    <button
                      onClick={() => handleDelete(section.id)}
                      className="text-red-600 dark:text-red-400 
                        hover:text-red-900 dark:hover:text-red-300 
                        transition-colors duration-200"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Section Form Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm 
          flex items-center justify-center p-4 z-50 transition-colors duration-200">
          <div className="bg-white dark:bg-dark-primary-light rounded-lg p-6 max-w-md w-full 
            shadow-xl dark:shadow-dark-primary/30 transition-colors duration-200">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
                {editingSection ? 'Edit Section' : 'Add Section'}
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingSection(null);
                }}
                className="text-gray-400 dark:text-gray-500 
                  hover:text-gray-500 dark:hover:text-gray-400 
                  transition-colors duration-200"
              >
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-white/80 mb-1 transition-colors duration-200">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  defaultValue={editingSection?.title}
                  className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-white/20 
                    dark:bg-dark-primary px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none 
                    focus:ring-1 focus:ring-indigo-500 text-gray-900 dark:text-white transition-colors duration-200"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-white/80 mb-1 transition-colors duration-200">
                  Sequence
                </label>
                <input
                  type="number"
                  name="sequence"
                  defaultValue={editingSection?.sequence ?? sections.length + 1}
                  className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-white/20 
                    dark:bg-dark-primary px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none 
                    focus:ring-1 focus:ring-indigo-500 text-gray-900 dark:text-white transition-colors duration-200"
                  required
                />
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingSection(null);
                  }}
                  className="px-4 py-2 text-gray-700 dark:text-white/90 bg-gray-100 
                    dark:bg-dark-primary rounded-lg hover:bg-gray-200 
                    dark:hover:bg-dark-primary-light transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-white rounded-lg transition-all 
                    transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                  style={{ 
                    backgroundColor: styles.accent.color,
                    backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                  }}
                >
                  {editingSection ? 'Save Changes' : 'Create Section'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionManager;