import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Navigation from './Navigation';
import Certificate from './Certificate';

const CertificatePage = () => {
  const location = useLocation();
  const { trackName, completionDate } = location.state || {};

  const styles = {
    primary: '#311d3f',
  };

  // Redirect if no certificate data is provided
  if (!trackName || !completionDate) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div style={{ backgroundColor: styles.primary }} className="min-h-screen">
      <Navigation />
      <div className="max-w-7xl mx-auto px-4 py-16">
        <Certificate 
          trackName={trackName}
          completionDate={completionDate}
        />
      </div>
    </div>
  );
};

export default CertificatePage;