import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { ChevronLeft, ChevronRight, Search, X, Eye, Loader2 } from 'lucide-react';
import AuditLogDetailsModal from './AuditLogDetailsModal';
import { themeStyles } from '../SharedComponents';
import { API_URL } from '../../config/api';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';

const AuditLogs = () => {
  const [logs, setLogs] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 20,
    total: 0,
    totalPages: 0
  });
  const [filters, setFilters] = useState({
    action_type: '',
    table_name: '',
    administrator_id: '',
    start_date: '',
    end_date: ''
  });
  const [filterOptions, setFilterOptions] = useState({
    actionTypes: [],
    tableNames: []
  });
  const [selectedLog, setSelectedLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getAuthHeaders } = useAuth();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  // Fetch filter options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const headers = await getAuthHeaders();
        const response = await fetch(`${API_URL}/admin/audit-logs/filters/options`, { headers });
        if (!response.ok) {
          throw new Error('Failed to fetch filter options');
        }
        const data = await response.json();
        setFilterOptions(data);
      } catch (err) {
        console.error('Error fetching filter options:', err);
        setError('Failed to load filter options');
      }
    };

    fetchFilterOptions();
  }, [getAuthHeaders]);

  // Fetch logs
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        setLoading(true);
        const headers = await getAuthHeaders();
        
        const queryParams = new URLSearchParams({
          page: pagination.page,
          limit: pagination.pageSize,
          ...Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== '')
          )
        });

        const response = await fetch(`${API_URL}/admin/audit-logs?${queryParams}`, { headers });
        if (!response.ok) {
          throw new Error('Failed to fetch audit logs');
        }
        
        const data = await response.json();
        setLogs(data.logs);
        setPagination(data.pagination);
      } catch (err) {
        console.error('Error fetching logs:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [pagination.page, pagination.pageSize, filters, getAuthHeaders]);

  const handleViewDetails = async (logId) => {
    try {
      setModalLoading(true);
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_URL}/admin/audit-logs/${logId}`, { headers });
      
      if (!response.ok) {
        throw new Error('Failed to fetch log details');
      }
      
      const detailedLog = await response.json();
      setSelectedLog(detailedLog);
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error fetching log details:', err);
    } finally {
      setModalLoading(false);
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setPagination(prev => ({ ...prev, page: 1 })); // Reset to first page
  };

  const clearFilters = () => {
    setFilters({
      action_type: '',
      table_name: '',
      administrator_id: '',
      start_date: '',
      end_date: ''
    });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLog(null);
  };

  const getActionTypeStyles = (actionType) => {
    const baseClasses = "px-2 py-1 rounded-full text-xs font-medium transition-colors duration-200";
    switch (actionType) {
      case 'CREATE':
        return `${baseClasses} bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300`;
      case 'UPDATE':
        return `${baseClasses} bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300`;
      case 'DELETE':
        return `${baseClasses} bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300`;
      default:
        return `${baseClasses} bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300`;
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 
        rounded-lg p-4 text-red-700 dark:text-red-300 transition-colors duration-200">
        Error loading audit logs: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
          Audit Logs
        </h1>
        {Object.values(filters).some(v => v !== '') && (
          <button
            onClick={clearFilters}
            className="flex items-center gap-2 px-4 py-2 text-gray-700 dark:text-white/90 
              rounded-lg hover:bg-gray-100 dark:hover:bg-dark-primary-light/30 
              transition-colors duration-200"
          >
            <X size={16} />
            Clear Filters
          </button>
        )}
      </div>

      {/* Filters */}
      <div className="bg-white dark:bg-dark-primary-light rounded-lg p-6 shadow-lg 
        dark:shadow-dark-primary/30 transition-colors duration-200">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80 mb-1 transition-colors duration-200">
              Action Type
            </label>
            <select
              value={filters.action_type}
              onChange={(e) => handleFilterChange('action_type', e.target.value)}
              className="w-full rounded-lg border-gray-300 dark:border-white/20 dark:bg-dark-primary 
                shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                text-gray-900 dark:text-white transition-colors duration-200"
            >
              <option value="">All Actions</option>
              {filterOptions.actionTypes.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80 mb-1 transition-colors duration-200">
              Table Name
            </label>
            <select
              value={filters.table_name}
              onChange={(e) => handleFilterChange('table_name', e.target.value)}
              className="w-full rounded-lg border-gray-300 dark:border-white/20 dark:bg-dark-primary 
                shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                text-gray-900 dark:text-white transition-colors duration-200"
            >
              <option value="">All Tables</option>
              {filterOptions.tableNames.map((table) => (
                <option key={table} value={table}>{table}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80 mb-1 transition-colors duration-200">
              Date Range
            </label>
            <div className="flex gap-2">
              <input
                type="date"
                value={filters.start_date}
                onChange={(e) => handleFilterChange('start_date', e.target.value)}
                className="w-full rounded-lg border-gray-300 dark:border-white/20 dark:bg-dark-primary 
                  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                  text-gray-900 dark:text-white transition-colors duration-200"
              />
              <input
                type="date"
                value={filters.end_date}
                onChange={(e) => handleFilterChange('end_date', e.target.value)}
                className="w-full rounded-lg border-gray-300 dark:border-white/20 dark:bg-dark-primary 
                  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                  text-gray-900 dark:text-white transition-colors duration-200"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Logs Table */}
      <div className="bg-white dark:bg-dark-primary-light rounded-lg shadow-lg 
        dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-gray-400 dark:text-gray-500" />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-white/10">
              <thead className="bg-gray-50 dark:bg-dark-primary transition-colors duration-200">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Timestamp
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Action
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Table
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Administrator
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-dark-primary-light divide-y divide-gray-200 dark:divide-white/10">
                {logs.map((log) => (
                  <tr key={log.id} className="hover:bg-gray-50 dark:hover:bg-dark-primary/50 transition-colors duration-200">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {formatDate(log.created_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={getActionTypeStyles(log.action_type)}>
                        {log.action_type}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {log.table_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {log.administrator?.trainee?.name || 'Unknown'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <button
                        onClick={() => handleViewDetails(log.id)}
                        className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-900 
                          dark:hover:text-indigo-300 flex items-center gap-1 transition-colors duration-200"
                      >
                        <Eye size={16} />
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination */}
        <div className="bg-white dark:bg-dark-primary-light px-4 py-3 flex items-center justify-between 
          border-t border-gray-200 dark:border-white/10 transition-colors duration-200">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => setPagination(prev => ({ ...prev, page: prev.page - 1 }))}
              disabled={pagination.page === 1}
              className="relative inline-flex items-center px-4 py-2 text-white rounded-lg 
                transition-all transform hover:scale-105 hover:shadow-lg 
                dark:hover:shadow-dark-primary/30 disabled:opacity-50"
              style={{ 
                backgroundColor: styles.accent.color,
                backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
              }}
            >
              Previous
            </button>
            <button
              onClick={() => setPagination(prev => ({ ...prev, page: prev.page + 1 }))}
              disabled={pagination.page === pagination.totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 text-white rounded-lg 
                transition-all transform hover:scale-105 hover:shadow-lg 
                dark:hover:shadow-dark-primary/30 disabled:opacity-50"
              style={{ 
                backgroundColor: styles.accent.color,
                backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
              }}
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700 dark:text-gray-300 transition-colors duration-200">
                Showing{' '}
                <span className="font-medium">
                  {((pagination.page - 1) * pagination.pageSize) + 1}
                </span>
                {' '}to{' '}
                <span className="font-medium">
                  {Math.min(pagination.page * pagination.pageSize, pagination.total)}
                </span>
                {' '}of{' '}
                <span className="font-medium">{pagination.total}</span>
                {' '}results
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => setPagination(prev => ({ ...prev, page: prev.page - 1 }))}
                  disabled={pagination.page === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border 
                    border-gray-300 dark:border-white/20 bg-white dark:bg-dark-primary-light 
                    text-sm font-medium text-gray-500 dark:text-gray-400 
                    hover:bg-gray-50 dark:hover:bg-dark-primary 
                    disabled:opacity-50 disabled:cursor-not-allowed 
                    transition-colors duration-200"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  onClick={() => setPagination(prev => ({ ...prev, page: prev.page + 1 }))}
                  disabled={pagination.page === pagination.totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border 
                    border-gray-300 dark:border-white/20 bg-white dark:bg-dark-primary-light 
                    text-sm font-medium text-gray-500 dark:text-gray-400 
                    hover:bg-gray-50 dark:hover:bg-dark-primary 
                    disabled:opacity-50 disabled:cursor-not-allowed 
                    transition-colors duration-200"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <AuditLogDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        logDetails={selectedLog}
        loading={modalLoading}
      />
    </div>
  );
};

export default AuditLogs;