// AuthContext.js
import { createContext, useContext, useState, useCallback, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { API_URL } from '../config/api';

const AuthContext = createContext(null);

  const getStoredUser = () => {
    try {
      const data = localStorage.getItem('user');
      const parsed = data ? JSON.parse(data) : null;
      if (parsed) {
        parsed.isAdmin = parsed.isAdmin || parsed.role === 'admin' || parsed.administrator ? true : false;
      }
      return parsed;
    } catch (error) {
      console.error('Error parsing stored user data:', error);
      localStorage.removeItem('user');
      return null;
    }
  };

const getStoredToken = () => {
  try {
    return localStorage.getItem('accessToken') || null;
  } catch (error) {
    console.error('Error getting stored token:', error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getStoredUser);
  const [accessToken, setAccessToken] = useState(getStoredToken);
  
  const isTokenExpired = useCallback((token) => {
    if (!token) return true;
    try {
      const decoded = jwt_decode(token);
      return decoded.exp * 1000 < Date.now();
    } catch {
      return true;
    }
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/auth/refresh-token`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
  
      const responseData = await response.json();
  
      if (!response.ok) throw new Error(responseData.message || 'Refresh failed');
  
      // Directly use the user object from the response
      const userData = responseData.user;
      if (!userData) {
        throw new Error('No user data in refresh response');
      }
  
      localStorage.setItem('accessToken', responseData.accessToken);
      localStorage.setItem('user', JSON.stringify(userData));
      setAccessToken(responseData.accessToken);
      setUser(userData);
      return responseData.accessToken;
    } catch (error) {
      console.error('Token refresh error:', error);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      setAccessToken(null);
      setUser(null);
      throw error;
    }
  }, []);

  const getAccessToken = useCallback(async () => {
    if (!accessToken) return null;
    if (isTokenExpired(accessToken)) {
      return await refreshToken();
    }
    return accessToken;
  }, [accessToken, isTokenExpired, refreshToken]);

  const getAuthHeaders = useCallback(async () => {
    const token = await getAccessToken();
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
  }, [getAccessToken]);

  const login = useCallback(async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Login failed');
      }

      const data = await response.json();
      
      // Get user data from either trainee or user property
      const userData = data.user;
      if (!userData) {
        console.error('No user data in login response');
        throw new Error('Invalid login response - missing user data');
      }

      // Verify user data before storing
      if (!userData.id || !userData.email) {
        console.error('Invalid user data structure:', userData);
        throw new Error('Invalid user data structure');
      }

      // Add isAdmin to the stored user data
      const userWithAdmin = {
        ...userData,
        isAdmin: userData.isAdmin || userData.role === 'admin' || userData.administrator ? true : false
      };
      

      const userString = JSON.stringify(userWithAdmin);
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('user', userString);
      
      setAccessToken(data.accessToken);
      setUser(userWithAdmin);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await fetch(`${API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      });
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      setAccessToken(null);
      setUser(null);
    }
  }, []);

  // Auto refresh token when it's close to expiring
  useEffect(() => {
    if (!accessToken || !user) return;

    const decoded = jwt_decode(accessToken);
    const expiresIn = decoded.exp * 1000 - Date.now();
    const refreshTime = Math.max(expiresIn - 60000, 0); // Refresh 1 minute before expiry
    
    const timeoutId = setTimeout(refreshToken, refreshTime);
    return () => clearTimeout(timeoutId);
  }, [accessToken, user, refreshToken]);

  return (
    <AuthContext.Provider value={{ 
      user,
      login,
      logout,
      isAuthenticated: !!user && !!accessToken,
      getAccessToken,
      getAuthHeaders
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};