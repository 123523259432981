import React, { useState, useEffect, useRef } from 'react';
import SQLLineageFlowVisualizer from './SQLLineageFlowVisualizer';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { themeStyles } from './SharedComponents';
import Editor from '@monaco-editor/react';
import { API_URL } from '../config/api';

// Storage key for local storage
const STORAGE_KEY = 'sql-lineage-query';

// Initial demo SQL to show in the editor
const initialCode = `-- Example SQL query with CTEs and joins
WITH orders_summary AS (
  SELECT 
    order_id,
    customer_id,
    SUM(amount) as total_amount,
    COUNT(*) as item_count
  FROM order_items
  GROUP BY order_id, customer_id
),
customer_orders AS (
  SELECT 
    c.customer_id,
    c.customer_name,
    o.order_id,
    o.total_amount,
    o.item_count
  FROM customers c
  JOIN orders_summary o ON c.customer_id = o.customer_id
)
SELECT 
  co.customer_name,
  COUNT(co.order_id) as order_count,
  SUM(co.total_amount) as lifetime_value,
  AVG(co.item_count) as avg_items_per_order
FROM customer_orders co
GROUP BY co.customer_name
ORDER BY lifetime_value DESC
LIMIT 10;`;

const SQLVizApp = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const [code, setCode] = useState(initialCode);
  const [lineageData, setLineageData] = useState(null);
  const [error, setError] = useState(null);
  const [editorCollapsed, setEditorCollapsed] = useState(false);
  const [panelSizes, setPanelSizes] = useState({ editor: 40, visualization: 60 });
  const [isDragging, setIsDragging] = useState(false);
  const contentRef = useRef(null);
  const isDraggingRef = useRef(false);
  const editorRef = useRef(null);
  const rafRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedDialect, setSelectedDialect] = useState('');
  
  // Save SQL query to local storage
  const saveToLocalStorage = (codeToSave) => {
    try {
      localStorage.setItem(STORAGE_KEY, codeToSave);
    } catch (err) {
      console.error('Error saving to local storage:', err);
    }
  };
  
  // Load SQL query from local storage
  const loadFromLocalStorage = () => {
    try {
      const savedCode = localStorage.getItem(STORAGE_KEY);
      if (savedCode) {
        return savedCode;
      }
    } catch (err) {
      console.error('Error loading from local storage:', err);
    }
    return initialCode;
  };

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'col-resize';
      document.body.style.userSelect = 'none';
    } else {
      document.body.style.cursor = '';
      document.body.style.userSelect = '';
    }
  }, [isDragging]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (editorRef.current && !editorCollapsed) {
        const timeoutId = setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.layout();
          }
        }, 100);
  
        return () => clearTimeout(timeoutId);
      }
    };
    
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [editorCollapsed]);

  useEffect(() => {
    if (!window.monaco) return;
    
    window.monaco.editor.defineTheme('custom-dark-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': styles.secondary.color,
      }
    });
  }, [effectiveTheme, styles.secondary.color]);

  // Load saved code on component mount
  useEffect(() => {
    const savedCode = loadFromLocalStorage();
    setCode(savedCode);
  }, []);

  const handleEditorChange = (value) => {
    setCode(value);
    saveToLocalStorage(value);
  };

  const toggleEditorCollapse = () => {
    setEditorCollapsed(!editorCollapsed);
    
    setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    }, 300);
  };

  const startDragging = (e) => {
    e.preventDefault();
    isDraggingRef.current = true;
    setIsDragging(true);
    document.addEventListener('mousemove', handleDragging);
    document.addEventListener('mouseup', stopDragging);
    document.body.classList.add('resizing');
  };
  
  const handleDragging = (e) => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    
    rafRef.current = requestAnimationFrame(() => {
      if (!isDraggingRef.current || !contentRef.current) return;
      
      const containerRect = contentRef.current.getBoundingClientRect();
      const editorWidthPercent = Math.max(20, Math.min(80, 
        ((e.clientX - containerRect.left) / containerRect.width) * 100
      ));
      
      setPanelSizes({
        editor: editorWidthPercent,
        visualization: 100 - editorWidthPercent
      });
    });
  };
  
  const stopDragging = () => {
    if (!isDraggingRef.current) return;
    
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = null;
    }
    
    isDraggingRef.current = false;
    setIsDragging(false);
    document.removeEventListener('mousemove', handleDragging);
    document.removeEventListener('mouseup', stopDragging);
    document.body.classList.remove('resizing');
  };
  
  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      document.removeEventListener('mousemove', handleDragging);
      document.removeEventListener('mouseup', stopDragging);
      document.body.classList.remove('resizing');
    };
  }, []);

  const processSQL = async () => {
    setIsProcessing(true);
    setError(null);
    
    try {
      const response = await fetch(`${API_URL}/apps/sqlviz`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ 
          sql: code,
          dialect: selectedDialect || undefined // Only include if a value is selected
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        setLineageData(data.data);
      } else {
        setError(data.error || 'Error processing SQL query');
      }
    } catch (err) {
      console.error('Error processing SQL:', err);
      setError('Failed to process SQL. Please check your query syntax.');
    } finally {
      setIsProcessing(false);
    }
  };

  const importSQL = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedCode = e.target.result;
        setCode(importedCode);
        saveToLocalStorage(importedCode);
      } catch (error) {
        setError("Failed to import the SQL file.");
      }
    };
    reader.readAsText(file);
  };

  const exportSQL = () => {
    const blob = new Blob([code], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = 'query.sql';
    document.body.appendChild(a);
    a.click();
    
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const resetToDefault = () => {
    if (window.confirm('Are you sure you want to reset to the default SQL? This will discard your current work.')) {
      setCode(initialCode);
      saveToLocalStorage(initialCode);
    }
  };

  return (
    <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
      {/* Toolbar */}
      <div className="p-4 border-b border-gray-200 dark:border-gray-700/50 flex justify-between items-center">
        <div className="flex items-center">
          <button
            onClick={toggleEditorCollapse}
            className="flex items-center justify-center w-8 h-8 rounded-md hover:bg-gray-100 dark:hover:bg-dark-primary text-gray-700 dark:text-gray-300 mr-3"
          >
            {editorCollapsed ? '→' : '←'}
          </button>
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white transition-colors duration-200">
            SQL Query
          </h2>
        </div>
        <div className="flex items-center gap-3">
          <select
            value={selectedDialect}
            onChange={(e) => setSelectedDialect(e.target.value)}
            className="px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg 
                      text-gray-700 dark:text-white/90 bg-white dark:bg-dark-primary 
                      transition-colors duration-200"
          >
            <option value="">Auto-detect dialect</option>
            <option value="athena">Athena</option>
            <option value="bigquery">BigQuery</option>
            <option value="clickhouse">ClickHouse</option>
            <option value="databricks">DataBricks</option>
            <option value="duckdb">DuckDB</option>
            <option value="hive">Hive</option>
            <option value="mysql">MySQL</option>
            <option value="oracle">Oracle</option>
            <option value="postgres">PostgreSQL</option>
            <option value="presto">Presto</option>
            <option value="redshift">Redshift</option>
            <option value="snowflake">Snowflake</option>
            <option value="spark">Spark</option>
            <option value="sqlite">SQLite</option>
            <option value="tsql">TSQL</option>
            <option value="trino">Trino</option>
          </select>
          
          <button
            onClick={processSQL}
            disabled={isProcessing}
            className="px-4 py-2 text-white rounded-lg transition-all hover:scale-105 shadow-sm disabled:opacity-50 disabled:hover:scale-100"
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          >
            {isProcessing ? 'Processing...' : 'Process SQL'}
          </button>
        </div>
        <div className="flex gap-3">
          <label className="flex items-center gap-2 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg cursor-pointer text-gray-700 dark:text-white/90 hover:bg-gray-50 dark:hover:bg-dark-primary transition-colors duration-200">
            <span>Import SQL</span>
            <input 
              type="file" 
              accept=".sql,.txt" 
              onChange={importSQL} 
              className="hidden" 
            />
          </label>
          <button
            onClick={exportSQL}
            className="px-4 py-2 text-white rounded-lg transition-all hover:scale-105 shadow-sm"
            style={{ 
              backgroundColor: styles.secondary.color,
              backgroundImage: `linear-gradient(to right, ${styles.secondary.color}, ${styles.primary.color})` 
            }}
          >
            Export SQL
          </button>
          <button
            onClick={resetToDefault}
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white/90 rounded-lg transition-all hover:bg-gray-300 dark:hover:bg-gray-600"
          >
            Reset
          </button>
        </div>
      </div>
      
      {/* Main Content Area */}
      <div 
        ref={contentRef} 
        className="flex relative" 
        style={{ height: 'calc(100vh - 250px)', minHeight: '500px' }}
      >
        {/* Editor Panel */}
        <div 
          className={`transition-all duration-300 flex flex-col border-r border-gray-200 dark:border-gray-700/50 
                      ${editorCollapsed ? 'w-[40px]' : ''}`}
          style={{ 
            width: editorCollapsed ? '40px' : `${panelSizes.editor}%`,
            flexShrink: 0
          }}
        >
          {!editorCollapsed ? (
            <div className="h-full overflow-auto bg-white dark:bg-dark-primary transition-colors duration-200">
              <Editor
                height="100%"
                width="100%"
                defaultLanguage="sql"
                value={code}
                onChange={handleEditorChange}
                theme={effectiveTheme === 'dark' ? "custom-dark-theme" : "vs-light"}
                options={{
                  minimap: { enabled: false },
                  fontSize: 14,
                  wordWrap: 'on',
                  automaticLayout: true,
                }}
                onMount={(editor) => {
                  editorRef.current = editor;
                }}
                beforeMount={(monaco) => {
                  monaco.editor.defineTheme('custom-dark-theme', {
                    base: 'vs-dark',
                    inherit: true,
                    rules: [],
                    colors: {
                      'editor.background': styles.secondary.color,
                    }
                  });
                }}
              />
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <span className="transform -rotate-90 text-gray-500 whitespace-nowrap">SQL Editor</span>
            </div>
          )}
        </div>
        
        {/* Resize Handle */}
        <div 
          className={`w-[5px] bg-gray-200 dark:bg-gray-700 cursor-col-resize absolute top-0 bottom-0 z-10 
                    hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200
                    ${editorCollapsed ? 'hidden' : ''}`}
          onMouseDown={startDragging}
          style={{ left: `${panelSizes.editor}%` }}
        ></div>
        
        {/* Visualization Panel */}
        <div 
          className="transition-all duration-300 overflow-y-auto p-4"
          style={{ 
            width: editorCollapsed ? 'calc(100% - 40px)' : `${panelSizes.visualization}%`,
            flexShrink: 0
          }}
        >
          {error && (
            <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 rounded-lg text-red-700 dark:text-red-300 transition-colors duration-200">
              {error}
            </div>
          )}
          
          {!lineageData && !isProcessing && (
            <div className="flex flex-col items-center justify-center h-full">
              <div className="text-gray-400 dark:text-gray-600 text-center">
                <svg className="w-16 h-16 mx-auto mb-4" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 14a6 6 0 110-12 6 6 0 010 12zm-1-5a1 1 0 112 0v1a1 1 0 11-2 0v-1zm0-3a1 1 0 112 0 1 1 0 01-2 0z" clipRule="evenodd" />
                </svg>
                <p className="text-lg">Enter a SQL query and click "Process SQL" to visualize data lineage</p>
              </div>
            </div>
          )}
          
          {isProcessing && (
            <div className="flex flex-col items-center justify-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              <p className="mt-4 text-gray-600 dark:text-gray-300">Processing SQL query...</p>
            </div>
          )}
          
          {lineageData && !isProcessing && (
            <div data-export-container>
                <SQLLineageFlowVisualizer data={lineageData} />
            </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SQLVizApp;