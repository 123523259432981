import { FileText, Image, Video, FileQuestion, Link, Code } from 'lucide-react';

export const contentTypes = [
  { value: 'text', label: 'Text Content', icon: FileText },
  { value: 'video', label: 'Video', icon: Video },
  { value: 'image', label: 'Image', icon: Image },
  { value: 'quiz', label: 'Quiz', icon: FileQuestion },
  { value: 'protected-pdf', label: 'Protected PDF', icon: FileText },
  { value: 'link', label: 'Link', icon: Link },
  { value: 'code', label: 'Code Block', icon: Code },
];

export const getContentTypeIcon = (type) => {
  const ContentIcon = contentTypes.find(t => t.value === type)?.icon || FileText;
  return <ContentIcon size={16} />;
};