import React from 'react';
import { Sun, Moon, Monitor } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';

const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();

  const cycleTheme = () => {
    const themeOptions = ['system', 'light', 'dark'];
    const currentIndex = themeOptions.indexOf(theme);
    const nextIndex = (currentIndex + 1) % themeOptions.length;
    setTheme(themeOptions[nextIndex]);
  };

  return (
    <button 
      onClick={cycleTheme} 
      className="text-gray-400 hover:text-white transition-colors"
      aria-label="Toggle theme"
    >
      {theme === 'light' && <Sun size={24} />}
      {theme === 'dark' && <Moon size={24} />}
      {theme === 'system' && <Monitor size={24} />}
    </button>
  );
};

export default ThemeSwitcher;