import React from 'react';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { 
  HeroSection, 
  GradientBreakSection,
  CTASection,
  themeStyles,
  ContentSection
} from './components/SharedComponents';

function OurStory() {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const contentSections = [
    {
      title: "The Corporate Data Odyssey",
      content: [
        "My journey in data began almost 2 decades ago, working across diverse industries from consulting to finance, healthcare, and hospitality. Each role presented unique challenges that deepened my understanding of how data transforms business strategies and drives meaningful innovation.",
        "Throughout my career, I was fortunate to lead data teams that consistently pushed the boundaries of what's possible. Whether it was optimizing supply chains, predicting customer behavior, or developing complex compensation models, I saw firsthand the transformative power of well-executed data strategies."
      ]
    },
    {
      title: "The Mentorship Catalyst",
      content: [
        "As I mentored teams throughout my career, I realized that technical skills were only part of the equation. The most successful data professionals combined deep technical knowledge with a unique ability to translate data insights into actionable business strategies.",
        "Many talented individuals lacked the structured learning paths and real-world context to truly excel. This observation became the seed of an idea – what if there was a training platform that didn't just teach technical skills, but immersed learners in realistic, industry-driven scenarios?"
      ]
    },
    {
      title: "Birth of Data Training Lab",
      content: [
        "In early 2023, the concept for Data Training Lab crystallized. I wanted to create more than just another online course platform. The vision was to develop a comprehensive learning environment that simulates real-world data challenges, complete with intricate business contexts and mentorship.",
        "The Summit Stay Hotel simulation became our innovative solution. By creating a complete, fictional hospitality ecosystem, we could provide learners with an immersive experience that bridges the gap between academic learning and professional application.",
        "Over the months that followed, we collaborated with industry experts, data professionals, and educational technologists to refine our approach, ensuring that each learning pathway provides genuine, actionable skills."
      ]
    },
    {
      title: "Our Commitment",
      content: [
        "Data Training Lab is more than a platform – it's a mission to democratize high-quality data education. We believe that with the right guidance, context, and hands-on experience, anyone can become a transformative data professional."
      ]
    }
  ];

  const breakSections = [
    "Bridging the gap between technical expertise and business impact",
    "Creating immersive learning experiences that mirror real-world challenges",
    "Empowering the next generation of data leaders"
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        imageSrc="/images/founder-journey.png"
        fallbackSrc="/founder-journey.png"
        title="The Story Behind Data Training Lab"
        subtitle="A journey from corporate data leadership to empowering the next generation of data professionals"
        styles={styles}
        ctaLink="/about"
        ctaText="Our Platform"
      />

      {contentSections.slice(0, 1).map((section, index) => (
        <ContentSection key={index} {...section} />
      ))}

      <GradientBreakSection 
        text={breakSections[0]}
        styles={styles}
      />

      {contentSections.slice(1, 2).map((section, index) => (
        <ContentSection key={index} {...section} />
      ))}

      <GradientBreakSection 
        text={breakSections[1]}
        styles={styles}
      />

      {contentSections.slice(2, 3).map((section, index) => (
        <ContentSection key={index} {...section} />
      ))}

      <GradientBreakSection 
        text={breakSections[2]}
        styles={styles}
      />

      {contentSections.slice(3).map((section, index) => (
        <ContentSection key={index} {...section} />
      ))}

      <CTASection 
        title="Ready to Begin Your Data Journey?"
        subtitle="Join our community of data professionals and start building real-world experience today."
        ctaText="Find Out About Our Platform"
        styles={styles}
        to="/about"
      />

      <Footer />
    </div>
  );
}

export default OurStory;