import React, { useState, useCallback } from 'react';
import Navigation from './components/Navigation';
import CDNImage from './components/CDNImage';
import EnrollmentModal from './components/EnrollmentModal';
import Footer from './components/Footer';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import { 
  HeroSection,
  themeStyles,
  CTASection
} from './components/SharedComponents';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function Tracks() {
  const [showModal, setShowModal] = useState(false);
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const location = useLocation();

  const scrollToSection = useCallback(() => {
    const hash = location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      
      if (element) {
        requestAnimationFrame(() => {
          // Calculate the target scroll position
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - 100; 
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    // Scroll to section after a short delay to ensure content is loaded
    const timer = setTimeout(scrollToSection, 100);
    
    return () => clearTimeout(timer);
  }, [location.hash, scrollToSection]);

  const pathways = [
    {
      title: "Data Engineering",
      image: "/images/engineering.jpg",
      fallbackImage: "/engineering.jpg",
      description: "Step into the role of a Data Engineer at Summit Stay Hotel in our comprehensive 24-week program. You'll build a complete data infrastructure from the ground up, working with our Property Management System (PMS) API.",
      apiDocs: "https://api.summitstayhotel.com/api-docs/",
      duration: "24 weeks",
      technicalSkills: [
        "Python and SQL development",
        "Cloud platforms (AWS/Snowflake)",
        "Apache Airflow orchestration",
        "Terraform for infrastructure",
        "CI/CD implementation",
        "Data quality frameworks"
      ],
      builds: [
        "End-to-end data platform architecture",
        "Booking and folio data pipelines",
        "Guest analytics data warehouse",
        "Automated quality monitoring",
        "Security and compliance systems"
      ],
      supportTeam: [
        "Senior Data Engineer mentor",
        "Virtual hotel management team",
        "Technical support specialists",
        "Fellow learner community"
      ],
      timeline: [
        {
          period: "Weeks 1-8",
          description: "Master fundamentals of development, cloud platforms, and data integration while working with hotel data sources including our PMS and weather APIs."
        },
        {
          period: "Weeks 9-16",
          description: "Build data warehouses, implement ELT processes, and create orchestrated pipelines using industry-standard tools like Airflow."
        },
        {
          period: "Weeks 17-24",
          description: "Deploy production systems, implement monitoring, and complete a capstone project showcasing your full-stack data engineering capabilities."
        }
      ],
      projects: [
        "Seasonal booking analysis system",
        "Guest experience data platform",
        "Revenue optimization pipeline",
        "Operational efficiency monitoring",
        "Marketing analytics integration"
      ]
    },
    {
      title: "Data Analytics",
      image: "/images/analytics.jpg",
      fallbackImage: "/analytics.jpg",
      description: "Join our 24-week Data Analytics program where you'll master the art of transforming hotel data into actionable insights. Work with real-life datasets from Summit Stay Hotel's data warehouse (built using the best practices from the Data Engineering course), learn advanced analytics techniques, and develop the skills to drive data-informed decision making across the organization",
      duration: "24 weeks",
      technicalSkills: [
        "Advanced SQL and Python analytics",
        "Statistical analysis and modeling",
        "Power BI and Tableau",
        "Machine learning fundamentals",
        "Data visualization techniques",
        "Business intelligence tools"
      ],
      builds: [
        "Interactive business dashboards",
        "Automated reporting systems",
        "Predictive analytics models",
        "Customer segmentation tools",
        "Performance monitoring systems",
        "Revenue analysis frameworks"
      ],
      supportTeam: [
        "Senior Data Analyst mentor",
        "Business intelligence expert",
        "Statistics specialist",
        "Fellow learner community"
      ],
      timeline: [
        {
          period: "Weeks 1-8",
          description: "Learn foundational analytics skills while working with hotel data sources, mastering SQL, Python, and statistical analysis techniques."
        },
        {
          period: "Weeks 9-16",
          description: "Develop expertise in visualization tools, create comprehensive dashboards, and implement automated reporting systems."
        },
        {
          period: "Weeks 17-24",
          description: "Apply advanced analytics techniques, build predictive models, and complete a capstone project demonstrating your analytical capabilities."
        }
      ],
      projects: [
        "Revenue performance analysis",
        "Guest satisfaction monitoring",
        "Marketing campaign effectiveness",
        "Competitive analysis system",
        "Resource optimization studies"
      ]
    },
    {
      title: "Data Governance",
      image: "/images/governance.jpg",
      fallbackImage: "/governance.jpg",
      description: "Embark on a 24-week journey to master data governance. Learn to develop and implement robust governance frameworks, ensure compliance with regulations, and lead data initiatives that balance innovation with risk management at Summit Stay Hotel",
      duration: "24 weeks",
      technicalSkills: [
        "Data governance frameworks",
        "Privacy and compliance regulations",
        "Metadata management",
        "Data quality assessment",
        "Risk management",
        "Policy automation approaches"
      ],
      builds: [
        "Governance frameworks",
        "Compliance monitoring",
        "Data quality programs",
        "Privacy protection solutions",
        "Policy management platforms",
        "Training and documentation systems"
      ],
      supportTeam: [
        "Data Governance expert",
        "Compliance specialist",
        "Change management coach",
        "Fellow learner community"
      ],
      timeline: [
        {
          period: "Weeks 1-8",
          description: "Master governance fundamentals, understand regulatory requirements, and learn to assess data management practices."
        },
        {
          period: "Weeks 9-16",
          description: "Develop governance frameworks, implement compliance monitoring, and create effective data policies and procedures."
        },
        {
          period: "Weeks 17-24",
          description: "Lead governance initiatives, implement change management strategies, and complete a capstone project showcasing your leadership capabilities."
        }
      ],
      projects: [
        "Data privacy program implementation",
        "Quality management framework",
        "Governance structure development",
        "Compliance program creation",
        "Change management initiative"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        imageSrc="/images/dashboard.png"
        fallbackSrc="/dashboard.png"
        title="Learning Pathways"
        subtitle="Start your journey in data with our immersive, project-based learning paths. Each pathway provides real-world context and practical experience."
        styles={styles}
        ctaText="Read Our Story"
        ctaLink="/our-story"
      />

      {/* Pathways Section */}
      <div className="py-24 bg-gray-50 dark:bg-dark-primary-light/30 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4">
          {pathways.map((pathway, index) => (
            <div 
              id={`track-${pathway.title.toLowerCase().replace(' ', '-')}`}
              key={index} 
              className="mb-16 bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl overflow-hidden 
                       transform transition-all hover:shadow-2xl dark:shadow-dark-primary/30 
                       dark:hover:shadow-dark-primary/50"
            >
              <div className="p-8">
                {/* Header Section */}
                <div className="flex flex-col lg:flex-row gap-8 mb-12">
                  <div className="lg:w-1/3">
                    <div className="rounded-2xl overflow-hidden shadow-lg transform transition-all hover:scale-105">
                      <CDNImage
                        src={pathway.image}
                        alt={`${pathway.title} Illustration`}
                        className="w-full h-auto object-cover"
                        fallbackSrc={pathway.fallbackImage}
                      />
                    </div>
                  </div>
                  <div className="lg:w-2/3">
                    <h2 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">
                      {pathway.title}
                    </h2>
                    <div className="mb-4">
                      <span 
                        className="inline-block px-4 py-2 rounded-full text-white text-sm mb-4"
                        style={{ 
                          backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                        }}
                      >
                        {pathway.duration}
                      </span>
                    </div>
                    <p className="text-gray-700 dark:text-white/80 mb-6">
                      {pathway.description}
                      {pathway.apiDocs && (
                        <>
                          {' '}
                          <a 
                            href={pathway.apiDocs}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 
                                     dark:hover:text-blue-300 transition-colors"
                          >
                            View API documentation
                          </a>
                        </>
                      )}
                    </p>
                  </div>
                </div>

                {/* Features Grid */}
                <div className="grid md:grid-cols-3 gap-6 mb-12">
                  <PathwayFeature
                    title="Core Technical Skills"
                    items={pathway.technicalSkills}
                    styles={styles}
                  />
                  <PathwayFeature
                    title="What You'll Build"
                    items={pathway.builds}
                    styles={styles}
                  />
                  <PathwayFeature
                    title="Your Support Team"
                    items={pathway.supportTeam}
                    styles={styles}
                  />
                </div>

                {/* Timeline and Projects */}
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="bg-gray-50 dark:bg-dark-primary-light/50 rounded-2xl p-6">
                    <h4 className="text-xl font-bold mb-6 text-gray-900 dark:text-white">
                      Program Timeline
                    </h4>
                    <div className="space-y-6">
                      {pathway.timeline.map((period, i) => (
                        <div key={i} className="relative pl-6 border-l-2 border-gray-200 dark:border-gray-700">
                          <div 
                            className="absolute left-[-5px] top-0 w-2 h-2 rounded-full"
                            style={{ backgroundColor: styles.accent.color }}
                          ></div>
                          <h5 
                            className="font-bold mb-2"
                            style={{ color: styles.accent.color }}
                          >
                            {period.period}
                          </h5>
                          <p className="text-gray-700 dark:text-white/80 text-sm">
                            {period.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-gray-50 dark:bg-dark-primary-light/50 rounded-2xl p-6">
                    <h4 className="text-xl font-bold mb-6 text-gray-900 dark:text-white">
                      Featured Projects
                    </h4>
                    <div className="grid grid-cols-1 gap-4">
                      {pathway.projects.map((project, i) => (
                        <div 
                          key={i}
                          className="bg-white dark:bg-dark-primary rounded-lg p-4 shadow 
                                   transform transition-all hover:scale-105"
                        >
                          <span className="text-gray-700 dark:text-white/80">{project}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <CTASection 
        title="Ready to Start Your Journey?"
        subtitle="Join our community of data professionals and start building real-world experience today."
        ctaText="Read Our Story"
        styles={styles}
        to="/our-story"
      />
      <Footer />

      <EnrollmentModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

// Helper component for pathway features
const PathwayFeature = ({ title, items, styles }) => (
  <div className="bg-gray-50 dark:bg-dark-primary-light/50 rounded-2xl p-6 transform transition-all hover:scale-105">
    <h4 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
      {title}
    </h4>
    <ul className="space-y-3">
      {items.map((item, i) => (
        <li key={i} className="flex items-start gap-2">
          <span 
            className="w-2 h-2 rounded-full mt-2"
            style={{ backgroundColor: styles.accent.color }}
          ></span>
          <span className="text-gray-700 dark:text-white/80">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default Tracks;