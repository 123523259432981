import React from 'react';
import { ArrowLeft, ArrowUpDown, Plus } from 'lucide-react';

const ContentHeader = ({ 
  onBack, 
  isReorderMode, 
  onToggleReorder, 
  onAddContent,
  styles 
}) => (
  <div className="space-y-6">
    <button
      onClick={onBack}
      className="flex items-center gap-2 text-gray-600 dark:text-gray-300 
        hover:text-gray-900 dark:hover:text-white transition-colors duration-200"
    >
      <ArrowLeft size={20} />
      Back to Sections
    </button>
    
    <div className="flex justify-between items-center">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
        Content Management
      </h1>
      
      <div className="flex gap-4">
        <button
          onClick={onToggleReorder}
          className="flex items-center gap-2 px-6 py-3 rounded-lg 
            text-white transition-all transform hover:scale-105 
            hover:shadow-lg dark:hover:shadow-dark-primary/30"
          style={{ 
            backgroundColor: styles.secondary.color,
            backgroundImage: `linear-gradient(to right, ${styles.secondary.color}, ${styles.primary.color})` 
          }}
        >
          <ArrowUpDown size={20} />
          {isReorderMode ? 'Exit Reorder' : 'Reorder Content'}
        </button>
        
        <button
          onClick={onAddContent}
          className="flex items-center gap-2 px-6 py-3 rounded-lg 
            text-white transition-all transform hover:scale-105 
            hover:shadow-lg dark:hover:shadow-dark-primary/30"
          style={{ 
            backgroundColor: styles.accent.color,
            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
          }}
        >
          <Plus size={20} />
          Add Content
        </button>
      </div>
    </div>
  </div>
);

export default ContentHeader;