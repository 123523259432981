import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import CDNImage from './CDNImage';
import { themeStyles } from './SharedComponents';
import { useTheme } from '../contexts/ThemeContext';

const PreviewCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const { theme } = useTheme();
  const styles = themeStyles[theme === 'dark' ? 'dark' : 'light'];
  
  const slides = [
    {
      title: "Track Overview",
      description: "Get a glimpse of your learning journey, with clear progress tracking and course structure.",
      imageSrc: "/images/carousel/track-overview.png",
      fallbackSrc: "/carousel/track-overview.png",
      alt: "Data Engineering Track Overview"
    },
    {
      title: "Expert-Led Video Content",
      description: "Learn from industry professionals through high-quality video lectures and demonstrations.",
      imageSrc: "/images/carousel/video-content.png",
      fallbackSrc: "/carousel/video-content.png",
      alt: "Video Content Example"
    },
    {
      title: "Hands-On Code Examples",
      description: "Practice with real-world code snippets and examples using industry-standard tools.",
      imageSrc: "/images/carousel/code-examples.png",
      fallbackSrc: "/carousel/code-examples.png",
      alt: "Code Examples"
    },
    {
      title: "Knowledge Checkpoints",
      description: "Regular assessments ensure you've mastered the material before moving forward.",
      imageSrc: "/images/carousel/assessment.png",
      fallbackSrc: "/carousel/assessment.png",
      alt: "Assessment Example"
    }
  ];

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    setIsPaused(true);
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    setIsPaused(true);
  }, [slides.length]);

  const handleDotClick = useCallback((index) => {
    setCurrentSlide(index);
    setIsPaused(true);
  }, []);

  // Auto-rotation effect
  useEffect(() => {
    if (!isPaused) {
      const timer = setTimeout(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [currentSlide, isPaused, slides.length]);

  // Reset pause state when mouse leaves the carousel
  const handleMouseLeave = useCallback(() => {
    setIsPaused(false);
  }, []);

  // Pause auto-rotation when mouse enters the carousel
  const handleMouseEnter = useCallback(() => {
    setIsPaused(true);
  }, []);

  return (
    <div className="w-full bg-gray-50 dark:bg-dark-primary/90 pt-0 pb-16 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white transition-colors duration-200">
          Preview Your Learning Experience
        </h2>
        
        <div 
          className="relative bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex flex-col md:flex-row items-center p-8 gap-8">
            <div className="md:w-1/2">
              <div className="relative aspect-[4/3] w-full">
                <div 
                  key={currentSlide} 
                  className="absolute inset-0 transition-opacity duration-500 ease-in-out"
                  style={{ 
                    opacity: 1,
                    transition: 'opacity 0.5s ease-in-out'
                  }}
                >
                  <CDNImage 
                    src={slides[currentSlide].imageSrc}
                    fallbackSrc={slides[currentSlide].fallbackSrc}
                    alt={slides[currentSlide].alt}
                    className="rounded-xl shadow-lg dark:shadow-gray-900/30 object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>
            
            <div className="md:w-1/2 space-y-6">
              <h3 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
                {slides[currentSlide].title}
              </h3>
              <p className="text-gray-700 dark:text-gray-300 text-lg transition-colors duration-200">
                {slides[currentSlide].description}
              </p>
              
              <div className="flex items-center justify-between pt-4">
                <button 
                  onClick={prevSlide}
                  className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-dark-secondary/50 
                          text-gray-700 dark:text-gray-300 transition-colors"
                  aria-label="Previous slide"
                >
                  <ChevronLeft size={24} />
                </button>
                
                <div className="flex gap-2">
                  {slides.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handleDotClick(index)}
                      className={`h-2 rounded-full transition-all ${
                        currentSlide === index 
                          ? `w-6 ${styles.accent.className}` 
                          : 'w-2 bg-gray-300 dark:bg-gray-700'
                      }`}
                      style={{ 
                        backgroundColor: currentSlide === index ? styles.accent.color : undefined 
                      }}
                      aria-label={`Go to slide ${index + 1}`}
                    />
                  ))}
                </div>
                
                <button 
                  onClick={nextSlide}
                  className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-dark-secondary/50 
                          text-gray-700 dark:text-gray-300 transition-colors"
                  aria-label="Next slide"
                >
                  <ChevronRight size={24} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCarousel;