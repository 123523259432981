// src/components/custom-nodes/TableNode.js
import React from 'react';
import { Handle, Position } from 'reactflow';

const TableNode = ({ data }) => {
  return (
    <div style={{
      padding: '10px 15px',
      borderRadius: '5px',
      background: '#eff6ff',
      border: '1px solid #bfdbfe',
      minWidth: '150px',
      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
    }}>
      <Handle type="source" position={Position.Right} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ 
          display: 'inline-block', 
          marginRight: '8px',
          color: '#3b82f6',
          fontWeight: 'bold'
        }}>DB</div>
        <span style={{ fontWeight: '500', color: '#1e40af' }}>{data.label}</span>
      </div>
    </div>
  );
};

export default TableNode;