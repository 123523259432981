import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { 
  HeroSection,
  GradientBreakSection, 
  ContentSection, 
  CTASection,
  themeStyles 
} from './components/SharedComponents';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';

const CookiePolicy = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#policy-details') {
      const element = document.getElementById('policy-details');
      if (element) {
        // Smooth scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const contentSections = [
    {
      title: "Introduction",
      content: [
        "This Cookie Policy explains how Data Training Lab uses cookies and similar technologies to provide essential functionality on our educational platform. We maintain a minimal cookie policy, using only those cookies that are necessary for our platform to function properly."
      ]
    },
    {
      title: "What Are Cookies",
      content: [
        "Cookies are small text files that are stored on your device when you visit a website. They are widely used to make websites work more efficiently and provide basic functionality such as remembering your preferences and login status."
      ]
    },
    {
      title: "How We Use Cookies",
      content: [
        "We use only essential cookies that are strictly necessary for our platform to function. These cookies are used to:",
        "• Maintain your login session",
        "• Remember your authentication status",
        "• Enable basic platform functionality",
        "• Ensure secure access to your account",
        "",
        "We do not use any:",
        "• Marketing or advertising cookies",
        "• Analytics cookies",
        "• Third-party tracking cookies",
        "• Social media cookies",
        "",
        "In addition to cookies, we also use local storage in some of our interactive tools, such as the Enterprise Bus Matrix application. This local storage:",
        "• Saves your work automatically as you make changes",
        "• Allows you to continue where you left off when you return to the tool",
        "• Is stored only on your device and is not transmitted to our servers",
        "• Contains only the data you create while using the tool"
      ]
    },
    {
      title: "Types of Cookies We Use",
      content: [
        "Our platform uses the following essential cookies:",
        "",
        "Cookie Name: auth_token",
        "Purpose: Maintains your authentication status",
        "Duration: Session",
        "",
        "Cookie Name: session_id",
        "Purpose: Manages your active session",
        "Duration: Session"
      ]
    },
    {
      title: "Third-Party Cookies",
      content: [
        "Our payment processor, Stripe, may set cookies during the checkout process. These cookies are essential for processing payments securely. Please refer to Stripe's cookie policy for more information about their cookie usage.",
        "",
        "Similarly, Vimeo may set cookies when you view video content. These cookies are managed by Vimeo and are subject to their cookie policy."
      ]
    },
    {
      title: "Managing Cookies",
      content: [
        "Most web browsers allow you to control cookies through their settings preferences. However, please note that since we only use essential cookies, disabling cookies may limit your ability to use our platform or prevent it from functioning properly.",
        "",
        "You can manage cookie settings in your browser through the following paths:",
        "• Google Chrome: Settings → Privacy and Security → Cookies and other site data",
        "• Firefox: Options → Privacy & Security → Cookies and Site Data",
        "• Safari: Preferences → Privacy → Cookies and website data",
        "• Edge: Settings → Cookies and site permissions → Cookies and site data"
      ]
    },
    {
      title: "Changes to Cookie Policy",
      content: [
        "We may update this Cookie Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons. We will post any updates on this page and update the \"Last Updated\" date at the top of this policy.",
        "",
        "Your continued use of our platform after any changes to this Cookie Policy constitutes your acceptance of the updated policy."
      ]
    },
    {
      title: "Contact Information",
      content: [
        "If you have any questions about our Cookie Policy or how we use cookies, please contact us using the Contact Us link below."
      ]
    }
  ];

  const breakSections = [
    "We prioritize your privacy by using only essential cookies",
    "Keeping your data secure and your experience smooth",
    "Your privacy and security are our top priorities"
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        title="Cookie Policy"
        subtitle="Understanding how we use cookies to improve your experience"
        ctaText="Learn More"
        ctaLink="#policy-details"
        styles={styles}
        imageSrc="/images/legal.png"
        fallbackSrc="/legal.png"
      />
      <div className='text-gray-700 dark:text-white/90 max-w-4xl mx-10'>Last Updated: 1 Mar 2025</div>

      {contentSections.slice(0, 2).map((section, index) => (
        <React.Fragment key={index}>
          {index === 0 && (
            <GradientBreakSection 
              text={breakSections[0]}
              styles={styles}
            />
          )}
          <div id="policy-details">
            <ContentSection {...section} />
          </div>
        </React.Fragment>
      ))}

      {contentSections.slice(2, 5).map((section, index) => (
        <React.Fragment key={index}>
          {index === 0 && (
            <GradientBreakSection 
              text={breakSections[1]}
              styles={styles}
            />
          )}
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      {contentSections.slice(5).map((section, index) => (
        <React.Fragment key={index}>
          {index === 0 && (
            <GradientBreakSection 
              text={breakSections[2]}
              styles={styles}
            />
          )}
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      <CTASection 
        title="Ready to Start Your Journey?"
        subtitle="Join our community of data professionals and start building real-world experience today."
        ctaText="View Learning Tracks"
        styles={styles}
        to="/tracks"
      />

      <Footer />
    </div>
  );
};

export default CookiePolicy;