import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminConsole from "./AdminConsole";
import { Loader2 } from 'lucide-react';

const AdminRoute = ({ children }) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    // Only redirect if loading is finished and user is not an admin
    if (!isLoading && user && !user.isAdmin) {
      setIsRedirecting(true);
      navigate("/", { replace: true });
    }
  }, [user, isLoading, navigate]);

  // Show loading spinner while checking auth status
  if (isLoading || isRedirecting) {
    return (
      <div className="flex items-center justify-center h-screen" style={{ backgroundColor: '#311d3f' }}>
        <Loader2 className="w-8 h-8 animate-spin text-white" />
      </div>
    );
  }

  // Redirect if no user or not admin
  if (!user || !user.isAdmin) {
    return <Navigate to="/" replace />;
  }

  // If user is valid and an admin, render the AdminConsole
  return <AdminConsole>{children}</AdminConsole>;
};

export default AdminRoute;