import React, { useState } from 'react';
import { Mail, User, MessageSquare } from 'lucide-react';
import Navigation from './components/Navigation';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import { 
  ContentSection, 
  themeStyles,
  HeroSection,
  CTASection 
} from './components/SharedComponents';
import Footer from './components/Footer';
import { API_URL } from './config/api';
import { FormInput, FormButton, FormError, FormSuccess } from './components/FormElements';


const ContactUs = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    description: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      const response = await fetch(`${API_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to send message');
      }
  
      setSuccess(true);
      setFormData({
        name: '',
        email: '',
        subject: '',
        description: ''
      });
    } catch (error) {
      setError(error.message || 'Failed to send message');
    } finally {
      setLoading(false);
    }
  };

  const renderContactContent = () => {
    if (success) {
      return (
        <div className="space-y-4">
          <FormSuccess message="Message Sent Successfully! Thank you for contacting us. We'll get back to you as soon as possible." />
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <FormError message={error} />

        <form onSubmit={handleSubmit} className="space-y-6">
          <FormInput
            icon={User}
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your full name"
            required
          />

          <FormInput
            icon={Mail}
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="you@example.com"
            required
          />

          <FormInput
            icon={MessageSquare}
            label="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="What can we help you with?"
            required
          />

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white/80">Description</label>
            <div className="mt-1">
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows={6}
                className="block w-full rounded-md border border-gray-300 dark:border-white/20 dark:bg-dark-primary-light px-3 py-2 shadow-sm 
                  focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 
                  dark:text-white dark:placeholder-white/50"
                placeholder="Please provide details about your inquiry..."
                required
              />
            </div>
          </div>

          <FormButton
            text="Send Message"
            loading={loading}
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          />
        </form>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      
      <HeroSection 
        title="Contact Us"
        subtitle="Have questions? We're here to help. Reach out to our team and we'll get back to you as soon as possible."
        styles={styles}
        imageSrc="/images/contact.png"
        fallbackSrc="/contact.png"
      />

      <ContentSection 
        title="Get in Touch" 
        content={[
          "Whether you have questions about our courses, need technical support, or want to learn more about our platform, we're here to help."
        ]}
      >
        {renderContactContent()}
      </ContentSection>

      <CTASection 
        title="Ready to Start Learning?"
        subtitle="Explore our comprehensive learning tracks and begin your journey in data."
        ctaText="View Learning Tracks"
        styles={styles}
        to="/tracks"
      />

      <Footer />
    </div>
  );
};

export default ContactUs;