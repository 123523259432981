import { useRef, useCallback } from 'react';

export const useDragSort = (items, onReorder) => {
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDragStart = useCallback((e, position) => {
    dragItem.current = position;
    e.target.classList.add('opacity-50');
  }, []);

  const handleDragEnter = useCallback((e, position) => {
    dragOverItem.current = position;
    e.target.closest('tr').classList.add('bg-gray-100', 'dark:bg-dark-primary-light/50');
  }, []);

  const handleDragEnd = useCallback((e) => {
    e.target.classList.remove('opacity-50');
    
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const itemsCopy = [...items];
      const draggedItem = itemsCopy[dragItem.current];
      
      // Remove the dragged item from its original position
      itemsCopy.splice(dragItem.current, 1);
      
      // Insert the dragged item at the new position
      itemsCopy.splice(dragOverItem.current, 0, draggedItem);

      // Update sequences
      const reorderedItems = itemsCopy.map((item, index) => ({
        ...item,
        sequence: index + 1
      }));

      // Call the reorder callback
      onReorder(reorderedItems);
    }

    // Reset drag references
    dragItem.current = null;
    dragOverItem.current = null;

    // Remove highlighting from all rows
    document.querySelectorAll('tr').forEach(tr => {
      tr.classList.remove('bg-gray-100', 'dark:bg-dark-primary-light/50');
    });
  }, [items, onReorder]);

  const handleDragLeave = useCallback((e) => {
    e.target.closest('tr').classList.remove('bg-gray-100', 'dark:bg-dark-primary-light/50');
  }, []);

  return {
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleDragLeave
  };
};

export default useDragSort;