import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import CDNImage from './CDNImage';
import Footer from './Footer';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { API_URL } from '../config/api';
import { 
  LoadingState, 
  ErrorState, 
  themeStyles 
} from './SharedComponents';
import { ChevronRight, BookOpen, CheckCircle, Clock, ArrowLeft } from 'lucide-react';


const ProgressDashboard = () => {
  const [tracks, setTracks] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [enrollingTrackId, setEnrollingTrackId] = useState(null);
  
  const { getAuthHeaders } = useAuth();
  const navigate = useNavigate();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const fetchData = async () => {
    try {
      const headers = await getAuthHeaders();
      const [tracksResponse, enrollmentsResponse] = await Promise.all([
        fetch(`${API_URL}/tracks`, {
          headers
        }),
        fetch(`${API_URL}/tracks/my-enrollments`, {
          headers
        })
      ]);
      
      if (!tracksResponse.ok || !enrollmentsResponse.ok) {
        throw new Error('Failed to fetch data');
      }
      
      const tracksData = await tracksResponse.json();
      const enrollmentsData = await enrollmentsResponse.json();
      
      setTracks(tracksData);
      setEnrollments(enrollmentsData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getAuthHeaders]);

  const handleEnroll = async (trackId) => {
    setEnrollingTrackId(trackId);
    try {
      const headers = await getAuthHeaders();
      headers['Content-Type'] = 'application/json';
  
      const response = await fetch(`${API_URL}/payments/create-checkout-session`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ trackId })
      });
  
      if (!response.ok) {
        const errorBody = await response.json().catch(() => ({}));
        console.error('Enrollment error response:', errorBody);
        throw new Error(errorBody.message || 'Failed to create checkout session');
      }
  
      const responseData = await response.json();
  
      if (!responseData.url) {
        throw new Error('No checkout URL returned');
      }
  
      // Redirect to Stripe checkout URL
      window.location.href = responseData.url;
    } catch (err) {
      console.error('Enrollment error:', err);
      setError(err.message || 'Failed to start enrollment. Please try again.');
    } finally {
      setEnrollingTrackId(null);
    }
  };

  const calculateTrackProgress = (track) => {
    const allModules = track.courses.flatMap(course => course.modules || []);
    if (allModules.length === 0) return 0;
    const completedModules = allModules.filter(module => module.status === 'Complete').length;
    return Math.round((completedModules / allModules.length) * 100);
  };

  const getCompletionDate = (track) => {
    // Get the latest updated_at date from all modules across all courses
    const allModules = track.courses.flatMap(course => course.modules || []);
    if (allModules.length === 0) return null;
    
    const latestDate = allModules.reduce((latest, module) => {
      const moduleDate = new Date(module.updated_at);
      return moduleDate > latest ? moduleDate : latest;
    }, new Date(0));
    
    return latestDate;
  };

  // Helper function to get image path for a track
  const getTrackImage = (trackTitle) => {
    const imageMap = {
      "Data Engineering": "/images/engineering.jpg",
      "Data Analytics": "/images/analytics.jpg",
      "Data Governance": "/images/governance.jpg"
    };
    return imageMap[trackTitle] || "/images/default.jpg";
  };

  const handleModuleClick = (module, isEnrolled) => {
    if (!isEnrolled) return;
    
    navigate(`/module/${module.id}`, {
      state: { module }
    });
  };

  const TrackOverview = ({ track, isEnrolled }) => {
    const completionDate = getCompletionDate(track);
    const isCompleted = calculateTrackProgress(track) === 100;
  
    return (
      <div className="py-4">
        <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl 
          dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/3 p-2">
              <CDNImage
                src={getTrackImage(track.name)}
                alt={`${track.name} Track`}
                className="rounded-xl w-full h-auto transform transition-transform hover:scale-105"
                fallbackSrc={getTrackImage(track.name).replace('/images/', '/')}
              />
            </div>
            <div className="md:w-2/3 p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                    {track.name}
                  </h3>
                  {isEnrolled && (
                    <span 
                      className="px-3 py-1 rounded-full text-white text-sm mt-2 inline-block"
                      style={{ 
                        backgroundColor: styles.accent.color,
                        backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                      }}
                    >
                      {calculateTrackProgress(track)}% Complete
                    </span>
                  )}
                </div>
                {isEnrolled && (
                  <div className="space-y-1 text-right">
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      Enrolled: {new Date(enrollments.find(e => e.track_id === track.id)?.enrollment_date).toLocaleDateString()}
                    </div>
                    {isCompleted && completionDate && (
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        Completed: {completionDate.toLocaleDateString()}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <p className="text-lg text-gray-700 dark:text-white/80 mb-6">{track.description}</p>
              
              {isEnrolled ? (
                <div className="space-y-4">
                  <div className="h-2 bg-gray-200 dark:bg-dark-primary rounded-full">
                    <div 
                      className="h-full rounded-full transition-all duration-500"
                      style={{ 
                        width: `${calculateTrackProgress(track)}%`,
                        backgroundColor: styles.accent.color
                      }}
                    />
                  </div>
                  <div className="flex justify-between items-center">
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {track.courses.flatMap(course => course.modules || []).filter(module => module.status === 'Complete').length} of {track.courses.flatMap(course => course.modules || []).length} modules completed
                  </div>
                    <div className="flex gap-4">
                      {isCompleted && (
                        <button
                          onClick={() => navigate('/certificate', {
                            state: {
                              trackName: track.name,
                              completionDate: getCompletionDate(track).toLocaleDateString()
                            }
                          })}
                          className="inline-block px-6 py-3 text-white rounded-lg transition-all 
                            transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                          style={{ 
                            backgroundColor: styles.accent.color,
                            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                          }}
                        >
                          View Certificate
                        </button>
                      )}
                      <button
                        onClick={() => setSelectedTrack(track)}
                        className="inline-block px-6 py-3 text-white rounded-lg transition-all 
                          transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                        style={{ 
                          backgroundColor: styles.accent.color,
                          backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                        }}
                      >
                        View Details
                        <ChevronRight size={16} className="inline-block ml-2" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex justify-end gap-4">
                  <button
                    onClick={() => setSelectedTrack(track)}
                    className="flex items-center gap-2 px-4 py-2 rounded-lg text-gray-700 dark:text-white transition-opacity hover:opacity-90 border border-gray-300 dark:border-dark-primary-light"
                  >
                    Learn More
                    <ChevronRight size={16} />
                  </button>
                  <button
                    onClick={() => handleEnroll(track.id)}
                    disabled={enrollingTrackId === track.id}
                    className="inline-block px-6 py-3 text-white rounded-lg transition-all 
                      transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30 disabled:opacity-50"
                    style={{ 
                      backgroundColor: styles.accent.color,
                      backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                    }}
                  >
                    {enrollingTrackId === track.id ? 'Enrolling...' : 'Enroll Now'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TrackDetail = ({ track, onBack }) => {
    const isEnrolled = enrolledTrackIds.has(track.id);

    return (
      <div className="py-16 bg-gray-50 dark:bg-dark-primary/90 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4">
          <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl 
            dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
            <div className="p-8">
              <button
                onClick={onBack}
                className="flex items-center gap-2 text-gray-700 dark:text-white mb-4 hover:opacity-90 transition-opacity"
              >
                <ArrowLeft size={20} />
                Back to Overview
              </button>
              
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                  {track.name}
                </h2>
                {isEnrolled ? (
                  <span 
                    className="px-3 py-1 rounded-full text-white text-sm"
                    style={{ 
                      backgroundColor: styles.accent.color,
                      backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                    }}
                  >
                    {calculateTrackProgress(track)}% Complete
                  </span>
                ) : (
                  <button
                    onClick={() => handleEnroll(track.id)}
                    disabled={enrollingTrackId === track.id}
                    className="inline-block px-6 py-3 text-white rounded-lg transition-all 
                      transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30 disabled:opacity-50"
                    style={{ 
                      backgroundColor: styles.accent.color,
                      backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                    }}
                  >
                    {enrollingTrackId === track.id ? 'Enrolling...' : 'Enroll Now'}
                  </button>
                )}
              </div>
              <p className="text-lg text-gray-700 dark:text-white/80 mb-8">{track.description}</p>

              <div className="space-y-4">
                {track.courses.map((course) => (
                  <div key={course.id} className="bg-gray-50 dark:bg-dark-primary rounded-lg p-6">
                    <div className="flex items-start gap-4">
                    <div className="hidden md:flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-dark-primary-light flex-shrink-0">
                        {course.status === 'Complete' ? (
                          <CheckCircle size={24} className="text-green-500" />
                        ) : (
                          <Clock size={24} className="text-gray-400 dark:text-gray-500" />
                        )}
                      </div>
                      <div className="flex-grow">
                        <div className="flex justify-between items-center mb-2">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {course.sequence}. {course.name}
                          </h3>
                          <span 
                            className={`px-3 py-1 rounded-full text-sm ${
                              course.status === 'Complete' 
                                ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300' 
                                : 'bg-gray-100 text-gray-800 dark:bg-dark-primary-light dark:text-gray-300'
                            }`}
                          >
                            {course.status}
                          </span>
                        </div>
                        <p className="text-gray-600 dark:text-gray-300 mb-4">{course.description}</p>
                        
                        {course.modules && course.modules.length > 0 && (
                          <div className="space-y-3 mt-4">
                            <div className="text-sm font-medium text-gray-700 dark:text-gray-300">Modules:</div>
                            {course.modules.map((module) => (
                              <div 
                                key={module.id} 
                                className={`flex items-start gap-3 pl-4 ${
                                  isEnrolled ? 'cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-secondary rounded p-2' : ''
                                }`}
                                onClick={() => handleModuleClick(module, isEnrolled)}
                              >
                                <BookOpen size={16} className="flex-shrink-0 mt-1 text-gray-400 dark:text-gray-500" />
                                <div className="flex-grow">
                                  <div className="flex justify-between items-center">
                                    <span className="text-sm text-gray-700 dark:text-gray-300">
                                      {module.sequence}. {module.name}
                                    </span>
                                    <span className={`text-sm ${
                                      module.status === 'Complete' 
                                        ? 'text-green-600 dark:text-green-400' 
                                        : module.status === 'In Progress'
                                          ? 'text-orange-700 dark:text-orange-600'
                                          : 'text-gray-500 dark:text-gray-400'
                                    }`}>
                                      {module.status}
                                    </span>
                                  </div>
                                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 whitespace-pre-line">
                                    {module.description}
                                  </p>
                                </div>
                                {isEnrolled && (
                                  <ChevronRight size={16} className="flex-shrink-0 mt-1 text-gray-400 dark:text-gray-500" />
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Get enrolled track IDs
  const enrolledTrackIds = new Set(enrollments.map(e => e.track_id));
  
  // Split tracks into enrolled and unenrolled
  const enrolledTracks = tracks.filter(track => enrolledTrackIds.has(track.id));
  const unenrolledTracks = tracks.filter(track => !enrolledTrackIds.has(track.id));

  // If loading, show loading state
  if (loading) {
    return <LoadingState styles={styles} />;
  }

  // If there's an error, show error state
  if (error) {
    return <ErrorState error={error} styles={styles} />;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      <div>
        {selectedTrack ? (
          <TrackDetail 
            track={selectedTrack} 
            onBack={() => setSelectedTrack(null)} 
          />
        ) : (
          <div className="max-w-7xl mx-auto px-4 py-16">
            {enrolledTracks.length > 0 && (
              <div>
                <h2 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
                  Your Enrolled Tracks
                </h2>
                <div>
                  {enrolledTracks.map(track => (
                    <TrackOverview key={track.id} track={track} isEnrolled={true} />
                  ))}
                </div>
              </div>
            )}
            
            {unenrolledTracks.length > 0 && (
              <div>
                <h2 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
                  Available Tracks
                </h2>
                <div>
                  {unenrolledTracks.map(track => (
                    <TrackOverview key={track.id} track={track} isEnrolled={false} />
                  ))}
                </div>
              </div>
            )}

            {/* No tracks message */}
            {enrolledTracks.length === 0 && unenrolledTracks.length === 0 && (
              <div className="text-center py-16 bg-white dark:bg-dark-primary-light rounded-lg shadow-xl">
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                  No Tracks Available
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-6">
                  Check back later or contact support for more information.
                </p>
                <button
                  onClick={() => navigate('/tracks')}
                  className="px-6 py-3 rounded-lg text-white transition-all hover:opacity-90"
                  style={{ 
                    backgroundColor: styles.accent.color,
                    backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                  }}
                >
                  Explore Tracks
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProgressDashboard;