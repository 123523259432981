import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer
} from 'recharts';
import { Loader2, TrendingUp, Users, DollarSign, Award } from 'lucide-react';
import { themeStyles } from '../SharedComponents';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';
import { API_URL } from '../../config/api';

const StatisticsDashboard = () => {
  const [overviewStats, setOverviewStats] = useState(null);
  const [enrollmentTrends, setEnrollmentTrends] = useState([]);
  const [engagementMetrics, setEngagementMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAuthHeaders } = useAuth();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const COLORS = [
    styles.accent.color,
    styles.primary.color,
    styles.secondary.color,
    styles.dark.color
  ];

  const fetchJSON = async (response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();
    try {
      return text ? JSON.parse(text) : {};
    } catch (e) {
      console.error('JSON Parse error:', e, 'Response text:', text);
      throw new Error('Invalid JSON response from server');
    }
  };

  useEffect(() => {
    let mounted = true;
    
    const fetchData = async () => {
      try {
        const headers = await getAuthHeaders();
        
        const [overviewResponse, enrollmentsResponse, engagementResponse] = await Promise.all([
          fetch(`${API_URL}/admin/statistics/overview`, { headers }),
          fetch(`${API_URL}/admin/statistics/enrollments?period=monthly`, { headers }),
          fetch(`${API_URL}/admin/statistics/engagement`, { headers })
        ]);
  
        const [overview, enrollments, engagement] = await Promise.all([
          fetchJSON(overviewResponse),
          fetchJSON(enrollmentsResponse),
          fetchJSON(engagementResponse)
        ]);
  
        if (!mounted) return;
  
        setOverviewStats(overview || {});
        setEnrollmentTrends(Array.isArray(enrollments) ? enrollments : []);
        setEngagementMetrics(engagement || {
          dropoutPoints: [],
          completionTime: []
        });
      } catch (err) {
        if (!mounted) return;
        console.error('Statistics error:', err);
        setError(err.message);
      } finally {
        if (!mounted) return;
        setLoading(false);
      }
    };
  
    fetchData();
    return () => { mounted = false; };
  }, [getAuthHeaders]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="w-8 h-8 animate-spin text-gray-400 dark:text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 
        rounded-lg p-4 text-red-700 dark:text-red-300 transition-colors duration-200">
        Error loading statistics: {error}
      </div>
    );
  }

  const overviewCards = [
    {
      title: 'Total Trainees',
      value: overviewStats?.totalTrainees || 0,
      icon: Users,
      gradient: `linear-gradient(135deg, ${styles.primary.color}, ${styles.secondary.color})`
    },
    {
      title: 'Active Trainees',
      value: overviewStats?.activeTrainees || 0,
      icon: TrendingUp,
      gradient: `linear-gradient(135deg, ${styles.secondary.color}, ${styles.dark.color})`
    },
    {
      title: 'Total Revenue',
      value: `$${(overviewStats?.totalRevenue || 0).toLocaleString()}`,
      icon: DollarSign,
      gradient: `linear-gradient(135deg, ${styles.accent.color}, ${styles.lightAccent.color})`
    },
    {
      title: 'Completion Rate',
      value: `${overviewStats?.completionStats?.find(s => s.status === 'Complete')?.count || 0}%`,
      icon: Award,
      gradient: `linear-gradient(135deg, ${styles.primary.color}, ${styles.accent.color})`
    }
  ];

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
        Platform Statistics
      </h1>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {overviewCards.map((card, index) => (
          <div
            key={index}
            className="bg-white dark:bg-dark-primary-light rounded-xl shadow-xl 
              dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200"
          >
            <div className="p-6">
              <div className="flex items-center gap-4">
                <div 
                  className="p-3 rounded-lg"
                  style={{ background: card.gradient }}
                >
                  <card.icon className="h-6 w-6 text-white" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 transition-colors duration-200">
                    {card.title}
                  </h3>
                  <p className="text-2xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
                    {card.value}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Enrollment Trends */}
      {enrollmentTrends && enrollmentTrends.length > 0 && (
        <div className="bg-white dark:bg-dark-primary-light rounded-xl shadow-xl 
          dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
          <div className="p-6">
            <h2 className="text-xl font-bold mb-6 text-gray-900 dark:text-white transition-colors duration-200">
              Enrollment Trends
            </h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={enrollmentTrends}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke={effectiveTheme === 'dark' ? '#374151' : '#E5E7EB'}
                  />
                  <XAxis 
                    dataKey="period" 
                    stroke={effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'}
                    tick={{ fill: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827' }}
                  />
                  <YAxis 
                    yAxisId="left"
                    stroke={effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'}
                    tick={{ fill: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827' }}
                  />
                  <YAxis 
                    yAxisId="right" 
                    orientation="right"
                    stroke={effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'}
                    tick={{ fill: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827' }}
                  />
                  <Tooltip 
                    contentStyle={{
                      backgroundColor: effectiveTheme === 'dark' ? '#1F2937' : '#FFFFFF',
                      borderColor: effectiveTheme === 'dark' ? '#374151' : '#E5E7EB',
                      color: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'
                    }}
                  />
                  <Legend 
                    formatter={(value, entry) => (
                      <span style={{ color: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827' }}>
                        {value}
                      </span>
                    )}
                  />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="count"
                    stroke={styles.primary.color}
                    name="Enrollments"
                    strokeWidth={2}
                    dot={{
                      fill: styles.primary.color,
                      r: 4
                    }}
                    activeDot={{
                      r: 6,
                      stroke: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827',
                      strokeWidth: 2
                    }}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="revenue"
                    stroke={styles.accent.color}
                    name="Revenue"
                    strokeWidth={2}
                    dot={{
                      fill: styles.accent.color,
                      r: 4
                    }}
                    activeDot={{
                      r: 6,
                      stroke: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827',
                      strokeWidth: 2
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}

      {/* Dropout Analysis */}
      {engagementMetrics?.dropoutPoints && engagementMetrics.dropoutPoints.length > 0 && (
        <div className="bg-white dark:bg-dark-primary-light rounded-xl shadow-xl 
          dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
          <div className="p-6">
            <h2 className="text-xl font-bold mb-6 text-gray-900 dark:text-white transition-colors duration-200">
              Dropout Points Analysis
            </h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={engagementMetrics.dropoutPoints}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke={effectiveTheme === 'dark' ? '#374151' : '#E5E7EB'}
                  />
                  <XAxis 
                    dataKey="name" 
                    stroke={effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'}
                    tick={{ fill: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827' }}
                  />
                  <YAxis 
                    stroke={effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'}
                    tick={{ fill: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827' }}
                  />
                  <Tooltip 
                    contentStyle={{
                      backgroundColor: effectiveTheme === 'dark' ? '#1F2937' : '#FFFFFF',
                      borderColor: effectiveTheme === 'dark' ? '#374151' : '#E5E7EB',
                      color: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'
                    }}
                    formatter={(value, name) => [value, 'Dropouts']}
                  />
                  <Legend 
                    wrapperStyle={{
                      color: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="dropoutCount"
                    name="Dropouts"
                    stroke={styles.accent.color}
                    strokeWidth={2}
                    dot={{
                      fill: styles.accent.color,
                      r: 6
                    }}
                    activeDot={{
                      r: 8,
                      fill: styles.accent.color,
                      stroke: effectiveTheme === 'dark' ? '#FFFFFF' : '#111827',
                      strokeWidth: 2
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatisticsDashboard;