import React from 'react';
import { Edit, Trash2, Grip } from 'lucide-react';
import { getContentTypeIcon } from './contentUtils';
import ContentPreview from './ContentPreview';

const ContentTable = ({ 
  content, 
  isReorderMode, 
  onEdit, 
  onDelete, 
  dragHandlers 
}) => {
  const { handleDragStart, handleDragEnter, handleDragEnd, handleDragLeave } = dragHandlers;

  return (
    <div className="bg-white dark:bg-dark-primary-light rounded-lg shadow-xl dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-white/10">
        <thead className="bg-gray-50 dark:bg-dark-primary transition-colors duration-200">
          <tr>
            {isReorderMode && <th scope="col" className="w-12"></th>}
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
              Sequence
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
              Type
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
              Content Preview
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-dark-primary-light divide-y divide-gray-200 dark:divide-white/10">
          {content.map((item, index) => (
            <tr 
              key={item.id}
              draggable={isReorderMode}
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnter={(e) => handleDragEnter(e, index)}
              onDragEnd={handleDragEnd}
              onDragLeave={handleDragLeave}
              className={`transition-colors duration-200 ${isReorderMode ? 'cursor-move' : ''}`}
            >
              {isReorderMode && (
                <td className="pl-2 pr-0 w-12">
                  <Grip className="text-gray-400 dark:text-gray-500" size={16} />
                </td>
              )}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                {item.sequence}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                <div className="flex items-center gap-2">
                  {getContentTypeIcon(item.content_type)}
                  {item.content_type}
                </div>
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
                <ContentPreview content={item} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className="flex gap-2">
                  <button
                    onClick={() => onEdit(item)}
                    className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 transition-colors duration-200"
                  >
                    <Edit size={16} />
                  </button>
                  <button
                    onClick={() => onDelete(item.id)}
                    className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300 transition-colors duration-200"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContentTable;