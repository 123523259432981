import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useContentManager } from '../../hooks/useContentManager';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';
import { useDragSort } from '../../hooks/useDragSort';
import { themeStyles } from '../SharedComponents';
import ContentHeader from './content/ContentHeader';
import ContentTable from './content/ContentTable';
import ContentFormModal from './content/ContentFormModal';

const ContentManager = () => {
  const { sectionId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const parentState = location.state;
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const {
    content,
    loading,
    error,
    editingContent,
    isModalOpen,
    isReorderMode,
    selectedContentType,
    setEditingContent,
    setIsModalOpen,
    setIsReorderMode,
    setSelectedContentType,
    handleSubmit,
    handleDelete,
    handleReorder
  } = useContentManager(sectionId);

  const { handleDragStart, handleDragEnter, handleDragEnd, handleDragLeave } = useDragSort(content, handleReorder);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="w-8 h-8 animate-spin text-white" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 
        rounded-lg p-4 text-red-700 dark:text-red-300 transition-colors duration-200">
        Error loading content: {error}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <ContentHeader
        onBack={() => navigate(`/my_admin_console/sections/${parentState?.moduleId}`, {
          state: parentState
        })}
        isReorderMode={isReorderMode}
        onToggleReorder={() => setIsReorderMode(!isReorderMode)}
        onAddContent={() => {
          setEditingContent(null);
          setSelectedContentType('text');
          setIsModalOpen(true);
        }}
        parentState={parentState}
        styles={styles}
      />

      <ContentTable
        content={content}
        isReorderMode={isReorderMode}
        onEdit={(item) => {
          setSelectedContentType(item.content_type);
          setEditingContent(item);
          setIsModalOpen(true);
        }}
        onDelete={handleDelete}
        dragHandlers={{
          handleDragStart,
          handleDragEnter,
          handleDragEnd,
          handleDragLeave
        }}
      />

      <ContentFormModal
        isOpen={isModalOpen}
        onClose={() => {
          console.log('Closing modal');
          setIsModalOpen(false);
          setEditingContent(null);
        }}
        onSubmit={(data) => {
          console.log('Submitting form data:', data); // Add logging
          handleSubmit(data);
        }}
        editingContent={editingContent || { section_id: sectionId }} 
        content={content}
        sectionId={sectionId}
        styles={styles}
      />
    </div>
  );
};

export default ContentManager;