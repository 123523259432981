import { useMemo } from 'react';
import { useTheme } from '../contexts/ThemeContext';

export const useEffectiveTheme = () => {
  const { theme } = useTheme();
  
  return useMemo(() => {
    if (theme === 'system') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    return theme;
  }, [theme]);
};