import React from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';

export const FormInput = ({ 
  icon: Icon, 
  label, 
  name, 
  type = 'text', 
  value, 
  onChange, 
  placeholder, 
  required = false 
}) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 dark:text-white/80">{label}</label>
    <div className="mt-1 relative">
      {Icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon className="h-5 w-5 text-gray-400 dark:text-white/50" />
        </div>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={`block w-full ${Icon ? 'pl-10' : 'px-3'} rounded-md border border-gray-300 dark:border-white/20 dark:bg-dark-primary-light px-3 py-2 shadow-sm 
          focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 
          dark:text-white dark:placeholder-white/50`}
        placeholder={placeholder}
        required={required}
      />
    </div>
  </div>
);

export const FormButton = ({ 
  text, 
  type = 'submit', 
  onClick, 
  disabled = false, 
  loading = false,
  style 
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled || loading}
    className="w-full flex justify-center py-2 px-4 rounded-lg text-white 
      shadow-sm hover:opacity-90 transition-all transform hover:scale-105 
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
      disabled:opacity-50"
    style={style}
  >
    {loading ? 'Processing...' : text}
  </button>
);

export const FormError = ({ message }) => (
  message ? (
    <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 rounded-lg p-4 flex items-center gap-3">
      <AlertCircle className="text-red-500 flex-shrink-0" />
      <span className="text-red-700 dark:text-red-300">{message}</span>
    </div>
  ) : null
);

export const FormSuccess = ({ message }) => (
  message ? (
    <div className="bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-900/50 rounded-lg p-4 flex items-center gap-3">
      <CheckCircle className="text-green-500 flex-shrink-0" />
      <span className="text-green-700 dark:text-green-300">{message}</span>
    </div>
  ) : null
);