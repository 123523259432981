import React from 'react';
import { X, Loader2 } from 'lucide-react';
import { themeStyles } from '../SharedComponents';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';

const AuditLogDetailsModal = ({ isOpen, onClose, logDetails, loading }) => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  if (!isOpen) return null;

  const getActionTypeStyles = (actionType) => {
    const baseClasses = "px-2 py-1 rounded-full text-xs font-medium transition-colors duration-200";
    switch (actionType) {
      case 'CREATE':
        return `${baseClasses} bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300`;
      case 'UPDATE':
        return `${baseClasses} bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300`;
      case 'DELETE':
        return `${baseClasses} bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300`;
      default:
        return `${baseClasses} bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300`;
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Dark overlay with blur effect */}
      <div 
        className="fixed inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm transition-colors duration-200" 
        onClick={onClose}
      />
      
      {/* Modal container */}
      <div className="relative bg-white dark:bg-dark-primary-light rounded-lg w-full max-w-3xl mx-4 max-h-[90vh] flex flex-col shadow-xl dark:shadow-dark-primary/30 transition-colors duration-200">
        {/* Modal header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-white/10 transition-colors duration-200">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white transition-colors duration-200">
            Audit Log Details
          </h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 dark:hover:bg-dark-primary rounded-full 
              text-gray-500 dark:text-gray-400 transition-colors duration-200"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Modal content */}
        <div className="p-4 overflow-y-auto flex-1">
          {loading ? (
            <div className="flex justify-center items-center h-32">
              <Loader2 className="h-8 w-8 animate-spin text-gray-400 dark:text-gray-500" />
            </div>
          ) : logDetails ? (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                    Administrator
                  </h3>
                  <p className="text-gray-900 dark:text-white/90 transition-colors duration-200">
                    {logDetails.administrator?.trainee?.name || 'N/A'}
                  </p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                    Action Type
                  </h3>
                  <p>
                    <span className={getActionTypeStyles(logDetails.action_type)}>
                      {logDetails.action_type}
                    </span>
                  </p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                    Table Name
                  </h3>
                  <p className="text-gray-900 dark:text-white/90 transition-colors duration-200">
                    {logDetails.table_name}
                  </p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                    Record ID
                  </h3>
                  <p className="font-mono text-sm break-all text-gray-900 dark:text-white/90 transition-colors duration-200">
                    {logDetails.record_id}
                  </p>
                </div>
                <div>
                  <h3 className="font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                    Date
                  </h3>
                  <p className="text-gray-900 dark:text-white/90 transition-colors duration-200">
                    {new Date(logDetails.created_at).toLocaleString()}
                  </p>
                </div>
              </div>

              <div>
                <h3 className="font-medium text-gray-700 dark:text-white/80 mb-2 transition-colors duration-200">
                  Changes
                </h3>
                <div className="bg-gray-50 dark:bg-dark-primary p-4 rounded-lg border border-gray-200 dark:border-white/10 transition-colors duration-200">
                  <pre className="whitespace-pre-wrap font-mono text-sm text-gray-700 dark:text-white/80 overflow-x-auto transition-colors duration-200">
                    {JSON.stringify(logDetails.changes, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500 dark:text-gray-400 transition-colors duration-200">
              No details available
            </div>
          )}
        </div>

        {/* Modal footer */}
        <div className="border-t border-gray-200 dark:border-white/10 p-4 transition-colors duration-200">
          <button
            onClick={onClose}
            className="w-full px-6 py-3 text-white rounded-lg transition-all 
              transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuditLogDetailsModal;