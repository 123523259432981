// src/components/custom-nodes/CTENode.js
import React from 'react';
import { Handle, Position } from 'reactflow';

const CTENode = ({ data }) => {
  return (
    <div style={{
      padding: '10px 15px',
      borderRadius: '5px',
      background: data.isRecursive ? '#f5f0ff' : '#f5f3ff',
      border: data.isRecursive ? '2px solid #c4b5fd' : '1px solid #ddd6fe',
      minWidth: '180px',
      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
    }}>
      <Handle type="target" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
      
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
          <span style={{ fontWeight: '500', color: '#6d28d9' }}>
            {data.label}
            {data.isRecursive && 
              <span style={{ 
                marginLeft: '5px', 
                fontSize: '11px', 
                background: '#7c3aed', 
                color: 'white', 
                padding: '2px 4px', 
                borderRadius: '4px'
              }}>
                RECURSIVE
              </span>
            }
          </span>
        </div>
        
        {/* Source section - combining tables, referenced CTEs, and subqueries */}
        <div style={{ fontSize: '12px', color: '#7e22ce', marginTop: '4px' }}>
          <details>
            <summary style={{ cursor: 'pointer' }}>
              Sources: {(data.sourceTables?.length || 0) + 
                        ((data.referencedCTEs?.filter(cte => cte !== data.label)?.length) || 0) + 
                        (data.referencedSubqueries?.length || 0)}
              {data.isRecursive && ' + self'}
            </summary>
            <div style={{ paddingLeft: '12px', marginTop: '4px' }}>
              {/* Display table sources */}
              {data.sourceTables && data.sourceTables.length > 0 && (
                <>
                  <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2px' }}>Tables:</div>
                  {data.sourceTables.map((table, i) => (
                    <div key={`table-${i}`} style={{ fontSize: '12px' }}>
                      {table}
                    </div>
                  ))}
                </>
              )}
              
              {/* Display CTE sources */}
              {data.referencedCTEs && data.referencedCTEs.length > 0 && (
                <>
                  <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2px' }}>CTEs:</div>
                  {data.referencedCTEs.map((cte, i) => (
                    <div key={`cte-${i}`} style={{ fontSize: '12px' }}>
                      {cte} {cte === data.label && '(self)'}
                    </div>
                  ))}
                </>
              )}
              
              {/* Display Subquery sources */}
              {data.referencedSubqueries && data.referencedSubqueries.length > 0 && (
                <>
                  <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2px' }}>Subqueries:</div>
                  {data.referencedSubqueries.map((sq, i) => (
                    <div key={`sq-${i}`} style={{ fontSize: '12px' }}>
                      {sq}
                    </div>
                  ))}
                </>
              )}
            </div>
          </details>
        </div>
      </div>
    </div>
  );
};

export default CTENode;