// src/components/EnterpriseMatrix.js
import React, { useEffect, useRef } from 'react';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { themeStyles } from './SharedComponents';
import { Database, Network } from 'lucide-react';

const EnterpriseMatrix = ({ data }) => {
  const { facts, dimensions, bridges = [] } = data;
  const matrixRef = useRef(null);
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const getTableTypeIcon = (tableType, isDarkBackground) => {
    // Set color based on background
    const iconColor = isDarkBackground ? "text-white" : "text-gray-900 dark:text-white";
    
    switch(tableType) {
      case 'bridge':
        return <Network size={16} className={`mr-1 ${iconColor}`} />;
      case 'fact':
        return <Database size={16} className={`mr-1 ${iconColor}`} />;
      default:
        return null;
    }
  };
  
  useEffect(() => {
    if (!matrixRef.current) return;
    
    const tableElement = matrixRef.current.querySelector('table');
    if (!tableElement) return;

    // Force fixed table layout and explicit total width
    tableElement.style.tableLayout = 'fixed';
    tableElement.style.width = `${350 + (dimensions.length * 60)}px`;
  }, [dimensions.length]);

  // Helper function to get status styles
  const getStatusStyles = (status) => {
    if (!status) return { backgroundColor: '', classes: '' };

    // Map of status to style objects containing both Tailwind classes and inline styles
    const statusMap = {
      'complete': {
        backgroundColor: styles.accent.color,
        classes: 'text-white' 
      },
      'in-progress': {
        backgroundColor: styles.primary.color,
        classes: 'text-white'
      },
      'issues': {
        backgroundColor: '#b33a3a',
        classes: 'text-white'
      },
      'not-started': {
        backgroundColor: '',
        classes: 'text-gray-700 dark:text-white border border-gray-300 dark:border-gray-700'
      }
    };

    return statusMap[status.toLowerCase()] || { backgroundColor: '', classes: '' };
  };

  const allFactsAndBridges = [
    ...facts,
    ...(bridges || [])
  ].sort((a, b) => (a.sequence || 0) - (b.sequence || 0));

  return (
    <div className="w-full overflow-x-auto" ref={matrixRef}>
      <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white transition-colors duration-200">
        Enterprise Bus Matrix
      </h2>
      
      {/* Status Legend */}
      <div className="flex flex-wrap gap-5 mb-5">
        <div className="flex items-center">
          <div className={`w-4 h-4 mr-2 rounded-sm ${styles.accent.className}`}></div>
          <span className="text-sm text-gray-700 dark:text-white/80 transition-colors duration-200">Complete</span>
        </div>
        <div className="flex items-center">
          <div className={`w-4 h-4 mr-2 rounded-sm ${styles.primary.className}`}></div>
          <span className="text-sm text-gray-700 dark:text-white/80 transition-colors duration-200">In Progress</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 mr-2 rounded-sm border border-gray-300 dark:border-gray-700 bg-white dark:bg-dark-secondary"></div>
          <span className="text-sm text-gray-700 dark:text-white/80 transition-colors duration-200">Not Started</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 mr-2 rounded-sm bg-red-600 dark:bg-red-700"></div>
          <span className="text-sm text-gray-700 dark:text-white/80 transition-colors duration-200">Issues</span>
        </div>
        <div className="flex items-center">
          <Database className="w-4 h-4 mr-2 text-gray-900 dark:text-white" />
          <span className="text-sm text-gray-700 dark:text-white/80 transition-colors duration-200">Fact Table</span>
        </div>
        <div className="flex items-center">
          <Network className="w-4 h-4 mr-2 text-gray-900 dark:text-white" />
          <span className="text-sm text-gray-700 dark:text-white/80 transition-colors duration-200">Bridge Table</span>
        </div>
      </div>
      
      {/* Matrix Table with explicit overflow handling */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {/* First column */}
              <th 
                className="w-[350px] h-[250px] bg-gray-50 dark:bg-dark-primary border border-gray-200 dark:border-gray-700 font-bold"
              >
                {/* Empty header cell for table names */}
              </th>
              
              {/* Dimension Headers */}
              {dimensions.map((dim, index) => {
                const statusStyle = getStatusStyles(dim.status);
                
                return (
                  <th 
                    key={index} 
                    className={`w-[60px] p-0 relative font-bold border border-gray-200 dark:border-gray-700 transition-colors duration-200 ${statusStyle.classes} group`}
                    style={{ 
                      backgroundColor: statusStyle.backgroundColor 
                    }}
                  >
                    {/* Rotated header text */}
                    <div 
                      className="absolute whitespace-nowrap left-[30px] bottom-[0px] transform -rotate-90 origin-left flex items-center font-bold"
                    >
                      {dim.name}
                      {dim.description && (
                        <div className="relative inline-flex items-center ml-1 cursor-pointer">
                          <span className="opacity-70 text-xs">ⓘ</span>
                        </div>
                      )}
                    </div>

                    {/* Tooltip */}
                    {dim.description && (
                      <div className="absolute hidden group-hover:block right-[0px] top-1/2 -translate-y-1/2 bg-white dark:bg-dark-primary-light border border-gray-200 dark:border-gray-700 shadow-lg dark:shadow-dark-primary/50 p-2.5 rounded-md w-[250px] max-w-[300px] z-50 text-left">
                        <strong className="block mb-1 text-gray-900 dark:text-white text-sm">{dim.name}</strong>
                        <p className="text-gray-700 dark:text-white/80 text-xs leading-relaxed font-normal">{dim.description}</p>
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          
          <tbody>
            {allFactsAndBridges.map((item, factIndex) => {
              const statusStyle = getStatusStyles(item.status);
              // Determine if the background is dark (for icon color adjustment)
              const hasDarkBackground = ['complete', 'in-progress', 'issues'].includes(item.status?.toLowerCase());
              
              return (
                <tr key={factIndex}>
                  {/* Table Name Cell */}
                  <td 
                    className={`w-[350px] p-2 border border-gray-200 dark:border-gray-700 text-left pl-[10px] ${statusStyle.classes}`}
                    style={{ 
                      backgroundColor: statusStyle.backgroundColor 
                    }}
                  >
                    <div className="flex items-start">
                      {getTableTypeIcon(item.tableType, hasDarkBackground)}
                      <span className="font-medium">{item.name}</span>
                        
                        {/* Info Tooltip */}
                        {item.description && (
                          <div className="relative inline-flex items-center ml-1.5 cursor-pointer group">
                            <span className="opacity-70 text-xs">ⓘ</span>
                            
                            <div className="hidden group-hover:block absolute z-[1000] left-full top-0 bg-white dark:bg-dark-primary-light border border-gray-200 dark:border-gray-700 shadow-lg dark:shadow-dark-primary/50 p-2.5 rounded-md min-w-[200px] max-w-[300px] text-left">
                              <strong className="block mb-1 text-gray-900 dark:text-white text-sm">{item.name}</strong>
                              <p className="text-gray-700 dark:text-white/80 text-xs leading-relaxed">{item.description}</p>
                            </div>
                          </div>
                        )}
                        
                        {/* Linked Facts */}
                        {item.linkedFacts && item.linkedFacts.length > 0 && (
                          <div className="relative inline-flex items-center ml-2 cursor-pointer group">
                            <span className="font-bold text-base opacity-70">↔</span>
                            
                            <div className="hidden group-hover:block absolute z-[1000] left-full top-0 bg-white dark:bg-dark-primary-light border border-gray-200 dark:border-gray-700 shadow-lg dark:shadow-dark-primary/50 p-2.5 rounded-md min-w-[200px] text-left">
                              <strong className="block mb-1 text-gray-900 dark:text-white">Linked Facts:</strong>
                              {item.linkedFacts.map((linkedFact, idx) => (
                                <div key={idx} className="py-1 text-gray-900 dark:text-white text-xs whitespace-nowrap">
                                  • {linkedFact.name}
                                  {linkedFact.relationship && (
                                    <span className="italic text-xs opacity-80 ml-1">({linkedFact.relationship})</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    
                    {/* Relationship Cells */}
                    {dimensions.map((dim, dimIndex) => {
                      // Check dimension usage and roles
                      const dimensionUsage = item.dimensionRoles ? 
                        item.dimensionRoles.filter(dr => dr.dimension === dim.name) : [];
                      
                      // Fall back to old format if dimensionRoles is not defined
                      const isUsedLegacy = !item.dimensionRoles && 
                        item.dimensions && 
                        item.dimensions.includes(dim.name);
                      
                      const hasRelationship = dimensionUsage.length > 0 || isUsedLegacy;
                      
                      return (
                        <td 
                          key={dimIndex} 
                          className="w-[60px] p-2 border border-gray-200 dark:border-gray-700 text-center"
                        >
                          {hasRelationship && (
                            <div className="relative cursor-pointer group">
                              <span className="font-bold text-gray-900 dark:text-white">
                                {isUsedLegacy ? '1' : dimensionUsage.length}
                              </span>
                              
                              {!isUsedLegacy && dimensionUsage.length > 0 && (
                                <div className="hidden group-hover:block absolute z-[1000] top-full left-1/2 -translate-x-1/2 bg-white dark:bg-dark-primary-light border border-gray-200 dark:border-gray-700 shadow-lg dark:shadow-dark-primary/50 p-2.5 rounded-md min-w-[150px] text-left">
                                  <strong className="block mb-1 text-gray-900 dark:text-white">Dimension Roles:</strong>
                                  {dimensionUsage.map((usage, i) => (
                                    <div key={i} className="py-1 text-gray-900 dark:text-white text-xs whitespace-nowrap">
                                      • {usage.role || 'Default'}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              
              {/* Totals Row */}
              <tr>
                <td className="w-[350px] p-2 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white">
                  Usage Count
                </td>
                {dimensions.map((dim, dimIndex) => {
                  // Calculate total usage for this dimension across all facts and bridges
                  let totalRoles = 0;
                  [...facts, ...(bridges || [])].forEach(item => {
                    if (item.dimensionRoles) {
                      const roles = item.dimensionRoles.filter(dr => dr.dimension === dim.name);
                      totalRoles += roles.length;
                    } else if (item.dimensions && item.dimensions.includes(dim.name)) {
                      // Legacy support
                      totalRoles += 1;
                    }
                  });
                  
                  return (
                    <td 
                      key={dimIndex} 
                      className="w-[60px] p-2 border border-gray-200 dark:border-gray-700 text-center text-gray-900 dark:text-white"
                    >
                      {totalRoles}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default EnterpriseMatrix;