import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import Navigation from './Navigation';
import Footer from './Footer';
import { themeStyles } from './SharedComponents';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { API_URL } from '../config/api';
import { FormInput, FormButton, FormError, FormSuccess } from './FormElements';

const AccountManagement = () => {
  const { user } = useAuth();
  const [message, setMessage] = useState({ type: '', text: '' });
  const [loading, setLoading] = useState(false);
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const handlePasswordResetRequest = async () => {
    setLoading(true);
    setMessage({ type: '', text: '' });

    try {
      const response = await fetch(`${API_URL}/auth/request-password-reset`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.email })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to request password reset');
      }

      setMessage({
        type: 'success',
        text: 'Password reset link has been sent to your email'
      });
    } catch (error) {
      setMessage({
        type: 'error',
        text: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      
      <div className="py-16 bg-gray-50 dark:bg-dark-primary-light/30 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
              Account Settings
            </h1>

            <div className="bg-white dark:bg-dark-primary-light rounded-2xl shadow-xl dark:shadow-dark-primary/30 overflow-hidden">
              {/* Account Info Section */}
              <div className="p-8 space-y-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white transition-colors duration-200">
                    Account Information
                  </h3>
                  <div className="bg-gray-50 dark:bg-dark-primary-light/50 rounded-xl p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <FormInput
                        label="Name"
                        name="name"
                        value={user.name}
                        disabled={true}
                      />
                      <FormInput
                        label="Email"
                        name="email"
                        type="email"
                        value={user.email}
                        disabled={true}
                      />
                    </div>
                    <p className="mt-4 text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200">
                      Contact support to update account information
                    </p>
                  </div>
                </div>

                {/* Password Section */}
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white transition-colors duration-200">
                    Password & Security
                  </h3>
                  
                  {message.type === 'success' && (
                    <FormSuccess message={message.text} />
                  )}
                  
                  {message.type === 'error' && (
                    <FormError message={message.text} />
                  )}

                  <div className="bg-gray-50 dark:bg-dark-primary-light/50 rounded-xl p-6">
                    <FormButton
                      text={loading ? 'Sending...' : 'Reset Password'}
                      type="button"
                      onClick={handlePasswordResetRequest}
                      disabled={loading}
                      style={{ 
                        backgroundColor: styles.accent.color,
                        backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                      }}
                    />
                    <p className="mt-4 text-sm text-gray-600 dark:text-gray-400 transition-colors duration-200">
                      You'll receive an email with instructions to reset your password.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AccountManagement;