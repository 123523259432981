import React from 'react';
import QuizContentManager from '../QuizContentManager';

const ContentTypeFields = ({ type, editingContent }) => {
  console.log('ContentTypeFields Props:', {
    type,
    editingContent
  });
  const baseInputStyles = `mt-1 block w-full rounded-lg border-gray-300 dark:border-white/20 
    dark:bg-dark-primary shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
    text-gray-900 dark:text-white transition-colors duration-200`;

  const labelStyles = "block text-sm font-medium text-gray-700 dark:text-white/80 transition-colors duration-200";
  const helpTextStyles = "mt-1 text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200";

  switch (type) {
    case 'text':
      return (
        <div>
          <label className={labelStyles}>HTML Content</label>
          <textarea
            name="html"
            defaultValue={editingContent?.content?.html || ''}
            className={baseInputStyles}
            rows={6}
            required
          />
        </div>
      );

    case 'video':
      return (
        <div className="space-y-4">
          <div>
            <label className={labelStyles}>Video ID</label>
            <input
              type="text"
              name="videoId"
              defaultValue={editingContent?.content?.videoId || ''}
              className={baseInputStyles}
              required
            />
          </div>
          <div>
            <label className={labelStyles}>Hash</label>
            <input
              type="text"
              name="hash"
              defaultValue={editingContent?.content?.hash || ''}
              className={baseInputStyles}
              required
            />
          </div>
          <div>
            <label className={labelStyles}>Title</label>
            <input
              type="text"
              name="title"
              defaultValue={editingContent?.content?.title || ''}
              className={baseInputStyles}
              required
            />
          </div>
          <div>
            <label className={labelStyles}>Caption</label>
            <input
              type="text"
              name="caption"
              defaultValue={editingContent?.content?.caption || ''}
              className={baseInputStyles}
            />
          </div>
          <div>
            <label className={labelStyles}>Description</label>
            <textarea
              name="description"
              defaultValue={editingContent?.content?.description || ''}
              className={baseInputStyles}
              rows={3}
            />
          </div>
          <div>
            <label className={labelStyles}>Preview Image URL</label>
            <input
              type="url"
              name="previewImage"
              defaultValue={editingContent?.content?.previewImage || ''}
              className={baseInputStyles}
              placeholder="https://cdn.datatraininglab.com/images/your-preview-image.jpg"
            />
            <p className={helpTextStyles}>
              Optional: Provide a URL for the video preview image. If not provided, a placeholder will be used.
            </p>
          </div>
          <div>
            <label className={labelStyles}>
              Transcript (Optional)
              <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                Add a text transcript of the video content
              </span>
            </label>
            <textarea
              name="transcript"
              defaultValue={editingContent?.content?.transcript || ''}
              className={baseInputStyles}
              rows={6}
              placeholder="Enter video transcript here..."
            />
          </div>
        </div>
      );

      case 'code':
        return (
          <div className="space-y-4">
            <div>
              <label className={labelStyles}>Language</label>
              <select
                name="language"
                defaultValue={editingContent?.content?.language || 'javascript'}
                className={baseInputStyles}
                required
              >
                <option value="javascript">JavaScript</option>
                <option value="python">Python</option>
                <option value="sql">SQL</option>
                <option value="bash">Bash</option>
                <option value="typescript">TypeScript</option>
                <option value="java">Java</option>
                <option value="csharp">C#</option>
                <option value="php">PHP</option>
                <option value="html">HTML</option>
                <option value="css">CSS</option>
              </select>
            </div>
            <div>
              <label className={labelStyles}>Code Content</label>
              <textarea
                name="codeContent"
                defaultValue={editingContent?.content?.codeContent || ''}
                className={baseInputStyles}
                rows={10}
                required
              />
            </div>
            <div>
              <label className={labelStyles}>Caption (Optional)</label>
              <input
                type="text"
                name="caption"
                defaultValue={editingContent?.content?.caption || ''}
                className={baseInputStyles}
              />
            </div>
          </div>
        );

      case 'image':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Image URL</label>
              <input
                type="url"
                name="url"
                defaultValue={editingContent?.content?.url || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Alt Text</label>
              <input
                type="text"
                name="alt"
                defaultValue={editingContent?.content?.alt || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Caption</label>
              <input
                type="text"
                name="caption"
                defaultValue={editingContent?.content?.caption || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                defaultValue={editingContent?.content?.description || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                rows={3}
              />
            </div>
          </div>
        );
  
      case 'quiz':
        return (
          <>
            <input 
              type="hidden" 
              name="quiz" 
              id="quiz-data"
              value={JSON.stringify(editingContent?.content || { questions: [] })} 
            />
            <QuizContentManager 
              initialQuiz={editingContent?.content || { questions: [] }}
              onQuizUpdate={(quizData) => {
                const hiddenInput = document.getElementById('quiz-data');
                if (hiddenInput) {
                  hiddenInput.value = JSON.stringify(quizData);
                }
              }}
            />
          </>
        );
  
      case 'protected-pdf':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Filename</label>
              <input
                type="text"
                name="filename"
                defaultValue={editingContent?.content?.filename || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Display Text</label>
              <input
                type="text"
                name="text"
                defaultValue={editingContent?.content?.text || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          </div>
        );

      case 'link':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">URL</label>
              <input
                type="url"
                name="url"
                defaultValue={editingContent?.content?.url || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Display Text</label>
              <input
                type="text"
                name="text"
                defaultValue={editingContent?.content?.text || ''}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          </div>
        );
    
    default:
      return null;
  }
};

export default ContentTypeFields;