import React, { useState } from 'react';
import { Mail, User } from 'lucide-react';
import Navigation from './Navigation';
import { 
  ContentSection, 
  themeStyles 
} from './SharedComponents';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import Footer from './Footer';
import { API_URL } from '../config/api';
import { FormInput, FormButton, FormError, FormSuccess } from './FormElements';


const SignupInitiate = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const [formData, setFormData] = useState({
    email: '',
    name: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/auth/signup/initiate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to initiate signup');
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderSignupContent = () => {
    if (success) {
      return (
        <div className="space-y-4">
          <FormSuccess message={`Check Your Email! We've sent a verification link to ${formData.email}. Click the link in the email to complete your registration.`} />
          <div className="text-sm text-gray-500 dark:text-white/70">
            <p>Don't see the email? Check your spam folder.</p>
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <FormError message={error} />

        <form onSubmit={handleSubmit} className="space-y-6">
          <FormInput
            icon={User}
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your full name"
            required
          />

          <FormInput
            icon={Mail}
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="you@example.com"
            required
          />

          <FormButton
            text="Sign Up"
            loading={loading}
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          />
        </form>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />
      <ContentSection 
        title="Create Your Account" 
        content={[
          "Join Data Training Lab to start your learning journey. Create your account to access our comprehensive data education programs."
        ]}
      >
        {renderSignupContent()}
      </ContentSection>
      <Footer />
    </div>
  );
};

export default SignupInitiate;