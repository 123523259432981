import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { 
  HeroSection,
  GradientBreakSection, 
  ContentSection, 
  CTASection,
  themeStyles 
} from './components/SharedComponents';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';

const TermsOfUse = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#terms-details') {
      const element = document.getElementById('terms-details');
      if (element) {
        // Smooth scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const contentSections = [
    {
      title: "Introduction",
      content: [
        "Welcome to Data Training Lab. These Terms of Use govern your access to and use of the Data Training Lab platform, including any content, functionality, and services offered on or through datatraininglab.com (the \"Service\").",
        "Please read these Terms carefully before using the Service. By using the Service, you accept and agree to be bound by these Terms of Use. If you do not agree to these terms, you must not access or use the Service."
      ]
    },
    {
      title: "Eligibility",
      content: [
        "The Service is intended for users who are at least 16 years old. By using the Service, you represent and warrant that you are at least 16 years of age. If you are under 16, you may not use the Service."
      ]
    },
    {
      title: "Account Registration and Security",
      content: [
        "To access the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
        "You are responsible for safeguarding your account credentials and for any activities or actions under your account. You agree to notify us immediately of any unauthorized access to or use of your account."
      ]
    },
    {
      title: "Payments and Refunds",
      content: [
        "Access to the Service requires payment. All payments are processed securely through Stripe. Prices are listed in USD and are subject to change. You agree to pay all charges at the prices then in effect for your purchases.",
        "Refund requests will be handled on a case-by-case basis in accordance with our refund policy. Please contact our support team for refund inquiries."
      ]
    },
    {
      title: "Intellectual Property Rights",
      content: [
        "The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Data Training Lab and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
        "While you may download and retain PDF documentation provided at the completion of modules for your personal use, you may not share, distribute, modify, transmit, reuse, repost, or use any of our content for public or commercial purposes without written permission from Data Training Lab."
      ]
    },
    {
      title: "User Data and Privacy",
      content: [
        "We collect and store certain personal information, including your name, email address, and course progress data. This information is used solely for the purpose of providing and improving the Service.",
        "We do not share your personal information with third parties. We use cookies only for essential site functionality. You can request deletion of your account and associated data at any time, though data may persist in our backup systems."
      ]
    },
    {
      title: "Prohibited Uses",
      content: [
        "You may use the Service only for lawful purposes and in accordance with these Terms of Use. You agree not to:",
        "• Use the Service in any way that violates any applicable law or regulation",
        "• Share your account credentials with others or allow others to access your account",
        "• Attempt to copy, modify, create derivative works from, reverse engineer, or extract source code from the Service",
        "• Share, distribute, or resell any content from the Service",
        "• Use the Service to transmit malware or other malicious code"
      ]
    },
    {
      title: "Termination",
      content: [
        "We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason, including if you breach these Terms of Use.",
        "Upon termination, your right to use the Service will immediately cease. All provisions of these Terms which by their nature should survive termination shall survive termination."
      ]
    },
    {
      title: "Governing Law",
      content: [
        "These Terms shall be governed by and construed in accordance with the laws of New South Wales, Australia, without regard to its conflict of law provisions."
      ]
    },
    {
      title: "Changes to Terms",
      content: [
        "We reserve the right to modify or replace these Terms at any time. We will provide notice of any changes by posting the new Terms on this page and updating the \"Last Updated\" date.",
        "Your continued use of the Service after any such changes constitutes your acceptance of the new Terms."
      ]
    },
    {
      title: "Contact Information",
      content: [
        "If you have any questions about these Terms, please contact us using the Contact Us link below."
      ]
    }
  ];

  const breakSections = [
    "Understanding our commitment to providing a valuable learning experience",
    "Protecting our community and ensuring a safe learning environment",
    "Maintaining transparency and protecting user rights"
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary transition-colors duration-200">
      <Navigation />

      <HeroSection 
        title="Terms of Use"
        subtitle="Defining the guidelines for using our educational platform"
        ctaText="Learn More"
        ctaLink="#terms-details"
        styles={styles}
        imageSrc="/images/legal.png"
        fallbackSrc="/legal.png"
      />
      <div className='text-gray-700 dark:text-white/90 max-w-4xl mx-10'>Last Updated: 16 Mar 2024</div>

      {contentSections.slice(0, 2).map((section, index) => (
        <React.Fragment key={index}>
          {index === 0 && (
            <GradientBreakSection 
              text={breakSections[0]}
              styles={styles}
            />
          )}
          <div id="terms-details">
            <ContentSection {...section} />
          </div>
        </React.Fragment>
      ))}

      {contentSections.slice(2, 5).map((section, index) => (
        <React.Fragment key={index}>
          {index === 0 && (
            <GradientBreakSection 
              text={breakSections[1]}
              styles={styles}
            />
          )}
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      {contentSections.slice(5).map((section, index) => (
        <React.Fragment key={index}>
          {index === 0 && (
            <GradientBreakSection 
              text={breakSections[2]}
              styles={styles}
            />
          )}
          <ContentSection {...section} />
        </React.Fragment>
      ))}

      <CTASection 
        title="Ready to Start Your Journey?"
        subtitle="Join our community of data professionals and start building real-world experience today."
        ctaText="View Learning Tracks"
        styles={styles}
        to="/tracks"
      />

      <Footer />
    </div>
  );
};

export default TermsOfUse;