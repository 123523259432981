// src/components/EstimatesBreakdown.js
import React from 'react';
import { useEffectiveTheme } from '../hooks/useEffectiveTheme';
import { themeStyles } from './SharedComponents';

const EstimatesBreakdown = ({ data }) => {
  const { facts, dimensions, bridges } = data;
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];
  
  // Filter out facts and dimensions that don't have estimates
  const factsWithEstimates = facts.filter(fact => fact.estimates);
  const dimensionsWithEstimates = dimensions.filter(dim => dim.estimates);
  const bridgesWithEstimates = bridges.filter(bridge => bridge.estimates);
  
  // If there are no items with estimates, don't render the component
  if (factsWithEstimates.length === 0 && dimensionsWithEstimates.length === 0 && bridgesWithEstimates.length === 0) {
    return null;
  }
  
  // Calculate the grand totals for each work stage
  const grandTotals = {
    analysis: 0,
    modeling: 0,
    mapping: 0,
    buildTest: 0,
    total: 0
  };
  
  // Add up totals from facts
  [factsWithEstimates, dimensionsWithEstimates, bridgesWithEstimates].forEach(items => {
    items.forEach(item => {
      grandTotals.analysis += item.estimates.analysis || 0;
      grandTotals.modeling += item.estimates.modeling || 0;
      grandTotals.mapping += item.estimates.mapping || 0;
      grandTotals.buildTest += item.estimates.buildTest || 0;
      grandTotals.total += (item.estimates.analysis || 0) + (item.estimates.modeling || 0) + 
                         (item.estimates.mapping || 0) + (item.estimates.buildTest || 0);
    });
  });

  // Calculate usage count for each dimension from facts data
  const dimensionUsageCounts = {};
  dimensions.forEach(dim => {
    dimensionUsageCounts[dim.name] = 0;
  });

  // Count usage in facts and bridges
  [...facts, ...bridges].forEach(table => {
    if (table.dimensionRoles) {
      table.dimensionRoles.forEach(role => {
        if (dimensionUsageCounts[role.dimension] !== undefined) {
          dimensionUsageCounts[role.dimension]++;
        }
      });
    } else if (table.dimensions) {
      table.dimensions.forEach(dimName => {
        if (dimensionUsageCounts[dimName] !== undefined) {
          dimensionUsageCounts[dimName]++;
        }
      });
    }
  });

  // Sort dimensions by usage count (highest first)
  const sortedDimensions = [...dimensionsWithEstimates].sort((a, b) => 
    (dimensionUsageCounts[b.name] || 0) - (dimensionUsageCounts[a.name] || 0)
  );

  return (
    <div className="mt-10">
      <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white transition-colors duration-200">Estimates Breakdown</h2>
      
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="bg-gray-50 dark:bg-dark-primary font-semibold text-left p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                Component
              </th>
              <th className="bg-gray-50 dark:bg-dark-primary font-semibold text-center p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                Analysis
              </th>
              <th className="bg-gray-50 dark:bg-dark-primary font-semibold text-center p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                Modeling
              </th>
              <th className="bg-gray-50 dark:bg-dark-primary font-semibold text-center p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                Mapping
              </th>
              <th className="bg-gray-50 dark:bg-dark-primary font-semibold text-center p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                Build &amp; Test
              </th>
              <th className="bg-gray-50 dark:bg-dark-primary font-semibold text-center p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                Total
              </th>
            </tr>
          </thead>
          
          <tbody>
          {sortedDimensions.length > 0 && (
            <>
              <tr>
                <td colSpan={6} className="bg-gray-100 dark:bg-dark-primary-light font-semibold p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                  Dimensions
                </td>
              </tr>
              
              {sortedDimensions.map((dim, index) => (
                <tr key={`dim-${index}`}>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {dim.name} <span className="text-xs text-gray-500 dark:text-gray-400">({dimensionUsageCounts[dim.name] || 0})</span>
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {dim.estimates.analysis || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {dim.estimates.modeling || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {dim.estimates.mapping || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {dim.estimates.buildTest || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-medium bg-gray-50 dark:bg-dark-primary text-gray-700 dark:text-white/90 transition-colors duration-200">
                    {(dim.estimates.analysis || 0) + (dim.estimates.modeling || 0) + 
                      (dim.estimates.mapping || 0) + (dim.estimates.buildTest || 0)}
                  </td>
                </tr>
              ))}
            </>
          )}


            {factsWithEstimates.length > 0 && (
              <>
                <tr>
                  <td colSpan={6} className="bg-gray-100 dark:bg-dark-primary-light font-semibold p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                    Facts
                  </td>
                </tr>
                
                {factsWithEstimates.map((fact, index) => (
                  <tr key={`fact-${index}`}>
                    <td className="p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/80 transition-colors duration-200">
                      {fact.name}
                    </td>
                    <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                      {fact.estimates.analysis || 0}
                    </td>
                    <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                      {fact.estimates.modeling || 0}
                    </td>
                    <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                      {fact.estimates.mapping || 0}
                    </td>
                    <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                      {fact.estimates.buildTest || 0}
                    </td>
                    <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-medium bg-gray-50 dark:bg-dark-primary text-gray-700 dark:text-white/90 transition-colors duration-200">
                      {(fact.estimates.analysis || 0) + (fact.estimates.modeling || 0) + 
                        (fact.estimates.mapping || 0) + (fact.estimates.buildTest || 0)}
                    </td>
                  </tr>
                ))}
              </>
            )}

          {bridgesWithEstimates.length > 0 && (
            <>
              <tr>
                <td colSpan={6} className="bg-gray-100 dark:bg-dark-primary-light font-semibold p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/90 transition-colors duration-200">
                  Bridge Tables
                </td>
              </tr>
              
              {bridgesWithEstimates.map((bridge, index) => (
                <tr key={`bridge-${index}`}>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {bridge.name}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {bridge.estimates.analysis || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {bridge.estimates.modeling || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {bridge.estimates.mapping || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center text-gray-700 dark:text-white/80 transition-colors duration-200">
                    {bridge.estimates.buildTest || 0}
                  </td>
                  <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-medium bg-gray-50 dark:bg-dark-primary text-gray-700 dark:text-white/90 transition-colors duration-200">
                    {(bridge.estimates.analysis || 0) + (bridge.estimates.modeling || 0) + 
                      (bridge.estimates.mapping || 0) + (bridge.estimates.buildTest || 0)}
                  </td>
                </tr>
              ))}
            </>
          )}
            
            <tr>
              <td className="p-3 border border-gray-200 dark:border-gray-700 font-semibold bg-gray-100 dark:bg-dark-primary-light text-gray-700 dark:text-white/90 transition-colors duration-200">
                Grand Totals
              </td>
              <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-semibold bg-gray-100 dark:bg-dark-primary-light text-gray-700 dark:text-white/90 transition-colors duration-200">
                {grandTotals.analysis}
              </td>
              <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-semibold bg-gray-100 dark:bg-dark-primary-light text-gray-700 dark:text-white/90 transition-colors duration-200">
                {grandTotals.modeling}
              </td>
              <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-semibold bg-gray-100 dark:bg-dark-primary-light text-gray-700 dark:text-white/90 transition-colors duration-200">
                {grandTotals.mapping}
              </td>
              <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-semibold bg-gray-100 dark:bg-dark-primary-light text-gray-700 dark:text-white/90 transition-colors duration-200">
                {grandTotals.buildTest}
              </td>
              <td className="p-3 border border-gray-200 dark:border-gray-700 text-center font-bold bg-gray-200 dark:bg-dark-secondary text-gray-900 dark:text-white transition-colors duration-200">
                {grandTotals.total}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EstimatesBreakdown;