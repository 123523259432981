// src/components/custom-nodes/OutputNode.js
import React from 'react';
import { Handle, Position } from 'reactflow';

const OutputNode = ({ data }) => {
  return (
    <div style={{
      padding: '10px 15px',
      borderRadius: '5px',
      background: '#ecfdf5',
      border: '1px solid #a7f3d0',
      minWidth: '180px',
      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
    }}>
      <Handle type="target" position={Position.Left} />
      
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
          <span style={{ fontWeight: '500', color: '#065f46' }}>Output</span>
        </div>
        
        {data.columns && data.columns.length > 0 && (
          <div style={{ fontSize: '12px', color: '#047857', marginTop: '4px' }}>
            <details>
              <summary style={{ cursor: 'pointer' }}>Columns: {data.columns.length}</summary>
              <div style={{ paddingLeft: '12px', marginTop: '4px' }}>
                {data.columns.map((col, i) => (
                  <div key={i} style={{ fontSize: '12px' }}>{col}</div>
                ))}
              </div>
            </details>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutputNode;