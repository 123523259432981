import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { Plus, Edit, Trash2, X, Loader2 } from 'lucide-react';
import { themeStyles } from '../SharedComponents';
import { useEffectiveTheme } from '../../hooks/useEffectiveTheme';
import { API_URL } from '../../config/api';

const CourseManager = () => {
  const [courses, setCourses] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingCourse, setEditingCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const { getAuthHeaders } = useAuth();
  const navigate = useNavigate();
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  // Set hardcoded tracks
  useEffect(() => {
    setTracks([
      { id: 'dafd555b-f27d-40c5-b651-bc6b457f988e', name: 'Data Engineering Track' },
      { id: '038a33eb-29e6-4e1b-9499-369f9b1b9b35', name: 'Data Analytics Track' },
      { id: '561e90cd-b963-448a-9d89-ef535059a86e', name: 'Data Governance Track' }
    ]);
    setLoading(false);
  }, []);

  // Fetch courses for selected track
  useEffect(() => {
    const fetchCourses = async () => {
      if (!selectedTrack) {
        setCourses([]);
        return;
      }

      try {
        setLoading(true);
        const headers = await getAuthHeaders();
        const response = await fetch(`${API_URL}/admin/courses/track/${selectedTrack}`, { headers });
        const data = await response.json();
        setCourses(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [selectedTrack, getAuthHeaders]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const courseData = {
      name: formData.get('name'),
      description: formData.get('description'),
      sequence: parseInt(formData.get('sequence')),
      trackId: selectedTrack
    };

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(
        editingCourse ? `${API_URL}/admin/courses/${editingCourse.id}` : `${API_URL}/admin/courses`,
        {
          method: editingCourse ? 'PUT' : 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(courseData),
        }
      );

      if (!response.ok) throw new Error('Failed to save course');

      const updatedResponse = await fetch(`${API_URL}/admin/courses/track/${selectedTrack}`, { headers });
      const updatedData = await updatedResponse.json();
      setCourses(updatedData);
      
      setIsModalOpen(false);
      setEditingCourse(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDelete = async (courseId) => {
    if (!window.confirm('Are you sure you want to delete this course?')) return;

    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${API_URL}/admin/courses/${courseId}`, {
        method: 'DELETE',
        headers,
      });

      if (!response.ok) throw new Error('Failed to delete course');

      const updatedResponse = await fetch(`${API_URL}/admin/courses/track/${selectedTrack}`, { headers });
      const updatedData = await updatedResponse.json();
      setCourses(updatedData);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading && !tracks.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="w-8 h-8 animate-spin text-gray-400 dark:text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
          Course Management
        </h1>
        {selectedTrack && (
          <button
            onClick={() => {
              setEditingCourse(null);
              setIsModalOpen(true);
            }}
            className="flex items-center gap-2 px-6 py-3 text-white rounded-lg 
              transition-all transform hover:scale-105 hover:shadow-lg 
              dark:hover:shadow-dark-primary/30"
            style={{ 
              backgroundColor: styles.accent.color,
              backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
            }}
          >
            <Plus size={20} />
            Add Course
          </button>
        )}
      </div>

      {error && (
        <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-900/50 
          rounded-lg p-4 text-red-700 dark:text-red-300 transition-colors duration-200">
          {error}
        </div>
      )}

      {/* Track Selection */}
      <div className="bg-white dark:bg-dark-primary-light rounded-lg p-6 shadow-xl 
        dark:shadow-dark-primary/30 transition-colors duration-200">
        <label className="block text-sm font-medium text-gray-700 dark:text-white/80 mb-2 transition-colors duration-200">
          Select Track
        </label>
        <select
          value={selectedTrack || ''}
          onChange={(e) => setSelectedTrack(e.target.value)}
          className="w-full rounded-lg border-gray-300 dark:border-white/20 dark:bg-dark-primary 
            shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
            text-gray-900 dark:text-white transition-colors duration-200"
        >
          <option value="">Select a track...</option>
          {tracks.map((track) => (
            <option key={track.id} value={track.id}>
              {track.name}
            </option>
          ))}
        </select>
      </div>

      {/* Courses Table */}
      {selectedTrack && (
        <div className="bg-white dark:bg-dark-primary-light rounded-lg shadow-xl 
          dark:shadow-dark-primary/30 overflow-hidden transition-colors duration-200">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-white/10">
            <thead className="bg-gray-50 dark:bg-dark-primary transition-colors duration-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Sequence
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Modules
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-dark-primary-light divide-y divide-gray-200 dark:divide-white/10">
              {courses.map((course) => (
                <tr key={course.id} className="hover:bg-gray-50 dark:hover:bg-dark-primary/50 transition-colors duration-200">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {course.sequence}
                  </td>
                  <td 
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white 
                      cursor-pointer hover:text-gray-600 dark:hover:text-white/80 transition-colors duration-200" 
                    onClick={() => navigate(`/my_admin_console/modules/${course.id}`, {
                      state: { 
                        courseId: course.id,
                        courseName: course.name,
                        trackId: selectedTrack 
                      }
                    })}
                  >
                    {course.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {course.modules?.length || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setEditingCourse(course);
                          setIsModalOpen(true);
                        }}
                        className="text-indigo-600 hover:text-indigo-900 
                          dark:text-indigo-400 dark:hover:text-indigo-300 
                          transition-colors duration-200"
                      >
                        <Edit size={16} />
                      </button>
                      <button
                        onClick={() => handleDelete(course.id)}
                        className="text-red-600 hover:text-red-900 
                          dark:text-red-400 dark:hover:text-red-300 
                          transition-colors duration-200"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Course Form Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm 
          flex items-center justify-center p-4 z-50 transition-colors duration-200">
          <div className="bg-white dark:bg-dark-primary-light rounded-lg p-6 max-w-md w-full 
            shadow-xl dark:shadow-dark-primary/30 transition-colors duration-200">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white transition-colors duration-200">
                {editingCourse ? 'Edit Course' : 'Add Course'}
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setEditingCourse(null);
                }}
                className="text-gray-400 hover:text-gray-500 dark:text-gray-500 
                  dark:hover:text-gray-400 transition-colors duration-200"
              >
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  defaultValue={editingCourse?.name}
                  className="mt-1 block w-full rounded-lg border-gray-300 dark:border-white/20 
                    dark:bg-dark-primary shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                    text-gray-900 dark:text-white transition-colors duration-200"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                  Description
                </label>
                <textarea
                  name="description"
                  defaultValue={editingCourse?.description}
                  className="mt-1 block w-full rounded-lg border-gray-300 dark:border-white/20 
                    dark:bg-dark-primary shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                    text-gray-900 dark:text-white transition-colors duration-200"
                  rows={3}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-white/80 transition-colors duration-200">
                  Sequence
                </label>
                <input
                  type="number"
                  name="sequence"
                  defaultValue={editingCourse?.sequence ?? courses.length + 1}
                  className="mt-1 block w-full rounded-lg border-gray-300 dark:border-white/20 
                    dark:bg-dark-primary shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                    text-gray-900 dark:text-white transition-colors duration-200"
                  required
                />
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingCourse(null);
                  }}
                  className="px-4 py-2 text-gray-700 dark:text-white/90 bg-gray-100 
                    dark:bg-dark-primary rounded-lg hover:bg-gray-200 
                    dark:hover:bg-dark-primary-light transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-white rounded-lg transition-all 
                    transform hover:scale-105 hover:shadow-lg dark:hover:shadow-dark-primary/30"
                  style={{ 
                    backgroundColor: styles.accent.color,
                    backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
                  }}
                >
                  {editingCourse ? 'Save Changes' : 'Create Course'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseManager;