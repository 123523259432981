import React from 'react';
import { Link } from 'react-router-dom';
import CDNImage from './CDNImage';
import { Linkedin, ArrowUp } from 'lucide-react';
import { themeStyles } from './SharedComponents';
import ThemeSwitcher from './ThemeSwitcher';
import { useMemo } from 'react';
import { useTheme } from '../contexts/ThemeContext';

export const useEffectiveTheme = () => {
  const { theme } = useTheme();
  
  return useMemo(() => {
    if (theme === 'system') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    return theme;
  }, [theme]);
};

const Footer = () => {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const navLinkClasses = "text-gray-400 relative py-2 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-[#59a52c] after:transition-all after:duration-300";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="py-16" style={{ backgroundColor: styles.dark.color }}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8 mb-12">
            <div className="flex flex-col items-start md:col-span-1">
              <div className="flex items-center gap-4 mb-6">
                <CDNImage
                  src="/images/sphere-logo.png"
                  alt="Data Training Lab Logo"
                  className="h-10 w-10"
                  fallbackSrc="/sphere-logo.png"
                />
                <CDNImage
                  src="/images/transparent-name.png"
                  alt="Data Training Lab"
                  className="h-10 w-auto"
                  fallbackSrc="/transparent-name.png"
                />
              </div>
              <div className="flex gap-4">
                <a 
                  href="https://blog.datatraininglab.com" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24" height="24" fill="currentColor">
                  <path d="M15 12A13 13 0 1015 38 13 13 0 1015 12zM35.5 13c-3.59 0-6.5 5.373-6.5 12 0 1.243.102 2.441.292 3.568.253 1.503.662 2.879 1.192 4.065.265.593.56 1.138.881 1.627.642.978 1.388 1.733 2.202 2.201C34.178 36.811 34.827 37 35.5 37s1.322-.189 1.933-.539c.814-.468 1.56-1.223 2.202-2.201.321-.489.616-1.034.881-1.627.53-1.185.939-2.562 1.192-4.065C41.898 27.441 42 26.243 42 25 42 18.373 39.09 13 35.5 13zM45.5 14c-.259 0-.509.173-.743.495-.157.214-.307.494-.448.833-.071.169-.14.353-.206.551-.133.395-.257.846-.37 1.343-.226.995-.409 2.181-.536 3.497-.063.658-.112 1.349-.146 2.065C43.017 23.499 43 24.241 43 25s.017 1.501.051 2.217c.033.716.082 1.407.146 2.065.127 1.316.31 2.501.536 3.497.113.498.237.948.37 1.343.066.198.135.382.206.551.142.339.292.619.448.833C44.991 35.827 45.241 36 45.5 36c1.381 0 2.5-4.925 2.5-11S46.881 14 45.5 14z"/>
                </svg>
                </a>
                <a 
                  href="https://www.linkedin.com/company/data-training-lab" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24" height="24" fill="currentColor">
                  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"/>
                </svg>
                </a>
                
                <ThemeSwitcher />
              </div>
            </div>
            <div className="md:col-span-3 flex md:justify-end">
            <div className="grid grid-cols-3 gap-10">
              <div>
                <h3 className="text-white font-bold mb-4">About</h3>
                <ul className="space-y-2">
                  <li><Link to="/our-story" style={styles.link} className={navLinkClasses}>Our Story</Link></li>
                  <li><Link to="/our-platform" style={styles.link} className={navLinkClasses}>Our Platform</Link></li>
                </ul>
              </div>
              <div>
                <h3 className="text-white font-bold mb-4">Support</h3>
                <ul className="space-y-2">
                  <li><Link to="/help-center" style={styles.link} className={navLinkClasses}>Help Center</Link></li>
                  <li><Link to="/contact" style={styles.link} className={navLinkClasses}>Contact Us</Link></li>
                  <li><Link to="/hiring" style={styles.link} className={navLinkClasses}>Hiring</Link></li>
                </ul>
              </div>
              <div>
                <h3 className="text-white font-bold mb-4">Legal</h3>
                <ul className="space-y-2">
                  <li><Link to="/terms" style={styles.link} className={navLinkClasses}>Terms</Link></li>
                  <li><Link to="/cookies" style={styles.link} className={navLinkClasses}>Cookie Policy</Link></li>
                  <li><Link to="/privacy" style={styles.link} className={navLinkClasses}>Privacy</Link></li>
                </ul>
              </div>
            </div>
            </div>
          </div>
          <div className="border-t border-gray-800 pt-8">
            <p className="text-gray-400 text-center">
              © {new Date().getFullYear()} Data Training Lab. All rights reserved.
            </p>
          </div>
        </div>

        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-3 rounded-full shadow-lg 
            text-white transition-all transform hover:scale-105 
            hover:shadow-xl dark:hover:shadow-dark-primary/30"
          style={{ 
            backgroundColor: styles.accent.color,
            backgroundImage: `linear-gradient(to right, ${styles.accent.color}, ${styles.lightAccent.color})` 
          }}
          aria-label="Scroll to top"
        >
          <ArrowUp size={24} />
        </button>
      </footer>
  );
};

export default Footer;