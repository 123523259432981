import React from 'react';
import { FileQuestion, Link, FileText } from 'lucide-react';

const getLanguageDisplayName = (language) => {
  const displayNames = {
    javascript: 'JavaScript',
    python: 'Python',
    sql: 'SQL',
    bash: 'Bash',
    typescript: 'TypeScript',
    java: 'Java',
    csharp: 'C#',
    php: 'PHP',
    html: 'HTML',
    css: 'CSS',
    md: 'Markdown',
    json: 'JSON',
    yaml: 'YAML',
    txt: 'Text'
  };
  return displayNames[language] || language;
};

const ContentPreview = ({ content }) => {
  const renderPreview = () => {
    switch (content.content_type) {
      case 'text':
        return (
          <div className="max-w-lg">
            <p className="truncate text-gray-700 dark:text-gray-300 transition-colors duration-200">
              {content.content.html.replace(/<[^>]*>/g, '')}
            </p>
          </div>
        );
      
      case 'video':
        return (
          <div className="flex flex-col">
            <span className="font-medium text-gray-900 dark:text-white transition-colors duration-200">
              {content.content.title}
            </span>
            {content.content.caption && (
              <span className="text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200">
                {content.content.caption}
              </span>
            )}
          </div>
        );
      case 'code':
        return (
          <div className="flex flex-col">
            <span className="font-medium text-gray-900 dark:text-white transition-colors duration-200">
              {getLanguageDisplayName(content.content.language)}
            </span>
            <span className="text-sm text-gray-700 dark:text-gray-300 font-mono whitespace-nowrap overflow-hidden text-ellipsis transition-colors duration-200">
              {(content.content.codeContent || '').substring(0, 50)}{content.content.codeContent?.length > 50 ? '...' : ''}
            </span>
            {content.content.caption && (
              <span className="text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200">
                {content.content.caption}
              </span>
            )}
          </div>
        );
        
      case 'image':
        return (
          <div className="flex flex-col">
            <span className="font-medium text-gray-900 dark:text-white transition-colors duration-200">
              {content.content.alt}
            </span>
            {content.content.caption && (
              <span className="text-sm text-gray-500 dark:text-gray-400 transition-colors duration-200">
                {content.content.caption}
              </span>
            )}
          </div>
        );
      
      case 'quiz':
        return (
          <div className="flex items-center gap-2">
            <FileQuestion size={16} className="text-gray-400 dark:text-gray-500" />
            <span className="text-gray-700 dark:text-gray-300 transition-colors duration-200">
              Quiz with {content.content.questions?.length || 0} questions
            </span>
          </div>
        );

      case 'protected-pdf':
        return (
          <div className="flex items-center gap-2">
            <FileText size={16} className="text-gray-400 dark:text-gray-500" />
            <span className="text-gray-700 dark:text-gray-300 transition-colors duration-200">
              {content.content.text}
            </span>
          </div>
        );

      case 'link':
        return (
          <div className="flex items-center gap-2">
            <Link size={16} className="text-gray-400 dark:text-gray-500" />
            <span className="text-gray-700 dark:text-gray-300 transition-colors duration-200">
              {content.content.text}
            </span>
          </div>
        );

      default:
        return (
          <span className="text-gray-500 dark:text-gray-400 italic transition-colors duration-200">
            No preview available
          </span>
        );
    }
  };

  return renderPreview();
};

export default ContentPreview;