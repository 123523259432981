import React from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useEffectiveTheme } from './hooks/useEffectiveTheme';
import PreviewCarousel from './components/PreviewCarousel';
import { 
  HeroSection, 
  GradientBreakSection, 
  CardGrid, 
  CTASection,
  FeatureSection,
  themeStyles
} from './components/SharedComponents';

function Home() {
  const effectiveTheme = useEffectiveTheme();
  const styles = themeStyles[effectiveTheme];

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Data Engineering Tech Lead",
      content: "The practical experience I gained through the Summit Stay Hotel projects was invaluable. I now lead data infrastructure initiatives at my company.",
    },
    {
      name: "Michael Chen",
      role: "Analytics Manager",
      content: "This program transformed my approach to data analytics. The real-world scenarios helped me develop practical solutions for complex problems.",
    },
    {
      name: "Emma Davis",
      role: "Data Governance Lead",
      content: "The governance track provided me with the perfect blend of technical knowledge and leadership skills needed in modern data management.",
    }
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark-primary 
                    transition-colors duration-200">
      <Navigation />
  
      <HeroSection 
        imageSrc="/images/dashboard.png"
        fallbackSrc="/dashboard.png"
        title="Training the next generation of data leaders through real-world experience"
        subtitle="The premier platform for hands-on data engineering, analytics, and governance education"
        styles={styles}
        ctaText='View Learning Tracks'
      />
  
      <GradientBreakSection 
        text="Bridging the gap between theory and practice in data education"
        styles={styles}
      />
  
      <FeatureSection 
        imageSrc="/images/engineering.jpg"
        fallbackSrc="/engineering.jpg"
        title="Learn Data Engineering"
        description="Build real data infrastructure and pipelines for Summit Stay Hotel. Work with actual APIs, implement ETL processes, and create scalable solutions for real business challenges."
        ctaText="Explore Engineering Track"
        styles={styles}
        ctaLink="/tracks#track-data-engineering"
      />

      <div className="hidden md:block">
        <PreviewCarousel />
      </div>
            
      <GradientBreakSection 
        text="Building the future of data education through immersive learning"
        styles={styles}
      />
  
      <FeatureSection 
        imageSrc="/images/analytics.jpg"
        fallbackSrc="/analytics.jpg"
        title="Master Data Analytics"
        description="Transform raw hotel data into actionable insights. Learn advanced analytics techniques while working with real-world datasets and business scenarios."
        ctaText="Explore Analytics Track"
        styles={styles}
        reverse={true}
        ctaLink="/tracks#track-data-analytics"
      />
  
      <GradientBreakSection 
        text="Who Should Join Data Training Lab?"
        styles={styles}
      />
      <div className="text-center py-16 px-4 bg-white dark:bg-dark-primary transition-colors duration-200">
        <p className="text-xl text-gray-700 dark:text-white/90 max-w-4xl mx-auto mt-6">
          Whether you're a recent graduate looking to break into the field,
          a professional seeking to transition to a data role, or an experienced
          practitioner aiming to update your skills, our program is designed
          to meet you where you are and take you where you want to go.
        </p>
      </div>
  
      <FeatureSection 
        imageSrc="/images/governance.jpg"
        fallbackSrc="/governance.jpg"
        title="Lead Data Governance"
        description="Develop comprehensive data governance frameworks while ensuring compliance and managing risk in a real business context."
        ctaText="Explore Governance Track"
        styles={styles}
        ctaLink="/tracks#track-data-governance"
      />

      {/* Testimonials Section */}
      <div className="py-24" style={{ 
        backgroundImage: `linear-gradient(135deg, ${styles.dark.color} 0%, ${styles.primary.color} 100%)` 
      }}>
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">
            Success Stories
          </h2>
          <CardGrid 
            items={testimonials} 
            styles={styles} 
            variant="testimonial" 
          />
        </div>
      </div>

      <CTASection 
        title="Ready to Start Your Journey?"
        subtitle="Join our community of data professionals and start building real-world experience today."
        ctaText="View Learning Tracks"
        styles={styles}
        to="/tracks"
      />

      <Footer />
    </div>
  );
}

export default Home;